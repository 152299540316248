import React from 'react';

import { Hidden } from '@material-ui/core';

import AppDrawer from './AppDrawer';
import {UserContext} from '../../../context/UserProvider'




function DynamicBox({open,onClickDynamicBox,...props}) {


const {usuario}=React.useContext(UserContext)


return (
  <div>
      {usuario.estado !== false &&(<>
            <Hidden mdDown>
                    <AppDrawer
                        variant='permanent'
                        open={true}
                    />
            </Hidden>

            <Hidden lgUp>
                <AppDrawer
                    variant='temporary'
                    open={open}
                    onClose={onClickDynamicBox}
                />
            </Hidden>
      
        </>)}

  </div>

)

}

export default DynamicBox;
export {DynamicBox}