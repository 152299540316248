import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import HeadTile from "./HeadTile";
import { UserContext } from "../../../../context/UserProvider";
import { db } from "../../../../config/firebase";
import { get_Uid_Label_ForDate } from "../../../../context/functions/DashboardFunctions/divideUIDByKind";

const WeekDaysTile = ({
  col,
  data,
  onClickCrossButton,
  style,
}) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const { name, uid } = data;
  const { username } = usuario;
  const canId = String(uid.split("@")[1]);
  const mac = String(uid.split("@")[0]);
  const kind = String(uid.split("@")[2]);
  const [label1, setLabel1] = useState("1234");
  const [label2, setLabel2] = useState("1234");

  useEffect(() => {
    const idxMac = dataMacCan.findIndex((elmt) => {
      return String(elmt.mac) == String(mac);
    });
    const idxCan = dataMacCan[idxMac].cans.findIndex((elmt) => {
      return String(elmt.id) == String(canId);
    });
    const docPath = `${username}/infoDevices/${mac}/${canId}/fromModule/render`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      const resp = get_Uid_Label_ForDate({
        fromModuleActual: data,
        kind: kind,
        typeOfModule: dataMacCan[idxMac].cans[idxCan].type,
      });
      if (resp.label1 != label1) {
        setLabel1(resp.label1);
      }
      if (resp.label2 != label2) {
        setLabel2(resp.label2);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [usuario, uid]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile
        name={name === undefined ? `Fecha Modulo_${canId}` : name}
        uid={uid}
        onClickCrossButton={onClickCrossButton}
        col={col}
      />

      <Grid item xs={6}>
        <h6>Semana:{label1}</h6>
      </Grid>

      <Grid item xs={6}>
        <h6>Día:{label2}</h6>
      </Grid>
    </Grid>
  );
};

export default React.memo(WeekDaysTile, (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
});
