import React from "react";
import { Grid, InputLabel, MenuItem, Select, SvgIcon } from "@material-ui/core";
import {
  getIconComponent
} from "./svgIconEvents";


const CustomMenuItem = ({ text }) => {

  const iconComponent = getIconComponent(text, true);

  return (
    <MenuItem value={text}>
      {iconComponent}
      {text}
    </MenuItem>
  );
};

const SelectEvent = ({ color, handleChange }) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={12} style={{ marginLeft: 8 }}>
        <InputLabel id="demo-mutiple-chip-label">Indentificador</InputLabel>

        <Select
          labelId="demo-mutiple-chip-label"
          fullWidth={true}
          name="event"
          value={color}
          onChange={handleChange}
          variant="outlined"
        >
          {CustomMenuItem({ text: "Notes"})}
          {CustomMenuItem({ text: "Prune" })}
          {CustomMenuItem({ text: "Bug" })}
          {CustomMenuItem({ text: "Notification" })}
          {CustomMenuItem({ text: "Nutrients" })}
          {CustomMenuItem({ text: "Enviroment" })}
          {CustomMenuItem({ text: "Maintenance" })}
          {CustomMenuItem({ text: "Calibration" })}
          {CustomMenuItem({ text: "Other" })}
        </Select>
      </Grid>
    </Grid>
  );
};

export default SelectEvent;
