
import React from 'react';
import Radio from '@material-ui/core/Radio';
import { Grid } from '@material-ui/core';






export default function RadioButtons({dataRadios,handleChangeRadio,selectedRadio}) {
  

  return (
    <div>
      
      <Grid 
      container 
      direction="row"
      justifyContent="center"
      alignItems="center"
      >
      {
        dataRadios.map((item,i)=>(


          <div  key={`${i}_radio`}>

              <Grid 
              container 
              justifyContent="center" 
              alignItems="center"
              direction="row" 
              spacing={5}
              >
                <Grid item xs={6}>
                    {item.label}
                </Grid>

                <Grid item xs={6}>
                  <Radio
                  
                    checked={selectedRadio ===item.value}
                    onChange={handleChangeRadio}
                    value={item.value}
                    color={item.color}
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': item.value }}
                    
                    /> 
                </Grid>
              </Grid> 
          </div>
          
        ))
      }
      </Grid>
    </div>
  );
}
 export {RadioButtons}