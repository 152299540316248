import React from "react";
import { functions, db } from "../config/firebase";
import { UserContext } from "./UserProvider";
import { defaultFormData } from "../components/Views/Manual/Constant";

export const MeasureContext = React.createContext();

const MeasureProvider = (props) => {
  const [allManualMeasurements, setAllManualMeasurements] = React.useState([]);

  const { usuario } = React.useContext(UserContext);
  const [sensorsName, setSensorsName] = React.useState([]);

  const [outDevices, setOutDevices] = React.useState([]);
  const [inputDevices, setInputDevices] = React.useState([]);
  const [seriesModifier, setSeriesModifier] = React.useState({
    action: null,
    formData: null,
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [isDialogActivatedByRowConfig, setIsDialogActivatedByRowConfig] =
    React.useState(false);
  const [formData, setFormData] = React.useState(defaultFormData);

  const getNamesOfManualSensors = async () => {
    try {
      const getSubCollections = functions.httpsCallable("getSubCollections");
      const listColl = await getSubCollections({
        docPath: `${usuario.username}/manualMeasurements`,
      });

      const clone = listColl.data.collections.map((item) => ({
        uid: item,
        value: item,
        label: item,
      }));
      //setSensorsName(clone)
      return clone;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMeasurements = async (sensorsNamesClone) => {
    let allMeasurements = [];
    await Promise.all(
      sensorsNamesClone.map(async (item) => {
        const addr = usuario.username + "/manualMeasurements/" + item.uid;
        const query = await db.collection(addr).orderBy("date", "desc").get();
        const data = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        allMeasurements = [
          ...allMeasurements,
          {
            name: item.uid,
            data,
          },
        ];
      })
    );
    return allMeasurements;
  };

  React.useEffect(() => {
    const getSensorsData = async () => {
      const sensorsNamesClone = await getNamesOfManualSensors();
      setSensorsName(sensorsNamesClone);
      const allMeasurements = await getAllMeasurements(sensorsNamesClone);
      setAllManualMeasurements(allMeasurements);
    };
    getSensorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeSensor = (measure) => {
    setOutDevices([]);
    setInputDevices(measure);
  };

  React.useEffect(() => {
    const clone = [...sensorsName];
    typeSensor(clone);
  }, [sensorsName]);

  const getDataFromDB = async (user, uid, fIni, fFin, tipo = "modulo") => {
    let valArray = [];
    const getDataSetValues = functions.httpsCallable("getDataSetValuesBinacle");
    valArray = await getDataSetValues({
      user: user,
      uid: uid,
      dateStart: fIni,
      dateFinish: fFin,
    });
    return valArray;
  };

  const deleteEspecificMeasureFromDB = async (dataRow) => {
    try {
      const add = usuario.username + "/manualMeasurements/" + dataRow.name;
      await db.collection(add).doc(dataRow.id).delete();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMeasureInCollapseTable = (dataRow) => {
    const isElementToDelete = (item) =>
      !(item.name === dataRow.name && item.id === dataRow.id);

    const arrayFiltrado = allManualMeasurements.map((MeasureName) => ({
      name: MeasureName.name,
      data: MeasureName.data.filter(isElementToDelete),
    }));
    setAllManualMeasurements(arrayFiltrado);
  };

  const handleClickDeleteRow = async (e, dataRow) => {
    try {
      await deleteEspecificMeasureFromDB(dataRow);
      deleteMeasureInCollapseTable(dataRow);
      setSeriesModifier({ action: "delete", formData: dataRow });
    } catch (error) {
      console.log(error);
    }
  };

  const settingInitialFormData = (dataRow) => {
    const isDialogOpeningByRowConfig = !!dataRow;
    if (isDialogOpeningByRowConfig) {
      setFormData(dataRow);
    } else {
      setFormData(defaultFormData);
    }
  };

  const handleClickOpenDialog = (e, dataRow) => {
    const isDialogOpeningByRowConfig = !!dataRow;
    settingInitialFormData(dataRow);
    setIsDialogActivatedByRowConfig(isDialogOpeningByRowConfig);
    setOpenDialog(true);
  };

  return (
    <MeasureContext.Provider
      value={{
        sensorsName,
        handleClickDeleteRow,
        setSensorsName,
        seriesModifier,
        setSeriesModifier,
        openDialog,
        setOpenDialog,
        isDialogActivatedByRowConfig,
        formData,
        setFormData,
        allManualMeasurements,
        setAllManualMeasurements,
        inputDevices,
        outDevices,
        getDataFromDB,
        handleClickOpenDialog,
      }}
    >
      {props.children}
    </MeasureContext.Provider>
  );
};

export default MeasureProvider;
