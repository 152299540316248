import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { GridListTileBar } from "@material-ui/core";

import BarTileIconsGridTimelapse from "./BarTileIconsGridTimelapse";

const useStyles = makeStyles((theme) => ({
  bar: {
    background: "rgba(0,0,0, 0)",
    height: "auto",
    marginTop: 2,
  },
}));

const BarTileIcons = ({tile}) => {
  const styles = useStyles();

  return (
    <GridListTileBar
      titlePosition="top"
      title={<BarTileIconsGridTimelapse tile={tile}/>}
      classes={{ root: styles.bar }}
    />
  );
};

export default BarTileIcons;
