import React from "react";
import { makeStyles, Drawer, Divider, Grid } from "@material-ui/core";

import Listas from "./Listas";
import Logo from "../Logo";
import { drawerWidth } from "../../../constants/NavBarConst";

const estilos = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

const AppDrawer = ({variant,open,onClose}) => {
  
  const classes = estilos();
  return (
    <Drawer
      className={classes.drawer}
      classes={{paper: classes.drawerPaper,}}
      anchor="left"
      variant={variant}
      open={open}
      //onClose={onClose ? onClose : null}
      onClose={() => onClose()}
    >
      {console.log("Esto es onClose:", open)}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.toolbar}
      >
        <Grid item>
          <Logo className={classes.title}/>
        </Grid>
      </Grid>

      <Divider />
      <Listas />
    </Drawer>
  );
};

export default AppDrawer;
