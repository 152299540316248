import React from "react";
import jwt from "jsonwebtoken";
import { UserContext } from "../../../context/UserProvider";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Button } from "@material-ui/core";

import AvatarToEditApp from "./components/AvatarToEditApp";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cover: {
    //width: 151,
    height: 200,
    backgroundSize: "cover",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const InfoUser = () => {
  const { usuario, dataMacCan } = React.useContext(UserContext);
  const classes = useStyles();

  const sendMessageNative = () => {
    const secretWord = String(process.env.REACT_APP_SECRET_JWT);
    const dataMac = dataMacCan.map((uniMac) => {
      const cans = uniMac.cans.map((uniCan) => {
        return { canId: uniCan.canId, type: uniCan.type };
      });
      return { mac: uniMac.mac, cans };
    });
    const token = jwt.sign(
      {
        username: usuario.username,
        dataMac,
      },
      secretWord,
      {
        expiresIn: 60 * 1,
      }
    );
    //find a postMessage to talk to hoster
    let postMessage = window.parent.postMessage;
    if (window.ReactNativeWebView) {
      postMessage = window.ReactNativeWebView.postMessage;
    }
    postMessage(token);
    //window.ReactNativeWebView.postMessage(token, "*");
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <AvatarToEditApp />
        </Grid>

        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <h3>{usuario.username}</h3>
          </Paper>
          <Paper className={classes.paper}>
            <Button
              className={classes.button}
              onClick={() => sendMessageNative()}
            >
              Activar Notificaciones
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <Paper className={classes.paper}>{usuario.email}</Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>{usuario.rol}</Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoUser;
export { InfoUser };
