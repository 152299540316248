import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DialogTitle, Grid } from "@material-ui/core";

export default function BarTileIconIndoDayDialog({ open, handleClose }) {
  const dia = 15;
  const date = "15/02/2020";

  const dataCrop = [
    {
      unit: "Ciclo",
      value: "Plantula",
    },
    {
      unit: "Humedad:",
      value: "80%",
    },
    {
      unit: "Temperatura:",
      value: "24°C",
    },
    {
      unit: "PH:",
      value: "6",
    },
    {
      unit: "CE:",
      value: "1.4ms/cm",
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Dia ${dia} ${date}`}</DialogTitle>

      <DialogContent>
        {dataCrop.map((crop, i) => (
          <Grid
            key={i}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              {crop.unit}
            </Grid>
            <Grid item xs={6}>
              {crop.value}
            </Grid>
          </Grid>
        ))}

        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
