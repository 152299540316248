import {
  ALL_KINDS,
  continuosSensors,
  discreteSensors,
  discreteOuts,
  TYPES_OF_DEVICES,
  TOGGLE_POS_AUTO,
} from "../../../constants/globalConst";
import { functions } from "../../../config/firebase";
const { MODULE_TYPE_MASTER, MODULE_TYPE_AMBIENTE, MODULE_TYPE_AGUA } =
  TYPES_OF_DEVICES;
const {
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_SWITCH,
  OUT_AC,
  IN_RELOJ,
  OUT_PUMB_DC,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  IN_TEMP_HOJA,
  OUT_PWM,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
} = ALL_KINDS;

const getDataSetValues = functions.httpsCallable("getDataSetValues");

//Obtener fechas especificas
const formatISODate = (year, month, day, hour, minute) => {
  // Crear un objeto Date
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute));

  // Convertir a formato ISO 8601 en UTC
  return date.toISOString();
};

const getDataFromDB = async (uid, user) => {
  try {
    //console.log("Esto es uid:",uid);
    if(user === "DanielTo"){
      // Ejemplo de uso para el jueves 25-04-2024 a las 00:00
      const startDate = formatISODate(2024, 4, 25, 0, 0);
      // Ejemplo de uso para el viernes 26-04-2024 a las 23:59
      const finishDate = formatISODate(2024, 4, 26, 23, 59);
      const values = await getDataSetValues({ user: user, uid: uid, dateStart: startDate, dateFinish: finishDate });
      
      let data = values.data.result[0];
      let valArray = [];
      let arr = [];
      for (let i in data) {
        if(data[i].val > 0) {
          let newEntry = [Date.parse(data[i].timestamp.value), data[i].val];
          valArray.push(newEntry);
          arr.push(data[i].val);
        }
        
      }
      //console.log("Esto es arr:",arr);
      let minVal = Math.min(...arr);
      let maxVal = Math.max(...arr);
      return { valArray, minVal, maxVal };
    } else {
      const values = await getDataSetValues({ user: user, uid: uid });
      //console.log("Esto es values:", values.data.result);
      //console.log("Esto es uid:", uid)

      let data = values.data.result[0];
      let valArray = [];
      let arr = [];
      for (let i in data) {
        if(data[i].val > 0) {
          let newEntry = [Date.parse(data[i].timestamp.value), data[i].val];
          valArray.push(newEntry);
          arr.push(data[i].val);
        }
      }
      let minVal = Math.min(...arr);
      let maxVal = Math.max(...arr);
      return { valArray, minVal, maxVal };
    }
    
  } catch (error) {
    let valArray = [];
    let minVal = -123;
    let maxVal = 123;
    return { valArray, minVal, maxVal };
  }
};

const get_Uid_State_CurrenteValue_ForToggle = ({
  kind,
  outid,
  typeOfModule,
  fromModuleActual,
  toModuleDataActual,
  uid,
}) => {
  let currentValue = null;
  let state = null;
  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    if (kind === OUT_AC) {
      //Out AC Fan,
      currentValue = fromModuleActual.O_float[outid];
      state = fromModuleActual.O_bool[outid];
    }
  } else if (typeOfModule === MODULE_TYPE_AGUA) {
    //Out DC Pumb,
    if (kind === OUT_PUMB_DC) {
      currentValue = fromModuleActual.O_float[outid];
      state = fromModuleActual.O_bool[outid];
    }
  } else if (typeOfModule === MODULE_TYPE_MASTER) {
    if (kind === OUT_PWM) {
      //Out DC Pumb,
      currentValue = 0;
      state = fromModuleActual.D_float[outid];
    }
  }
  const getTogglePosition = () => {
    const toggleFound = toModuleDataActual.find((item) => item.id === uid);
    let positionIfNotFoundSetDefault = toggleFound
      ? toggleFound.posicion
      : TOGGLE_POS_AUTO;
    return positionIfNotFoundSetDefault;
  };

  return { currentValue, state, posicion: getTogglePosition() };
};

const get_Uid_Sate_ForSwitch = ({
  kind,
  outid,
  typeOfModule,
  fromModuleActual,
}) => {
  let state = null;

  const isAModuleWithSwitches =
    typeOfModule === MODULE_TYPE_AMBIENTE ||
    typeOfModule === MODULE_TYPE_AGUA ||
    typeOfModule === MODULE_TYPE_MASTER;

  if (isAModuleWithSwitches && kind === IN_SWITCH) {
    state = fromModuleActual.C_bool[outid];
  }
  return { state };
};

const get_Uid_Label_ForDate = ({ kind, fromModuleActual, typeOfModule }) => {
  let label1 = null;
  let label2 = null;

  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    if (kind === IN_RELOJ) {
      label1 = fromModuleActual.Dt[0];
      label2 = fromModuleActual.Dt[1];
    }
  }

  return { label1, label2 };
};

const get_Uid_Value_ForAnalogAmbiente = ({ fromModuleActual, kind, outid }) => {
  let value = null;
  switch (kind) {
    case IN_HUMEDAD:
      //value = fromModuleActual.D_float[0];
      //console.log("Esto es outid:",outid)
      if(outid === "0") {
        value = fromModuleActual.D_float[0];
      } else if(outid === "1"){
        value = fromModuleActual.C_float[1];
      }
      break;
    case IN_SUSTRATO:
      value = fromModuleActual.C_float[outid];
      break;
    case IN_TEMPERATURA:
      if(outid === "1") {
        value = fromModuleActual.D_float[1];
      } else if(outid === "2") {
        value = fromModuleActual.C_float[2];
      }
      break;
    case IN_CO2:
        value = fromModuleActual.C_float[0];
        break;
    default:
      value = null;
      break;
  }

  return { value };
};

const get_Uid_Value_ForAnalogAgua = ({ fromModuleActual, kind, outid }) => {
  let value = null;

  switch (kind) {
    case IN_PH:
      value = fromModuleActual.C_float[0];
      break;

    case IN_CE:
      value = fromModuleActual.C_float[1];
      break;

    case IN_TEMP_AGUA:
      value = fromModuleActual.C_float[2];
      break;

    default:
      break;
  }

  return { value };
};

const get_Uid_Value_ForAnalogMaster = ({ fromModuleActual, kind, outid }) => {
  function getDPV(temperature, humidity) {
    const A = -10440.397;
    const B = -11.29465;
    const C = -0.027022355;
    const D = 0.00001289036;
    const E = -0.0000000024780681;
    const F = 6.5459673;
    let Caire = Number(temperature);
    let Cmata = Caire - 2;
    let Taire = (Caire + 273.15) * 1.8;
    let Tmata = (Cmata + 273.15) * 1.8;
    let pvsa = Math.exp(
      A / Taire +
        B +
        C * Taire +
        D * Math.pow(Taire, 2) +
        E * Math.pow(Taire, 3) +
        F * Math.log(Taire)
    );
    let pvsm = Math.exp(
      A / Tmata +
        B +
        C * Tmata +
        D * Math.pow(Tmata, 2) +
        E * Math.pow(Tmata, 3) +
        F * Math.log(Tmata)
    );
    let pva = pvsa * (humidity / 100);
    let dpv1 = pvsm - pva;
    let dpv = dpv1 * 6.894757293168361;
    return Number(dpv).toFixed(3);
  }
  function calculateDli(parValue) {
    let vPar = parValue / 1000000.0;
    let dayLightHours = 12.0 * 3600.0;
    let dli = dayLightHours * vPar;
    return Number(dli).toFixed(3);
  }
  let value = null;
  switch (kind) {
    case IN_HUMEDAD:
      value = fromModuleActual.O_float[0];
      break;

    case IN_TEMPERATURA:
      value = fromModuleActual.O_float[1];
      break;
    case IN_PH:
      value = fromModuleActual.O_float[5];
      break;

    case IN_CE:
      value = fromModuleActual.O_float[6];
      break;

    case IN_TEMP_AGUA:
      value = fromModuleActual.O_float[7];
      break;
    
    case IN_TEMP_HOJA:
      value = fromModuleActual.O_float[3];
      break;

    case IN_SUSTRATO:
      value = fromModuleActual.C_float[outid];
      break;
    case IN_PAR:
      value = fromModuleActual.O_float[2];
      break;

    case IN_DPV:
      value = getDPV(fromModuleActual.O_float[1], fromModuleActual.O_float[0]);
      break;

    case IN_DLI:
      value = calculateDli(fromModuleActual.O_float[2]);
      break;
    case IN_CO2:
      value = fromModuleActual.O_float[4];
      break;

    default:
      break;
  }
  return { value };
};

const get_Uid_Value_ForAnalog = ({
  fromModuleActual,
  kind,
  outid,
  typeOfModule,
}) => {
  let value = null;

  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    const res = get_Uid_Value_ForAnalogAmbiente({
      fromModuleActual,
      kind,
      outid,
    });
    value = res.value;
  } else if (typeOfModule === MODULE_TYPE_AGUA) {
    const res = get_Uid_Value_ForAnalogAgua({ fromModuleActual, kind, outid });
    value = res.value;
  } else if (typeOfModule === MODULE_TYPE_MASTER) {
    const res = get_Uid_Value_ForAnalogMaster({
      fromModuleActual,
      kind,
      outid,
    });
    value = res.value;
  }

  return { value };
};

async function divideUIDByKind({
  allUidList,
  allFromModule,
  allToModule,
  username,
  flgGraph,
}) {
  let dataToggles = [],
    dataSwitch = [],
    dataAnalogSensors = [],
    dataDate = [];
  const aux = allUidList.map(async (uniUidItem) => {
    let kind = String(uniUidItem.uid.split("@")[2]);
    let outid = String(uniUidItem.uid.split("@")[3]);
    let typeOfModule = String(uniUidItem.typeCan);
    let can = String(uniUidItem.uid.split("@")[1]);
    let mac = String(uniUidItem.uid.split("@")[0]);
    let fromModuleActual = allFromModule[mac][can];
    let toModuleDataActual = allToModule[mac][can];
    const uid = uniUidItem.uid;
    if (continuosSensors.includes(kind)) {
      const res = get_Uid_Value_ForAnalog({
        fromModuleActual,
        kind,
        outid,
        typeOfModule,
      });
      if (flgGraph) {
        const graph = await Promise.all([getDataFromDB(uid, username)]);
        return { type: "analog", data: { ...uniUidItem, ...res, ...graph[0] } };
      } else {
        return { type: "analog", data: { ...uniUidItem, ...res } };
      }
    } else if (discreteOuts.includes(kind) || kind === OUT_PWM) {
      const res = get_Uid_State_CurrenteValue_ForToggle({
        kind,
        outid,
        typeOfModule,
        fromModuleActual,
        toModuleDataActual,
        uid: uniUidItem.uid,
      });
      return { type: "toggle", data: { ...uniUidItem, ...res } };
    } else if (discreteSensors.includes(kind)) {
      const res = get_Uid_Sate_ForSwitch({
        kind,
        outid,
        typeOfModule,
        fromModuleActual,
      });
      return { type: "switch", data: { ...uniUidItem, ...res } };
    } else if (kind === IN_RELOJ) {
      const res = get_Uid_Label_ForDate({
        kind,
        fromModuleActual,
        typeOfModule,
      });
      return { type: "date", data: { ...uniUidItem, ...res } };
    }
  });
  const allDataMixed = await Promise.all(aux);
  allDataMixed.forEach((uniData) => {
    switch (uniData.type) {
      case "analog":
        dataAnalogSensors.push(uniData.data);
        break;

      case "toggle":
        dataToggles.push(uniData.data);
        break;
      case "switch":
        dataSwitch.push(uniData.data);
        break;
      case "date":
        dataDate.push(uniData.data);
        break;
      default:
        break;
    }
  });
  console.log({
    msg: "Divide all data",
    flgGraph,
  });
  return { dataToggles, dataSwitch, dataAnalogSensors, dataDate };
}

export {
  divideUIDByKind,
  getDataFromDB,
  get_Uid_Value_ForAnalog,
  get_Uid_State_CurrenteValue_ForToggle,
  get_Uid_Sate_ForSwitch,
  get_Uid_Label_ForDate
};
