import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { GridListTileBar } from "@material-ui/core";

import BarTileIconsGrid from "./BarTileIconsGrid";

const useStyles = makeStyles((theme) => ({
  bar: {
    background: "rgba(0,0,0, 0)",
    height: "auto",
    marginTop: 2,
  },
}));

const BarTileIcons = ({nameCrop,photoURL}) => {
  const styles = useStyles();
  return (
    <GridListTileBar
      titlePosition="top"
      title={<BarTileIconsGrid nameCrop={nameCrop} photoURL={photoURL}/>}
      classes={{ root: styles.bar }}
    />
  );
};

export default React.memo(BarTileIcons);
