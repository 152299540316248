import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import BottomAppBarCrop from "./BottomAppBarCrop";
import { CropContext } from "../../../../context/CropProvider";
import A1 from "../../../../assets/images/Gapy_Crop.PNG";
import { GridListTile } from "@material-ui/core";

import BarTileInfo from "./BarTileInfo";
import BarTileIcons from "./BarTileIcons";
import { getWeeksDaysFromDay } from "../../../../utils/unixTime";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    paddingTop: theme.spacing(4),
  },
  gridList: {
    [theme.breakpoints.down("sm")]: { width: 300 },
    [theme.breakpoints.up("sm")]: { width: 700 },
    [theme.breakpoints.up("md")]: { width: 1000 },
    [theme.breakpoints.up("lg")]: { width: 1200 },
    [theme.breakpoints.up("xl")]: { width: 1600 },
  },
  tile: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3)",
  },
}));

const numberOfColsGridList = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
};



const convertCropDataToTileData = (cropData) => {
  if (!cropData) return [];
  
  const tileData = cropData.map((info) => {
    const { name, initDate, color } = info;
    const { week, day } = getWeeksDaysFromDay(initDate);
    const photoURL = info.photoURL ? info.photoURL : A1;

    const tileCrop = {
      name,
      initDate,
      color,
      week,
      day,
      photoURL,
    };
    return tileCrop;
  });
  return tileData;
};



const CropApp = ({ width }) => {
  const styles = useStyles();

  const { cropData } = React.useContext(CropContext);
  
  const tileData = convertCropDataToTileData(cropData);
  
  
  return (
    <div className={styles.root}>
      <GridList
        className={styles.gridList}
        cols={numberOfColsGridList[width] || 3}
        spacing={30}
        cellHeight={300}
      >
          
        {
          tileData.map((tile, index) => (
            <GridListTile key={index} cols={1} classes={{ tile: styles.tile }}>
              <img src={tile.photoURL} alt={tile.name} />
              <BarTileInfo tile={tile} />
              <BarTileIcons nameCrop={tile.name} photoURL={tile.photoURL}/>
            </GridListTile>
          ))
        }

      </GridList>

      <BottomAppBarCrop />
    </div>
  );
};

export default CropApp;
