import React from 'react'


const TresSelect = (props) => {
    const id="Select"+props.id;
    //console.log(props.id)
    
    return (
        <div className="row">
            <div className="form-group col-6">
                <label htmlFor={id}>{props.label}</label>
            </div>
            <div className="col-6">
                <select 
                className="form-control"
                name={props.id} 
                id={id} 
                onChange={(e)=>props.handleToggle(e.target)}
                value={props.value}
                disabled={props.desactivar? true:false}
                >
                <option value="1" >ON</option>
                <option value="2" >AUTO</option>
                <option value="0" >OFF</option>
                </select>
                
                
            </div>
        </div>
    )
}

export default React.memo(TresSelect)
