const yesterday = new Date(
  new Date().setDate(new Date().getDate() - 1)
).getTime();

const defaultFormData = {
  name: "",
  value: "0",
  unit: null,
  comment: "",
  date: new Date().getTime(),
  fromDateDelete: yesterday,
  checkedEraseAll: true,
};

export { defaultFormData };
