import { Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react'

export const ConfirmationAlerts = ({ open, setOpen, typeAlert, message, alertTitle}) => {
  return (
    <div>
        <Collapse in={open}>
        <Alert severity={typeAlert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
        <AlertTitle>{alertTitle}</AlertTitle>
          {message}
        </Alert>
      </Collapse>
    </div>
  )
}
