import React from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { db } from "../../../../config/firebase";
import { UserContext } from "../../../../context/UserProvider";
import { ALL_PATHS } from "../../../../constants/routerConst";
import FooterForm from "./FooterForm";
import SocialAuhtButtons from "./SocialAuhtButtons";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const SignUpForm = ({ setKindFormUser, ...props }) => {
  const classes = useStyles();

  const {signUp } =React.useContext(UserContext);
  const [email, setEmail] = React.useState("prueba@prueba.com");
  const [pass, setPass] = React.useState("123456");
  const [username, setUsername] = React.useState("insertusername");
  const [passV, setPassV] = React.useState("123456");
  const [name, setName] = React.useState("Pedro");
  const [lastname, setLastname] = React.useState("Aguilar");
  const [error, setError] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError("Ingrese email");
      return;
    }
    if (!pass.trim()) {
      setError("Ingrese Password");
      return;
    }
    if (!username.trim()) {
      setError("Ingrese Username");
      return;
    }

    if (!name.trim()) {
      setError("Ingrese Name");
      return;
    }

    if (!lastname.trim()) {
      setError("Ingrese Lastname");
      return;
    }

    if (/\s/.test(username)) {
      setError("Username contiene espacios");
      return;
    }

    if (pass !== passV) {
      setError("Passwords no son iguales");
      return;
    }

    if (pass.length < 6) {
      setError("Menor a 6 caracteres");
      return;
    }
    setError(null);
    console.log("pasando todas las validaciones");

    signUpNPush();
  };

  const signUpNPush = async () => {
    try {
      const usersRef = await db
        .collection("usuarios")
        .where("username", "==", username)
        .get(); // para verificar si el usuario ya existe

      //console.log(usersRef)

      if (usersRef.empty) {
        await signUp(email, pass, username);

        setEmail("");
        setPass("");
        setUsername("");
        setError(null);
        props.history.push(ALL_PATHS.PATH_ADMIN);
      } else {
        console.log("Existe");
        setError("Username Existe");
      }
    } catch (error) {
      console.log(error);
      if (error.code === "auth/invalid-email") {
        setError("email no valido");
      }
      if (error.code === "auth/email-already-in-use") {
        setError("email ya utilizado");
      }
    }
  };

  return (
    <>
      <h3> Registrate</h3>
      <form noValidate onSubmit={procesarDatos}>
        {error && <div className="alert alert-danger">{error}</div>}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPass(e.target.value)}
          value={pass}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassV(e.target.value)}
          value={passV}
        />

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              id="Name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="text"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              id="Lastname"
              label="Lastname"
              onChange={(e) => setLastname(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="text"
            />
          </Grid>
        </Grid>

        <TextField
          id="Username"
          label="Username"
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
        />

        <SocialAuhtButtons 
        labelGoogle="Suscribete con Google"
        labelFacebook="Suscribete con Facebook"
        />
        

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Acceder
        </Button>
      </form>

      <FooterForm
        setKindFormUser={setKindFormUser}
        Link1={{ text: "¿Ya tienes cuenta?", to: "SignInForm" }}
        Link2={{ text: "Recuperar contraseña", to: "ResetForm" }}
      />
    </>
  );
};

export default withRouter(SignUpForm);
