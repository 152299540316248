import React from "react";
import { useParams } from "react-router-dom";

import ConfigOutAC from "./ConfigItems/ConfigOutAC";
import ConfigDigitalInput from "./ConfigItems/ConfigDigitalInput";
import ConfigDate from "./ConfigItems/ConfigDate";
import ConfigAnalogInput from "./ConfigItems/ConfigAnalogInput";
import ConfigNode from "./ConfigItems/ConfigNode";
import ConfigPumbDC from "./ConfigItems/ConfigPumbDC";
import { ConfigLoraNode } from "./ConfigItems/ConfigLoraNode";
import ConfigOutACTest from "./ConfigItems/ConfigOutACTest";

const ConfigIO = () => {
  const { id } = useParams();

  const arrayDeCadenas = id.split("@");
  //console.log(arrayDeCadenas)
  const ide =
    arrayDeCadenas[1] +
    "@" +
    arrayDeCadenas[2] +
    "@" +
    arrayDeCadenas[3] +
    "@" +
    arrayDeCadenas[4];

  const ComponentConfig = (props) => {
    //const last=ide.length;
    //console.log(props)
    const kind = arrayDeCadenas[3];
    const isNode = arrayDeCadenas[2] === "node";

    if(isNode){
      return <ConfigLoraNode data={props} />
    } else{

    //Ambiente
    if (kind === "5") {
      // return <ConfigOutAC data={props} />;
      return <ConfigOutACTest data={props} />;
    } else if (kind === "3") {
      return <ConfigDigitalInput data={props} />;
    }
    //else if(kind==="0" || kind==="1"){return (<HyTConfig1 data={props}/>);}
    else if (kind === "D") {
      return <ConfigDate data={props}></ConfigDate>;
    } else if (
      kind === "0" ||
      kind === "1" ||
      kind === "12" ||
      kind === "13" ||
      kind === "14"
    ) {
      let head;
      switch (kind) {
        case "0":
          head = "Sensor humedad de ambiente";
          break;
        case "1":
          head = "Sensor temperatura de ambiente";
          break;

        case "2":
          head = "Sensor humedad de sustrato";
          break;
        default:
          break;
      }
      return <ConfigAnalogInput data={props} head={head} />;
    } else if (kind === "N") {
      return <ConfigNode data={props} />;
    }
    //Agua
    else if (kind === "6") {
      return <ConfigPumbDC data={props} />;
    } else if (kind === "2") {
      return (
        <ConfigAnalogInput
          data={props}
          head={"Sensor humedad de sustrato"}
          cal={true}
          typeCalibration="CalSOIL"
        />
      );
    } else if (kind === "7") {
      return (
        <ConfigAnalogInput
          data={props}
          head="Sensor PH"
          cal={true}
          typeCalibration="CalPH"
        />
      );
    } else if (kind === "8") {
      return (
        <ConfigAnalogInput
          data={props}
          head="Sensor EC"
          cal={true}
          typeCalibration="CalCE"
        />
      );
    } else if (kind === "9") {
      return <ConfigAnalogInput data={props} head="Sensor Temp Agua" />;
    }else if (kind === "10") {
      return <ConfigAnalogInput data={props} head="Sensor Temp de la Hoja" />;
    } else if (kind === "15") {
      return <ConfigAnalogInput data={props} head="Sensor de CO2" />;
    } else {
      return <h1>Otro</h1>;
    }
  }
  };

  return (
    <div className="container mt-3">
      <div>
        {
          <ComponentConfig
            ide={ide}
            //user={prop.user}
          />
        }
      </div>
    </div>
  );
};

export default ConfigIO;
export { ConfigIO };
