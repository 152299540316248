import React from "react";
import TableBody from "@material-ui/core/TableBody";
import "moment/locale/es";

import Row from "./Row";
import { MeasureContext } from "../../../../../../context/MeasureProvider";
const CollapseTableBody = () => {
  const { allManualMeasurements } = React.useContext(MeasureContext);
  return (
    <TableBody>
      {allManualMeasurements.map((row, i) => (
        <Row key={`${row.name},${i}`} row={row} />
      ))}
    </TableBody>
  );
};

export default CollapseTableBody;
