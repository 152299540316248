import React from "react";
import { db, functions } from "../../../../config/firebase";
import moment from "moment";
import "moment/locale/es";
import AdvancedConfig from "./ConfigNodeComponents/AdvancedConfig";
import UpdatingButton from "./ConfigComponents/UpdatingButton";
import DialogConfirm from "./ConfigComponents/DialogConfirm";
import { UserContext } from "../../../../context/UserProvider";
import TransitionAlerts from "../../../Common/TransitionAlerts";
import { Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import { backGroundList } from "../../../../constants/globalConst";
import { withRouter } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import "./AdvancedConfig.css"

const actionLink = "sendConfig";
//const backGroundList="#616161"
const NumberOfRulesT = 20; //Numero de reglas totales

const defaultOpenAlert = { open: null, type: null, tittle: null, txt: null };

const getTypeName = (type) => {
  switch (type) {
    case "1":
      return "Node_Ambiente";
    case "2":
      return "Node_Agua";
    default:
      return "Node_Default";
  }
};

const CodifyOneRule = (arrayRule) => {
  const arrayDeCadenas = arrayRule.fromUid.split("@");
  //const fromMac=arrayDeCadenas[0]
  //const fromCanid=arrayDeCadenas[1]
  let fromType = arrayDeCadenas[2]; //Tipo sensor h,t,seco, salida etc
  let iType = arrayDeCadenas[3]; //numero de sensor

  if (fromType === "D") {
    fromType = 255;
  }
  if (iType === undefined) {
    iType = 0;
  } // si es Seco cual Id 0,1,2,3
  let toCanid = 0; //Tipo notificacion
  let toOut = 0; // se envia notificacion email
  if(arrayRule.event === "Actuation") {
    //Separando el UID del 
    const toArrayDeCadenas = arrayRule.toUid.split("@"); //
    toCanid = toArrayDeCadenas[1];
    toOut = toArrayDeCadenas[3]; //Numero de salida que activa
  }
  //El codigo de abajo es para cuando haya mas de un tipo de notificacion 
  // else {
  //   //si es email = 0, 
  //   const typeOfNotification = arrayRule.typeOfNotification;
  //   toOut = typeOfNotification === "email" ? 0 : (typeOfNotification === "otro" ? 1 : 2);
  // }

  return `${fromType},${iType},${arrayRule.operator},${arrayRule.whenLValue},${arrayRule.whenHValue},${toCanid},${toOut},${arrayRule.set}`;
  //Si toCanid == 0 es notificacion y toOut== 0 es email
};

const codifyRules = (arrayRules, canid) => {
  //7 tamaño de struct y 20 reglas

  const Accion = "252";
  const len = String(8 * NumberOfRulesT + 2); //2=Canid +NumberOfRules

  let mqttFirst = "";
  arrayRules.forEach((item) => {
    mqttFirst = mqttFirst + "," + CodifyOneRule(item);
  });

  const res = NumberOfRulesT - arrayRules.length;
  for (let i = 0; i < res; i++) {
    mqttFirst = mqttFirst + ",0,0,0,0,0,0,0,0";
  }

  return `${len},${Accion},${canid},${arrayRules.length}${mqttFirst}`;
};

const ConfigNode = (propiedades) => {
  const props = propiedades.data;
  //console.log("props",props)

  const ide = props.ide; //es el id que se muestra en el navegador SW@x

  const [error, setError] = React.useState(null);

  const [loading, setLoading] = React.useState(null); //Loading es para cargar , esto muestra el ciruclo cargando
  const [loadingErasing, setLoadingErasing] = React.useState(false);
  const [node, setNode] = React.useState();
  //const [allComponents,setAllComponents]=React.useState([])
  const [fromToComponents, setFromToComponents] = React.useState(null);

  const [macCan, setMacCan] = React.useState(null);

  const [openWindow, setOpenWindow] = React.useState(false); //Para abrir la ventana emergente
  const [openWindowErase, setOpenWindowErase] = React.useState(false); //Para abrir la ventana emergente
  const { usuario, dataMacCan, getArrayMac, userTimezone } = React.useContext(UserContext);

  const [nodeNameCache, setNodeNameCache] = React.useState(); //Nombre del nodo en Cahce

  const [openAlert, setOpenAlert] = React.useState(defaultOpenAlert); //Para abrir la Alerta de notificaciones

  const [dateSnap, setDateSnap] = React.useState("0");
  const [notificationTypes, setNotificationTypes] = React.useState([])

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const leyendoDBNodeConfig = async (mac, canid, nameUid) => {
          try {
            const addr = `${usuario.username}/infoDevices/${mac}`;
            const rulesAddr = `${usuario.username}/infoDevices/${mac}/${canid}/advancedConfig`
            const data = await db.collection(addr).doc(canid).get();
            const getDataOfArrayRules = await db.collection(rulesAddr).doc('actualRules').get();
            const actualItem = { canId: data.id, ...data.data(), arrayRules: getDataOfArrayRules.data().rules };

            if (actualItem.name) {
              setNode(actualItem);
              //localStorage.setItem(nameUid, JSON.stringify(actualItem)); ///////////////////
            } else {
              //No existe en base de datos
              const actual = {
                mac,
                canid,
                name: "Ingrese",
                fecha: null,
                type: actualItem.type,
                typeName: getTypeName(actualItem.type),
                NumberRules: 0,
                arrayRules: [],
                msMqtt: "",
              };
              setNode(actual);
              //localStorage.setItem(nameUid, JSON.stringify(actual)); ///////////////////
            }
          } catch (error) {
            console.log(error);
          }
        };

        const arrayDeCadenas = ide.split("@");
        const mac = arrayDeCadenas[0];
        const canid = arrayDeCadenas[1];

        setMacCan({ mac, canid });
        const nameUid = `Config@${mac}@${canid}@N`;
        setNodeNameCache(nameUid);

        if (localStorage.getItem(nameUid)) {
          console.log("Leyendo desde cache");
          setNode(JSON.parse(localStorage.getItem(nameUid)));
        } else {
          console.log("Leyendo desde base de datos");
          leyendoDBNodeConfig(mac, canid, nameUid);
        }

        //Los datos que son modificados por IoT, no se deben de leer desde el cache, siempre leer la DB en tiempo real
        // ya que en el cache pueden existir y ser distintos en la DB,

        //Revisar quiza no se pueda almacenar en cache almenos que se cargue el paquete completo, ya que si se modifica un componente,
        //Se debe de modificar tambien el array de el el indivudual

        //Debido a que son demasiados, los que se entrelazan lo que se recomendaria, es guardar el array completo de cans, y leerlo y modificarlos, solo ese array,
        //Si almacenamos individualmente, tendriamos que almacenar otro array de todos los canid, y cuando se modifique, modificar ese en especifico

        //El problema es que cuando se agrega un nodo desde hardware, se crean elementos, y no aparecerian en el cache, ya que son creados desde cloudfunctions
        // se podria poner un bandera que diga cuando se modifico la base de datos y en ese momento modificar el cache, pero solo funcionaria con un dispositivo.
        // Si se vigilan desde dos o más dispositivos, esto no funcionaria tan bien.

        let dataConfiguracion = [];
        const dataCan1 = dataMacCan.filter((item) => item.mac === mac);

        await Promise.all(
          dataCan1[0].cans.map(async (itemCan) => {
            const addr =
              usuario.username +
              "/infoDevices/" +
              mac +
              "/" +
              itemCan.id +
              "/configModule";
            const data = await db.collection(addr).get();

            const dataConfig = data.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            dataConfiguracion = [...dataConfiguracion, ...dataConfig];
          })
        );

        let IO = dataConfiguracion.map((item) => ({
          uid: item.id,
          name: item.item.name,
          kind: item.item.kind,
          canid: item.item.canid,
          outid: item.item.outid,
        }));

        //console.log("IO",IO)
        //setAllComponents(IO)

        let fromUid = [];
        let toUid = [];

        IO.forEach((element) => {
          let value = element.uid;

          if (
            element.canid === canid &&
            element.kind !== "5" &&
            element.kind !== "6" &&
            element.kind !== "10"
          ) {
            fromUid = [...fromUid, { ...element, value }];
          }
          //else if(element.canid!==props.canid &&element.kind ==='5'){//Muestra salidad que son solo de otros canid distintos
          else if (
            element.kind === "5" ||
            element.kind === "6" ||
            element.kind === "10"
          ) {
            toUid = [...toUid, { ...element, value }];
          }
        });
        setFromToComponents({
          fromUid,
          toUid,
        });
      } catch (error) {
        console.log(error);
      }
    };

    //console.log("Cargando por primera vez",usuario)
    if (dataMacCan.length > 0) {
      obtenerDatos();
    }
  }, [usuario.username, ide, dataMacCan]);

  React.useEffect(() => {
    const obtainNotificationData = async() => {
      try {
        const docRef = db.collection(usuario.username).doc('notifications');
        const getDataDoc = await docRef.get();
        if(getDataDoc.exists){
          const methodsOfNotification = getDataDoc.data().notificationMethods;
          const arrayOfMethods = [];
          for (let index = 0; index < methodsOfNotification.length; index++) {
            if(methodsOfNotification[index] === "email"){
              const constructData = { value: "email", name:"Correo"}
              arrayOfMethods.push(constructData)
            }
          }
          setNotificationTypes([...arrayOfMethods]);
        }
      } catch (error) {
        console.error("Error al obtener los metodos de notificacion:",error)
      }
    }
    if(usuario.username){
      obtainNotificationData();
    }
  }, [usuario.username])
  

  const editar = async (e) => {
    e.preventDefault();
    //console.log("object")

    if (!node.name.trim()) {
      setError("Ingrese Nombre");
      return;
    }
    let flag = false;

    node.arrayRules.forEach((item, index) => {
      if (!item.fromUid.trim()) {
        setError(`Ingrese Sensor, regla# ${index + 1}`);
        flag = true;
        return;
      }
      if (!item.operator) {
        setError(`Ingrese Operador, regla# ${index + 1}`);
        flag = true;
        return;
      }
      if (!item.whenLValue.trim()) {
        setError(`Ingrese Valor, regla# ${index + 1}`);
        flag = true;
        return;
      }
      if (!item.whenHValue.trim()) {
        setError(`Ingrese Valor, regla# ${index + 1}`);
        flag = true;
        return;
      }
      if ((item.set !== 0 && item.set !== 1 ) && item.event === "Actuation") {
        setError(`Ingrese ON/OFF, regla# ${index + 1}`);
        flag = true;
        return;
      }
      if (!item.toUid.trim() && item.event === "Actuation") {
        setError(`Ingrese Salida, regla# ${index + 1}`);
        flag = true;
        return;
      }
    });
    if (flag) {
      return;
    }

    ///const Json=JSON.stringify(node.arrayRules);
    setError(null);

    setOpenWindow(true);
  };

  const delateAllCanLocalStorage = () => {
    const addr = `Config@${node.mac}@${node.canid}@`;
    console.log(addr);
    var arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes(addr)) {
        arr.push(localStorage.key(i));
      }
    }

    // Iterate over arr and remove the items by key
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  };

  const agreeDialogErase = async () => {
    try {
      setLoadingErasing(true);

      const path =
        usuario.username + "/infoDevices/" + node.mac + "/" + node.canid;
      const deleteFn = functions.httpsCallable("recursiveDelete");
      const result = await deleteFn({ path });
      console.log(result);

      setOpenWindowErase(false);
      setLoadingErasing(false);
      await getArrayMac();
      propiedades.history.push("/");
      delateAllCanLocalStorage();
    } catch (error) {
      console.log(error);
    }
  };

  const agreeDialog = async () => {
    //Aun no guarda solo manda a cloudfunctions para mandar a IOT
    //const Clonenode={...node, fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
    setLoading(true);

    const item = {
      msMqtt: codifyRules(node.arrayRules, node.canid),
      mac: node.mac,
      action: actionLink,
      fecha: Date.now(),
      uid: nodeNameCache,
    };

    try {
      const addr = `${usuario.username}/infoDevices/${node.mac}/${node.canid}/sendConfigModule`;
      await db.collection(addr).doc("sendConfig").set({ item });
      setOpenWindow(false); //Solo abre la ventana
    } catch (error) {
      console.log(error);
    }
  };

  /* Notifica cuando se a terminado de configurar y cambia la bandera, loading permite modificar o cambiar*/
  React.useEffect(() => {
    const isTheUpdated = async () => {
      //console.log(node.mac)
      const addr1 = `${usuario.username}/infoDevices/${macCan.mac}/${macCan.canid}/sendConfigModule`;

      const data = await (
        await db.collection(addr1).doc("sendConfig").get()
      ).data();
      if (data.item.uid !== nodeNameCache) {
        return false;
      } else {
        return true;
      }
    };

    const ChangeFireStore = async () => {
      const add =
        usuario.username +
        "/infoDevices/" +
        macCan.mac +
        "/" +
        macCan.canid +
        "/fromModule";
      await db
        .collection(add)
        .where("act", "==", "recOK")
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            if (change.type === "modified") {
              const breaking = await isTheUpdated();
              if (breaking === false) {
                return;
              }

              setLoading(false); //Una ves que llega el mensaje desactiva el bloqueo, el loading(boton)

              setOpenAlert({
                open: true,
                type: "success",
                tittle: "Configuración Exitosa",
                txt: "El modulo se configuro correctamente",
              });
              const dataTime = change.doc.data().time;
              console.log("object");
              setDateSnap(dataTime);

              /*
                    const Clonenode={...node,   fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
                    const addr=`${usuario.username}/infoDevices/${node.mac}`
                    await db.collection(addr).doc(node.canid).update(Clonenode)
                    localStorage.setItem(nodeNameCache,JSON.stringify(Clonenode))
                    setNode(Clonenode)
                    
                    console.log("Salvando en DB")
                    */
            }
          });
        });
    };
    if (macCan && usuario.username) {
      ChangeFireStore();
    }
  }, [macCan, usuario.username, nodeNameCache]);

  React.useEffect(() => {
    const savingDB = async () => { 
      const Clonenode = {
        ...node,
        arrayRules:[],
        fecha: Date.now(),
        msMqtt: codifyRules(node.arrayRules, node.canid),
      };
      const rules = [...node.arrayRules]
      const addr = `${usuario.username}/infoDevices/${node.mac}`;
      const rulesAddr = `${usuario.username}/infoDevices/${node.mac}/${node.canid}/advancedConfig`
      await db.collection(addr).doc(node.canid).update(Clonenode);
      await db.collection(rulesAddr).doc('actualRules').update({rules})
      //localStorage.setItem(nodeNameCache, JSON.stringify(Clonenode));
      const newNodeConfig = {
        ...node,
        fecha: Clonenode.fecha,
        msMqtt: Clonenode.msMqtt
      }
      setNode(newNodeConfig);

      console.log("Salvando en DB");
    };

    if (dateSnap !== "0") {
      savingDB();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSnap]);

  /* Notifica si Hubo un error o el mensaje cuando se envio al IoT*/
  React.useEffect(() => {
    const ChangeFireStore = async () => {
      const add = usuario.username + "/logs/" + macCan.mac;

      await db
        .collection(add)
        .where("act", "==", "sendConfigModule")
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
              const data = change.doc.data();
              if (data.action !== actionLink) {
                return;
              }
              if (data.uid !== nodeNameCache) {
                return;
              } //si este uid no fue el que se actualizo no cambia parametros
              let alertjs = {};
              switch (data.state) {
                case "Error":
                  alertjs = {
                    open: true,
                    type: "error",
                    tittle: "Error",
                    txt: "Hubo un error al enviar: Verifique la conexión a internet de GAPY-Master",
                  };
                  break;
                case "Info":
                  alertjs = {
                    open: true,
                    type: "info",
                    tittle: "Info",
                    txt: "Espere a que se configure correctamente GAPY-MASTER",
                  };
                  break;
                default:
                  alertjs = defaultOpenAlert;
                  break;
              }
              setOpenAlert(alertjs);
            }
          });
        });
    };

    if (macCan && usuario.username) {
      ChangeFireStore();
    }
  }, [macCan, usuario.username, setOpenAlert, nodeNameCache]);

  const onLHandle = React.useCallback(
    (e) => {
      const arrayDeCadenas = e.name.split("/");
      const i = arrayDeCadenas[1];
      let arrayRules = [...node.arrayRules];
      let NumberRules = node.NumberRules;

      switch (arrayDeCadenas[0]) {
        case "FromUid":
          arrayRules[i] = { ...arrayRules[i], fromUid: e.value, set: "0" };
          break;
        case "Operator":
          arrayRules[i] = { ...arrayRules[i], operator: e.value };
          break;
        case "LValue":
          arrayRules[i] = { ...arrayRules[i], whenLValue: e.value };
          break;
        case "HValue":
          arrayRules[i] = { ...arrayRules[i], whenHValue: e.value };
          break;
        case "toUid":
          arrayRules[i] = { ...arrayRules[i], toUid: e.value };
          break;
        case "set":
          arrayRules[i] = { ...arrayRules[i], set: e.value };
          break;
        case "Event":
            arrayRules[i] = { ...arrayRules[i], event: e.value };
            break;
        case "notification":
            arrayRules[i] = { ...arrayRules[i], typeOfNotification: e.value };
            break;
        case "delate":
          arrayRules.splice(i, 1);
          NumberRules = NumberRules - 1;
          break;
        default:
          arrayRules[i] = { ...arrayRules[i] };
      }
      setNode({ ...node, arrayRules, NumberRules });
    },
    [node]
  );

  const agregar = () => {
    if (node.NumberRules === NumberOfRulesT) {
      return;
    }
    const arrayRules = [
      ...node.arrayRules,
      {
        fromUid: "",
        operator: "",
        event: "",
        typeOfNotification: "",
        whenLValue: "0",
        whenHValue: "0",
        set: 0,
        toUid: "",
      },
      
    ];
    const NumberRules = node.NumberRules + 1;
    setNode({ ...node, arrayRules, NumberRules });
  };

  const close = (e) => {
    //console.log(e)
    if (openAlert.type === "error") {
      setLoading(false);
    }
    setOpenAlert(defaultOpenAlert);
  };

  const handdleCloseDialogErase = () => {
    //Para que no se cierre mientras esta cargando
    if (loadingErasing) {
      return;
    }
    setOpenWindowErase(false);
  };

  return (
    node !== undefined && (
      <div className="container m-3 ">
        <form onSubmit={editar}>
          <div className="row">
            <div className="col-lg">
              <ul className="list-group">
                <li
                  className="list-group-item "
                  style={{ background: backGroundList }}
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <h4 style={{ color: "white" }}>Datos básico de módulo</h4>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        fontSize="small"
                        style={{ background: "white" }}
                        onClick={() => {
                          setOpenWindowErase(true);
                        }}
                      >
                        <Tooltip
                          title={<h5 style={{ color: "white" }}>Delete</h5>}
                          arrow
                        >
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-lg-1 col-md-6">Nombre:</div>
                    <div className="col-lg-2 col-md-6">
                      <input
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control mb-2"
                        onChange={(e) =>
                          setNode({ ...node, name: e.target.value })
                        }
                        value={node.name}
                        disabled={loading}
                      ></input>
                    </div>

                    <div className="col-lg-1 col-md-6">MAC:</div>
                    <div className="col-lg-2 col-md-6">{node.mac}</div>

                    <div className="col-lg-1 col-md-6">CAN:</div>
                    <div className="col-lg-1 col-md-6">{node.canid}</div>

                    <div className="col-lg-1 col-md-6">Type:</div>
                    <div className="col-lg-1 col-md-6">{node.typeName}</div>
                  </div>

                  {node.fecha && (
                    <div className="row">
                      <div className=" col-12">
                        Ultima Actualizaciíon:{" "}
                        {/* {moment(node.fecha).format("llll")} */}
                        {moment(node.fecha).tz(userTimezone).format('ddd, D [de] MMM [de] YYYY, HH:mm [Hrs.]')}
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg">
              <ul className="list-group">
                <li
                  className="list-group-item "
                  style={{ background: backGroundList }}
                >
                  <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                      <h4 style={{ color: "white" }}>
                        Configuraciónes Avanzadas- {node.name}
                      </h4>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                      <button
                        type="button"
                        className="btn  float-right"
                        onClick={() => agregar()}
                        disabled={loading}
                      >
                        <h4>
                          <span className="badge badge-light">
                            +{node.NumberRules}
                          </span>
                        </h4>
                      </button>
                    </div>
                  </div>
                </li>

                {node.arrayRules.length !== 0 && (
                  <Hidden smDown>
                    <div className="table-container">
                      <div className="table-row">
                        <div className="table-cell">#</div>
                        <div className="table-cell">Sensor</div>
                        <div className="table-cell">Operador</div>
                        <div className="table-cell">Value</div>
                        <div className="table-cell">Event</div>
                        <div className="table-cell">Salida</div>
                        <div className="table-cell">Estado</div>
                      </div>
                    </div>
                  </Hidden>
                )}

                {node.arrayRules.map((item, i) => (
                  <li
                    className={
                      i % 2
                        ? "list-group-item list-group-item-success"
                        : "list-group-item"
                    }
                    //className="list-group-item "
                    key={i}
                  >
                    <AdvancedConfig
                      data={item}
                      onHandle={onLHandle}
                      //allComponents={allComponents}
                      canid={node.canid}
                      i={i}
                      disabled={loading}
                      fromToComponents={fromToComponents}
                      macCan={macCan}
                      dataMacCan={dataMacCan}
                      arrayOfNotificationsTypes={notificationTypes}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row">
            {
              //como operador ternario
              error && (
                <div className="col-lg-12 mt-3">
                  <div className="alert alert-danger alert-block">{error}</div>
                </div>
              )
            }
          </div>

          <UpdatingButton type="submit" disabled={loading} />
        </form>

        <DialogConfirm
          open={openWindow}
          handleClose={() => setOpenWindow(false)}
          agreeDialog={agreeDialog}
          title={"¿Está seguro que quiere continuar con esta operación?"}
          text={
            "Las configraciónes avanzadas permiten realizar comandos con otros Nodos asociados al GAPY master Verifique que el Nodo que desea configurar este conectado através del GAPY-BUS"
          }
        />

        <TransitionAlerts data={openAlert} onClick={close} />

        <DialogConfirm
          open={openWindowErase}
          handleClose={handdleCloseDialogErase}
          agreeDialog={agreeDialogErase}
          title={
            "¿Está seguro de que desea eliminar este GAPY-Node de forma permanente?"
          }
          text={
            "Al eliminar un GAPPY-Node, se eliminan de su base de datos y no podra acceder o modificarlo otra vez. Al menos que lo de de alta de forma manual."
          }
          color="secondary"
          disabled={loadingErasing}
        />
      </div>
    )
  );
};

export default withRouter(ConfigNode);
