import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { drawerWidth } from "../../../../constants/NavBarConst";
import IconAddPhotoTimelapse from "./IconAddPhotoTimelapse";

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    top: "auto",
    bottom: 0,
    height: 60,
    background: "#fff",
  },
  offset: {
    height: 60,
    width: "100%",
  },
}));

export default function BottomAppBarTimelapse() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.offset} />
      
      <AppBar position="fixed" className={classes.appBar}>
        <IconAddPhotoTimelapse/>
      </AppBar>

    </>
  );
}
