import React, { useContext } from 'react'
import { Card, CardContent, Collapse, Divider, FormControlLabel, Grid, IconButton, makeStyles, Switch, Typography } from '@material-ui/core'
import { IrrigationContext } from '../../../../context/IrrigationContext/IrrigationContext';
import { SelectorForm } from './SelectorForm';
import { KeyboardArrowDown, KeyboardArrowUp, RemoveCircle } from '@material-ui/icons';
import { HourSelector } from './HourSelector';
import { TimeOnOff } from './TimeOnOff';


const useStyles = makeStyles(theme => ({
	card: {
	  minWidth: 275, //275
	  margin: theme.spacing(2),
	  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)', // Añade una sombra sutil
      border: '3px solid #e0e0e0', // Borde sutil
      borderRadius: theme.shape.borderRadius, // Redondeo de esquinas
	},
	details: {
	  display: 'flex',
	  flexDirection: 'column',
	},
	controls: {
	  //display: 'flex',
	  alignItems: 'center',
	  paddingLeft: theme.spacing(1),
	  //paddingBottom: theme.spacing(1),
	},
	content: {
	  flex: '1 0 auto',
	},
  }));

export const AutomaticModeMobileView = ({numberOfRule, Id, handleChangePumps, handleChangeWater, 
	removeRule, handleChangeZones, handleStarHour,handleSwitchChange, openA,openB,setOpenA,setOpenB,optionsMinuts,
	optionsSeconds,handleChangeMinutos,handleChangeSegundos, minutsOnOff,handleEndHour,handleChangeTimeOn,
	handleChangeTimeOff,handleChangeDays}) => {
	const { pumpsSelected, waterPumps, waterSelected, waterSource,zonesSelected,zonesNames,startHour,
		typeSwitch,minutos,segundos,endHour,timeON,timeOFF,daysOfWeek,days
	 } = useContext(IrrigationContext)

	const classes = useStyles();
  return (
	<Card className={classes.card} variant="outlined">
		<CardContent>
			<Typography variant="h6" component="h2">Regla #{numberOfRule}
				<IconButton key={`removeRule_${Id}`} color="secondary" aria-label="remove_rule" id={`remove_${Id}`} component="span" onClick={removeRule}>
					<RemoveCircle />
				</IconButton> 
			</Typography>
		</CardContent>
		<Divider />
		<div className={classes.controls}>
		{/* Botones o switches como IconButton aquí */}
		<Grid container justifyContent='center' alignItems='center'>
			<Grid item xs={6} sm={3}>
			<SelectorForm
			valueSelected={pumpsSelected[Id]}
			handleChangeSelect={handleChangePumps} 
			arrayOfOptions={waterPumps}
			labelName={"Bombas"} 
			id={`1_${Id}`}
			/>
			</Grid>
			<Grid item xs={6} sm={3}>
			<SelectorForm
			valueSelected={waterSelected[Id]}
			handleChangeSelect={handleChangeWater} 
			arrayOfOptions={waterSource}
			labelName={"Agua"} 
			id={`2_${Id}`}
			/>
			</Grid>
			<Grid item xs={6} sm={3}>
			<SelectorForm
			valueSelected={zonesSelected[Id]}
			handleChangeSelect={handleChangeZones} 
			arrayOfOptions={zonesNames}
			labelName={"Zonas"} 
			id={`3_${Id}`}
			/>
			</Grid>
			<Grid item xs={6} sm={3}>
			<HourSelector 
			Id={Id} 
			value={startHour[Id]} 
			handleChangeHour={handleStarHour} 
			name={"Hora de Inicio"}
			/>
			</Grid>
			</Grid>
		</div>

		<Divider />

		<div className={classes.controls}>
			<Grid container justifyContent='center' alignItems='center'>
			
			<Grid item xs={6} sm={3}>
			<div>
				<FormControlLabel
				control={
					<Switch
					checked={typeSwitch[Id].checkedA}
					onChange={handleSwitchChange}
					name="checkedA"
					color="primary"
					/>
				}
				label="Activacion Unica"
				labelPlacement="top"
				/>
				<div style={{marginLeft: "15px"}}>
					<IconButton 
					aria-label="expand row" 
					size="small" 
					onClick={() => setOpenA(!openA)}
					disabled={typeSwitch[Id].checkedB}>
						{openA ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						{openA ? "Cerrar" : "Mostrar"}
					</IconButton>

					<Collapse in={openA} timeout="auto" unmountOnExit> 
						<TimeOnOff 
						Id={Id} 
						value={minutos[Id]} 
						handleChangeSelect={handleChangeMinutos}
						arrayOfOptions={optionsMinuts}
						name={"Minutos"}
						/>
						<TimeOnOff 
						Id={Id} 
						value={segundos[Id]} 
						handleChangeSelect={handleChangeSegundos}
						arrayOfOptions={optionsSeconds}
						name={"Segundos"}
						/>	
					</Collapse>
			  	</div>
			</div>
			</Grid>

			<Grid item xs={6} sm={3}>
			<div style={{marginLeft: "15px"}}>
				<FormControlLabel
				control={
					<Switch
					checked={typeSwitch[Id].checkedB}
					onChange={handleSwitchChange}
					name="checkedB"
					color="primary"
					/>
				}
				label="Activacion Multiple"
				labelPlacement="top"
				/>
				<div style={{marginLeft: "20px"}}>
					<IconButton aria-label="expand row" 
					size="small" 
					onClick={() => setOpenB(!openB)} 
					disabled={typeSwitch[Id].checkedA}>
					{openB ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					{openB ? "Cerrar" : "Mostrar"}
					</IconButton>

					
					<Collapse in={openB} timeout="auto" unmountOnExit> 
					<div style={{marginLeft: "25px"}}>
					<HourSelector 
					Id={Id} 
					value={endHour[Id]} 
					handleChangeHour={handleEndHour} 
					name={"Hora de Fin"}
					/>
					</div>

					<TimeOnOff 
					Id={Id} 
					value={timeON[Id]} 
					handleChangeSelect={handleChangeTimeOn} 
					arrayOfOptions={minutsOnOff} 
					name={"Minutos ON"}
					/>

					<TimeOnOff 
					Id={Id} 
					value={timeOFF[Id]} 
					handleChangeSelect={handleChangeTimeOff} 
					arrayOfOptions={minutsOnOff} 
					name={"Minutos OFF"}
					/>

              		</Collapse>
					
				</div>
			</div>
			</Grid>
			</Grid>
		</div>

		<Divider />

		<div className={classes.controls}>
			<Grid container justifyContent='center' alignItems='center'>
			<SelectorForm
			valueSelected={daysOfWeek[Id]}
			handleChangeSelect={handleChangeDays} 
			arrayOfOptions={days}
			labelName={"Dias"} 
			id={`6_${Id}`}
			/>
			</Grid>
		</div>
	</Card>
  )
}
