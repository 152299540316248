import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab';
import { LinearProgress, Grid, } from '@material-ui/core';

export const Steps_CalPumb = ({
  subActiveStep,
  info_alerts,
  percentage, }) => {
  return (
    <Grid container direction="column"
      justifyContent="center"
      alignItems="center" spacing={2}>
      <LinearProgress color="success" variant="determinate" size="lg" thickness={10} value={percentage} />
      {/* <p>{header_text[subActiveStep]}</p> */}
      {subActiveStep === 0 && (
        <Alert severity="warning">
          <AlertTitle> <strong>{info_alerts[0].points[0]}</strong> </AlertTitle>
          {/* {info_alerts[0].points} */}
        </Alert>
      )}

      {subActiveStep === 0 && (
        <Alert severity="info">
          <AlertTitle> <strong>{info_alerts[0].points[1]}</strong> </AlertTitle>
          {/* {info_alerts[1].points} */}
        </Alert>
      )}



      {subActiveStep === 1 && (
        <Alert severity="info">
          <AlertTitle> <strong>{info_alerts[0].points[2]}</strong> </AlertTitle>
          {/* {info_alerts[3].point_1} <strong>{info_alerts[3].point_2}</strong> {info_alerts[3].point_3} */}
        </Alert>
      )}

      {subActiveStep === 1 && (
        <Alert severity="info">
          <AlertTitle> <strong>{info_alerts[0].points[3]}</strong> </AlertTitle>
          {/* {info_alerts[2].points} */}
        </Alert>
      )}

      {subActiveStep === 2 && (
        <Alert severity="info">
          <AlertTitle> <strong>{info_alerts[0].points[4]}</strong> </AlertTitle>
          {/* {info_alerts[4].points} */}
        </Alert>
      )}

      {subActiveStep === 2 && (
        <Alert severity="warning">
          <AlertTitle> <strong>{info_alerts[0].points[5]}</strong> </AlertTitle>
          {/* {info_alerts[5].points} */}
        </Alert>
      )}

    </Grid>
  )
}
