import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import SelectEvent from "./CalendarComponents/selectEvent";
import PhotoUploader from "./CalendarComponents/photoUploader";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const CustomColorSelector = (values, handleChange) => (
  <SelectEvent
    color={values.extendedProps.typeEvent}
    handleChange={handleChange}
  />
);

const CustomTextDialog = (values, handleChange, error) => (
  <FormGroup>
    <TextField
      type="text"
      placeholder="Mantenimiento preventivo"
      label="Nombre del evento"
      name="title"
      value={values.title}
      onChange={handleChange}
      variant="outlined"
    />
    <FormLabel style={{ fontSize: 13, color: "red" }}>
      {error.titleError}
    </FormLabel>
  </FormGroup>
);

const CustomDescriptionDialog = (values, handleChange) => (
  <FormGroup>
    <TextField
      type="text"
      placeholder="¿Qué fue lo que hiciste?"
      label="Descripción del Evento"
      name="description"
      multiline
      rows={5}
      value={values.extendedProps.description}
      onChange={handleChange}
      variant="outlined"
    />
  </FormGroup>
);

const CustomDateStart = (values, handleChange) => (
  <FormGroup>
    <TextField
      type="date"
      label="Fecha inicial"
      name="start"
      onChange={handleChange}
      value={values.start}
      variant="outlined"
    />
  </FormGroup>
);

const CustomDateEnd = (values, handleChange, error) => (
  <FormGroup>
    <TextField
      type="date"
      name="end"
      label="Fecha Final"
      value={values.end}
      onChange={handleChange}
      placeholder="DD-MM-AAAA"
      variant="outlined"
    />
    <FormLabel style={{ fontSize: 13, color: "red" }}>
      {error.dateError} {error.rangeError}
    </FormLabel>
  </FormGroup>
);

const CustomDelete = (showDiv, handleDelete) => (
  <FormGroup hidden={showDiv}>
    <Button color="secondary" onClick={handleDelete}>
      Eliminar
    </Button>
  </FormGroup>
);

const DialogCalendar = (props) => {
  const {
    modalData,
    handleClosed,
    values,
    handleChange,
    error,
    handleDelete,
    showDiv,
    handleSubmit,
    flgSubmit
  } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={modalData.showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {modalData.titleModal}
        </DialogTitle>

        <DialogContent>
          <FormGroup className={classes.formControl}>
            {CustomColorSelector(values, handleChange)}
            {CustomTextDialog(values, handleChange, error)}
            {CustomDateStart(values, handleChange)}
            {CustomDateEnd(values, handleChange, error)}
            {CustomDescriptionDialog(values, handleChange)}
            {PhotoUploader({
              values,
              handleChange,
              dialogFlgShow: modalData.showModal,
            })}
            {CustomDelete(showDiv, handleDelete)}
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClosed}>Cerrar</Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            type="submit"
            value="Submit"
            disabled={flgSubmit}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogCalendar;
export { DialogCalendar };
