import React from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import { AlertTitle, Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DeleteIcon from "@material-ui/icons/Delete";

const ImageToEditDialogActionsApp = ({
  deletePhoto,
  selectFile,
  error,
  loading,
  deleteLabel,
  editLabel
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item style={{ marginBottom: 12 }}>
          {loading && <CircularProgress />}

          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        

        {(deletePhoto && deleteLabel) && (
          <Grid item>
            <Button
              onClick={deletePhoto}
              color="primary"
              startIcon={<DeleteIcon />}
              variant="outlined"
              disabled={loading}
            >
              {deleteLabel}
              
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            color="primary"
            startIcon={<CameraAltIcon />}
            variant="contained"
            component="label"
            onChange={(e) => selectFile(e)}
            disabled={loading}
          >
            {editLabel}
            <input type="file" hidden />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImageToEditDialogActionsApp;
