import React, { useState } from 'react'
import DragChart from '../ConfigComponents/DragChart'
import InputNumber from '../ConfigComponents/InputNumber'
import { FormControlLabel, Switch } from '@material-ui/core';

const ConfigLeds1 = (props) => {
    //console.log("L1",props)
    //const [state, setState] = useState(false);
    
    
    const data2=props.data.data2;
    const value=(data)=>{
        if(data===undefined){return 0}
        else {return data[0]}
    }
    
    return (
        <div>
            <InputNumber
            id="at"
            label="Primera hora de encendido"
            value={value(data2)}
            handleToggle={props.startAt}
            desactivar={props.desactivar}
            />

            {/* <FormControlLabel
                control={
                <Switch
                    checked={props.switchState}
                    onChange={props.handleSwitchChange}
                    name="checkedB"
                    color="primary"
                />
                }
                label="Minutos"
            /> */}
            
            <h3>{props.head}</h3>
            <DragChart data={props}/>
        </div>
    )
}

export default ConfigLeds1
