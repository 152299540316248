import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import TimelapseImg from "../../../../assets/images/timelapse.jpg";
import GridListTile from "@material-ui/core/GridListTile";
import BarTileInfo from "./BarTileInfo";
import BarTileIcons from "./BarTileIcons";
import BottomAppBarTimelapse from "./BottomAppBarTimelapse";
import { TimelapseContext } from "../../../../context/TimelapseProvider";
import { getWeeksDaysFromDay } from "../../../../utils/unixTime";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    paddingTop: theme.spacing(2),
  },
  gridList: {
    [theme.breakpoints.down("sm")]: { width: 300 },
    [theme.breakpoints.up("sm")]: { width: 700 },
    [theme.breakpoints.up("md")]: { width: 1000 },
    [theme.breakpoints.up("lg")]: { width: 1200 },
    [theme.breakpoints.up("xl")]: { width: 1600 },
  },
  tile: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3)",
  },
}));

const numberOfColsGridList = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
};

const convertTimelapseDataToTileData = (timelapseData) => {
  if (!timelapseData) return [];
  
  const tileData = timelapseData.map((info) => {
    const  date  =new Date (info.date).toLocaleString();
    const { week, day } = getWeeksDaysFromDay(info.date);
    const photoURL = info.photoURL ? info.photoURL : TimelapseImg;

    const tileTimelapse = {
      ...info,
      date,
      week,
      day,
      photoURL,
    };
    return tileTimelapse;
  });
  return tileData;
};



const TimelapseApp = ({ width }) => {
  
  const styles = useStyles();
  const { timelapseData } = React.useContext(TimelapseContext);
  const tileData = convertTimelapseDataToTileData(timelapseData);

  return (
    <div className={styles.root}>
      <GridList
        className={styles.gridList}
        cols={numberOfColsGridList[width] || 3}
        spacing={16}
        cellHeight={300}
      >
        {tileData.map((tile, index) => (
          <GridListTile key={index} cols={1} classes={{ tile: styles.tile }}>
            <img src={tile.photoURL} alt={tile.id} />
            <BarTileInfo tile={tile} />
            <BarTileIcons tile={tile}/>
          </GridListTile>
        ))}
      </GridList>
      <BottomAppBarTimelapse/>
    </div>
  );
};

export default TimelapseApp;

