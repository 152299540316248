import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";



import A1 from "../../../assets/A1.jpg";
import A2 from "../../../assets/A2.jpg";
import A3 from "../../../assets/A3.jpg";
import A4 from "../../../assets/A4.jpg";
import A5 from "../../../assets/A5.jpg";
import A6 from "../../../assets/A6.jpg";
import A7 from "../../../assets/A7.jpg";
import A8 from "../../../assets/A8.jpg";
import A9 from "../../../assets/A9.jpg";
import A10 from "../../../assets/A10.jpg";
import A11 from "../../../assets/A11.jpg";
import A12 from "../../../assets/A12.jpg";
import A13 from "../../../assets/A13.jpg";
import A14 from "../../../assets/A14.jpg";
import A15 from "../../../assets/A15.jpg";
import A16 from "../../../assets/A16.jpg";
import A17 from "../../../assets/A17.jpg";
import { Copyright } from "../../Common";
import SignInForm from "./Components/SignInForm";
import SignUpForm from "./Components/SignUpForm";
import ResetForm from "./Components/ResetForm";

var images = [
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,
  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
];
const useStyles = makeStyles((theme) => ({
  root: {
    height: "85vh",
  },
  image: {
    backgroundImage: `url(${
      images[Math.floor(Math.random() * images.length)]
    })`,
    
    backgroundSize: "cover",
    
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const RenderLogFormComponent = () => {
  const [kindFormUser, setKindFormUser] = React.useState("SignInForm");

  const RenderLogForm = {
    SignInForm: <SignInForm setKindFormUser={setKindFormUser} />,
    SignUpForm: <SignUpForm setKindFormUser={setKindFormUser} />,
    ResetForm:<ResetForm setKindFormUser={setKindFormUser}/>
  };

  return RenderLogForm[kindFormUser] || null;
};

const Login = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={false} md={7} className={classes.image}/>

      <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>

        <div className={classes.paper}>

          <RenderLogFormComponent />

          <Box mt={5}>
            <Copyright />
          </Box>

        </div>

      </Grid>
    </Grid>
  );
};

export { Login };
