import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import { Typography, Grid, Box } from "@material-ui/core";
import SelectMultiple from "../../../../Common/SelectMultiple";
import { Alert, AlertTitle } from "@material-ui/lab";
import InputSlider from "../ConfigComponents/InputSlider";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
//Archivos Nuevos
import { Steps_PH4 } from "../ConfigComponents/Steps_PH4";
import { Steps_PH7 } from "../ConfigComponents/Steps_PH7";
import { Steps_1413 } from "../ConfigComponents/Steps_1413";
import { Steps_1288 } from "../ConfigComponents/Steps_1288";
import { Steps_CalPumb } from "../ConfigComponents/Steps_CalPumb";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default function CalibrationStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectModo, setSelectModo] = React.useState(
    props.selecOptions[0].value
  );
  const [range, setRange] = React.useState(10);

  const [selecPos, setSelectPos] = React.useState(0);

  //Nuevas constantes
  const [subActiveStep, setSubActiveStep] = React.useState(0);
  const [subActiveStep2, setSubActiveStep2] = React.useState(0);
  const [valper, setValper] = React.useState(25);
  const [valper2, setValper2] = React.useState(25);
  const [valper3, setValper3] = React.useState(33);
  const [loading, setLoading] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const info_text = props.infoStepper;

  React.useEffect(() => {
    if (
      props.typeCalibration === "CalBomba" ||
      props.typeCalibration === "CalCE"
    ) {
      setSelectPos(selectModo - 3);
    } else if (props.typeCalibration === "CalPH") {
      setSelectPos(selectModo);
    } else if (props.typeCalibration === "CalSOIL") {
      setSelectPos(Number(selectModo) - 6);
    }
  }, [props, selectModo]);

  //Aqui se activa la animacion de loading
  React.useEffect(() => {
    if (props.typeCalibration === 'CalBomba') {
      if (activeStep === 2 && subActiveStep === 2) {
        if (loading === false) {
          setLoading(true);
          setTimer(setTimeout(() => setLoading(false), 5000));
        }
      } else {
        setLoading(false);
        clearTimeout(timer);
      }

      return () => clearTimeout(timer);

    } else {
      if (subActiveStep === 2 || subActiveStep2 === 2) {
        if (loading === false) {
          setLoading(true);
          setTimer(setTimeout(() => setLoading(false), 30000));
        }
      } else if (activeStep === 2) {
        if (loading === false) {
          setLoading(true);
          setTimer(setTimeout(() => setLoading(false), 1000));
        }

      } else {
        setLoading(false);
        clearTimeout(timer);
      }

      return () => clearTimeout(timer);

    }

  }, [activeStep, timer, subActiveStep, subActiveStep2, selectModo]);
  //Funcion que cambia a icono de check
  const circular_progress = () => {
    if (loading === true) {
      return <CircularProgress color="success" />;
    } else {
      return <CheckCircle fontSize="large" style={{ color: "green" }} />;
    }
  };

  const handleNext = () => {
    // if (activeStep === 1) {
      let mqtt = "";

      if (props.typeCalibration === "CalBomba") {
        const len = 4;
        const action = 4;
        mqtt =
          len +
          "," +
          action +
          "," +
          props.canid +
          "," +
          props.outid +
          "," +
          5 +  //selectModo
          "," +
          range;
          const messageAction = "Advanced Config";
          // console.log(mqtt);
        subActiveStep === 1 && props.sendingOperationCalibration(mqtt,messageAction);
        
      } else if (
        props.typeCalibration === "CalPH" ||
        props.typeCalibration === "CalCE"
      ) {
        const len = 2;
        const action = 1;
        mqtt = len + "," + action + "," + props.canid + "," + selectModo;
        // console.log({ mqtt });
        const messageAction = "Advanced Config";
        activeStep === 1 && props.sendingOperationCalibration(mqtt, messageAction);
        activeStep === 3 && setSelectModo(props.selecOptions[1].value);
        activeStep === 4 && setSelectModo(props.selecOptions[2].value);
        if (activeStep === 3 && subActiveStep === 1) {
          props.sendingOperationCalibration(mqtt, messageAction);
        } else if(activeStep === 4 && subActiveStep2 === 1) {
          props.sendingOperationCalibration(mqtt, messageAction);
        }

      } else if (props.typeCalibration === "CalSOIL") {
        const len = 3;
        const action = 9;
        mqtt =
          len +
          "," +
          action +
          "," +
          props.canid +
          "," +
          props.outid +
          "," +
          selectModo;
        //console.log({ mqtt });
        const messageAction = "Advanced Config";
        props.sendingOperationCalibration(mqtt, messageAction);
      }

    // }

    //Aqui se agrego el cambio de step hacia adelante y la barra de carga
    if (props.typeCalibration === 'CalBomba') {
      if (activeStep === 1 && subActiveStep !== 2) {
        setSubActiveStep((prevActiveStep) => prevActiveStep + 1);
        setValper3((preValper) => preValper + 33);

      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (props.typeCalibration === 'CalPH' || props.typeCalibration === 'CalCE') {
      if (activeStep === 3 && subActiveStep !== 3) {
        setSubActiveStep((prevActiveStep) => prevActiveStep + 1);
        setValper((preValper) => preValper + 25);
      } else if (activeStep === 4 && subActiveStep2 !== 2) {
        setSubActiveStep2((prevActiveStep) => prevActiveStep + 1);
        setValper2((preValper) => preValper + 25);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

  };

  const renderCalibrationForm = (typeCal, item) => {
    if (typeCal === "CalBomba") {
      return (
        <>
          <hr />
          {/* <div className="row my-2">
            <div className="col-6">Modo de calibración</div>
            <SelectMultiple
              id={`TypePumb`}
              value={selectModo}
              handleToggle={handdleSelectModo}
              desactivar={false}
              arrayOptions={props.selecOptions}
              className="col-6"
            />
          </div> */}

          <InputSlider
            value={range}
            handleSliderChange={handleSliderChange}
            handleInputChange={handleInputChange}
            handleBlur={handleBlur}
            // label={item.labelSlider ? item.labelSlider[selecPos] : ""}
            label={props.infoStepper[2].labelSlider ? props.infoStepper[2].labelSlider[2] : ""}
            step={1}
            min={0}
            max={100}
          />
          <hr />
        </>
      );
    }
    if (typeCal === "CalSOIL") {
      return (
        <div>
          <hr />
          <div className="row my-2">
            <div className="col-6">Modo de calibración</div>
            {/* <SelectMultiple
              id={`TypePumb`}
              value={selectModo}
              handleToggle={handdleSelectModo}
              desactivar={false}
              arrayOptions={props.selecOptions}
              className="col-6"
            /> */}
          </div>
          <hr />
        </div>
      );
    } else {
      return <h1>Otro</h1>;
    }
  };

  //Funcion que cambia step hacia atras y tambien cambia la barra de carga
  const handleBack = () => {
    if (activeStep === 3 && subActiveStep !== 0) {
      setSubActiveStep((prevActiveStep) => prevActiveStep - 1);
      setValper((preValper) => preValper - 25);
    } else if (activeStep === 4 && subActiveStep2 !== 0) {
      setSubActiveStep2((prevActiveStep) => prevActiveStep - 1);
      setValper2((preValper) => preValper - 25);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handdleSelectModo = (e) => {
    console.log({ selectModo, val: e.value });
    setSelectModo(e.value);
  };

  const handleSliderChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleInputChange = (event) => {
    setRange(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (range < 0) {
      setRange(0);
    } else if (range > 100) {
      setRange(100);
    }
  };

  //Funcion para el nombre del boton
  const selectButton = () => {
    if (subActiveStep === 1 || subActiveStep2 === 1) {
      return "ENVIAR";
    } else if (activeStep === info_text.length - 1) {
      return "Terminar";
    } else {
      return "Siguiente";
    }
  };

  return (
    <div className={classes.root}>
      {props.typeCalibration === "CalBomba" ? (
        <div>
          {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {props.infoStepper.map((item, index) => (
              <Step key={index}>
                <StepLabel>{item.tittleStep}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === info_text.length - 1 ? (
              <Grid container direction="column"
                justifyContent="center"
                alignItems="center" spacing={2}>
                <Alert severity="success">
                  <AlertTitle> <strong>{info_text[activeStep].text}</strong> </AlertTitle>
                  {/* {info_text[activeStep].textStep[0].points} */}
                </Alert>
                <Grid container direction="row"
                  justifyContent="center"
                  alignItems="center" spacing={2}>
                    
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={props.handleClose}
                    >
                      {selectButton()}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>) : (
              <Grid container direction="column"
                justifyContent="center"
                alignItems="center" spacing={2}>
                <Typography>{info_text[activeStep].text}</Typography>
                <Grid item></Grid>
                <Grid item>
                  <Grid container direction="column"
                    justifyContent="center"
                    alignItems="center" spacing={2}>

                    {activeStep === 0 && (
                      <Alert severity="info">
                        <AlertTitle>
                          <strong>
                            {info_text[activeStep].textStep[0].main}
                          </strong>
                        </AlertTitle>
                        {info_text[activeStep].textStep[0].points}
                      </Alert>
                    )}

                    {activeStep === 0 && (
                      <Alert severity="success">
                        <AlertTitle>
                          <strong>
                            {info_text[activeStep].textStep[1].main}
                          </strong>
                        </AlertTitle>
                        {info_text[activeStep].textStep[1].points}
                      </Alert>
                    )}

                    {activeStep === 1 && (
                      <Steps_CalPumb
                        subActiveStep={subActiveStep}
                        info_alerts={info_text[1].textStep}
                        percentage={valper3}
                      />
                    )}
                    {activeStep === 2 && (

                      <>
                        <Alert severity="info">
                          <AlertTitle>
                            <strong>
                              {info_text[activeStep].textStep[2].main}
                            </strong>
                          </AlertTitle>
                        </Alert>
                      </>

                    )}

                    {subActiveStep === 1   && 
                      renderCalibrationForm("CalBomba", props.infoStepper)
                    }

                    {subActiveStep === 2 && (
                      (selectModo === 5 && (
                        <Box justifyContent="center" alignItems="center">
                          {circular_progress()}
                        </Box>
                      )
                      ))}

                  </Grid>
                  <Grid container direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={2}>
                    <Grid item>
                      <Button
                        disabled={activeStep === 0 || (activeStep === 2 && loading === true) || (subActiveStep === 2 && loading === true) || props.onPause}
                        onClick={handleBack}
                      >
                        Atrás
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={activeStep === info_text.length ? props.handleClose : handleNext}
                        disabled={(activeStep === 2 && loading === true) || (subActiveStep === 2 && loading === true) || props.onPause}
                      >
                        {selectButton()}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>)}
          </div>
        </div>
      ) : (
        // Nuevo Stepper
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {props.infoStepper.map((item, index) => (
              <Step key={index}>
                <StepLabel>{item.tittleStep}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {activeStep === info_text.length - 1 ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="success">
                    <AlertTitle> <strong>{info_text[activeStep].textStep[0].main}</strong> </AlertTitle>
                    {info_text[activeStep].textStep[0].points}
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={props.handleClose}
                      >
                        {selectButton()}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>{info_text[activeStep].text}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {activeStep === 0 && (
                        <Alert severity="info">
                          <AlertTitle>
                            <strong>
                              {info_text[activeStep].textStep[0].main}
                            </strong>
                          </AlertTitle>
                          {info_text[activeStep].textStep[0].points}
                        </Alert>
                      )}

                      {activeStep === 0 && (
                        <Alert severity="success">
                          <AlertTitle>
                            {" "}
                            <strong>
                              {info_text[activeStep].textStep[1].main}
                            </strong>{" "}
                          </AlertTitle>
                          {info_text[activeStep].textStep[1].points}
                        </Alert>
                      )}

                      {activeStep === 1 && (
                        <Alert severity="info">
                          <AlertTitle>
                            <strong>
                              {info_text[activeStep].textStep[0].main}
                            </strong>
                          </AlertTitle>
                          {info_text[activeStep].textStep[0].points}
                        </Alert>
                      )}

                      {activeStep === 2 && (
                        <Alert severity="success">
                          <AlertTitle>
                            <strong>
                              {info_text[activeStep].textStep[0].main}
                            </strong>
                          </AlertTitle>
                          {info_text[activeStep].textStep[0].points}
                        </Alert>
                      )}
                      {activeStep === 2 && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {circular_progress()}
                        </Grid>
                      )}

                      {activeStep === 2 && (
                        <p>{info_text[activeStep].textStep[1].note}</p>
                      )}

                      {activeStep === 3 &&
                        (props.typeCalibration === "CalPH" ? (
                          <Steps_PH4
                            header_text={info_text[activeStep].headers}
                            subActiveStep={subActiveStep}
                            info_alerts={info_text[activeStep].textStep}
                            percentage={valper}
                          />
                        ) : (
                          <Steps_1413
                            header_text={info_text[activeStep].headers}
                            subActiveStep={subActiveStep}
                            info_alerts={info_text[activeStep].textStep}
                            percentage={valper}
                          />
                        ))}

                      {subActiveStep === 2 && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {circular_progress()}
                        </Grid>
                      )}

                      {activeStep === 4 &&
                        (props.typeCalibration === "CalPH" ? (
                          <Steps_PH7
                            header_text={info_text[activeStep].headers}
                            subActiveStep={subActiveStep2}
                            info_alerts={info_text[activeStep].textStep}
                            percentage={valper2}
                          />
                        ) : (
                          <Steps_1288
                            header_text={info_text[activeStep].headers}
                            subActiveStep={subActiveStep2}
                            info_alerts={info_text[activeStep].textStep}
                            percentage={valper2}
                          />
                        ))}

                      {subActiveStep2 === 2 && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {circular_progress()}
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Button
                            disabled={
                              activeStep === 0 ||
                              (activeStep === 2 && loading === true) ||
                              (subActiveStep === 2 && loading === true) ||
                              (subActiveStep2 === 2 && loading === true) ||
                              props.onPause
                            }
                            onClick={handleBack}
                          >
                            Atrás
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={
                              activeStep === info_text.length
                                ? props.handleClose
                                : handleNext
                            }
                            disabled={
                              (activeStep === 2 && loading === true) ||
                              (subActiveStep === 2 && loading === true) ||
                              (subActiveStep2 === 2 && loading === true) ||
                              props.onPause
                            }
                          >
                            {selectButton()}
                          </Button>
                        </Grid>
                        {activeStep === 2 && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={loading || props.onPause}
                              onClick={props.handleClose}
                            >
                              Finalizar
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
