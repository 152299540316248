import React from "react";

import { IconButton } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import DialogNewCrop from "./NewCropDialog"

const IconButtonSettings = ({ classNameIcon,nameCrop,photoURL }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <IconButton aria-label="delete" size="medium" onClick={()=>setOpen(true)}>
      <SettingsIcon className={classNameIcon} fontSize="large" />
    </IconButton>

    <DialogNewCrop 
      open={open}
      handleClose={handleClose}
      nameCropToModify={nameCrop}
      />
    
    </>
  );
};

export default IconButtonSettings;
