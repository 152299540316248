import React from 'react'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { mexicoTimeZones } from '../../../../constants/TimeZones';


const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "90%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
}));

export const TimeZone = ({ selectedTimezone, newTimezone, timezoneChangeFlag }) => {
	const classes = useStyles();
	const allTimeZones = mexicoTimeZones;	

	const handleChangeTimezone = (event) => {
		newTimezone(event.target.value);
		timezoneChangeFlag();
	}

  return (
	<div>
		<label className={"mt-3"}>Zona Horaria:</label>
		<FormControl variant="outlined" className={classes.formControl}>
			<InputLabel id="timeZone-title">Zona Horaria actual</InputLabel>
			<Select
			labelId="simple-select-timezone"
			id="timezone-selector"
			value={selectedTimezone}
			onChange={handleChangeTimezone}
			label="Zona Horaria actual"
			labelWidth={0}
			>
				{allTimeZones.map((timezone) => (
					<MenuItem key={timezone.value} value={timezone.value}>{timezone.label}</MenuItem>
				))}
			</Select>
		</FormControl>
	</div>
  )
}
