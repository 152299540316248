import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../config/firebase";
import { UserContext } from "./UserProvider";
export const TimelapseContext = React.createContext();

const TimelapseProvider = (props) => {
  const { usuario } = React.useContext(UserContext);
  const { username } = usuario;

  const { crop } = useParams();
 
  

  const [timelapseData, setTimelapseData] = React.useState([]);

  React.useEffect(() => {
    const getAllTimelapsesData = async () => {
      const addr = `${username}/crop/${crop}/timelapse/plant`;
      const data = await db.collection(addr).orderBy("date", "asc").get();

      const arrayData = data.docs.map((doc) => ({ 
        id: doc.id, 
        ...doc.data(),
        date: doc.data().date.toDate()
      }));
      return arrayData;
    };

    const setStateTimelapseData = async () => {
      const localTimelapse = `${crop}/timelapse/plant`;
      const local = JSON.parse(localStorage.getItem(localTimelapse));

      if (local) {
        setTimelapseData(local);
      } else {
        const allTimelapsesInfo = await getAllTimelapsesData();
        setTimelapseData(allTimelapsesInfo);
        localStorage.setItem(localTimelapse, JSON.stringify(allTimelapsesInfo));
        console.log("updating local timelapse");
      }
    };

    setStateTimelapseData();
  }, [username,crop]);

  return (
    <TimelapseContext.Provider
      value={{
        timelapseData,
        setTimelapseData,
      }}
    >
      {props.children}
    </TimelapseContext.Provider>
  );
};

export default TimelapseProvider;
