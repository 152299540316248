import { Dashboard } from "@material-ui/icons";
import React from "react";
import DashboardProvider from "../../../context/DashboardProvider";
import DashBoard from "./DashBoard";

const DashboardWCtx = () => {
  return (
    <div>
      <DashboardProvider>
        <DashBoard />
      </DashboardProvider>
    </div>
  );
};

export default DashboardWCtx;
export { DashboardWCtx };
