import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress, DialogTitle } from "@material-ui/core";
import { UserContext } from "../../../../context/UserProvider";
import { CropContext } from "../../../../context/CropProvider";
import { functions, storage } from "../../../../config/firebase";

function BarTileIconDeleteDialog({
  open,
  handleClose,
  nameCrop,
}) {
  const { usuario } = React.useContext(UserContext);
  const { cropData, setCropData } = React.useContext(CropContext);
  

  const [loading, setLoading] = React.useState(false);
  const addr = `${usuario.username}/crop/${nameCrop}`;
  const namePhoto = nameCrop;

  const deleteCropFireStore = async () => {
    const deleteFn = functions.httpsCallable("recursiveDelete");
    await deleteFn({ path: addr });
  };

  const deletePhotoStorage = async () => {
    try {
      const imagenRef = await storage.ref().child(addr).child(namePhoto);
      await imagenRef.delete();
    } catch (error) {
      console.log(error.message_);
    }
  };

  const deleteCropPage = () => {
    const deletedCrop = cropData.filter((item) => item.name !== nameCrop);
    setCropData(deletedCrop);
    localStorage.setItem("crop", JSON.stringify(deletedCrop));
  };

  const deleteCrop = async () => {
    try {
      setLoading(true);
      await deleteCropFireStore();
      await deletePhotoStorage();
      deleteCropPage();

      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        ¿Está seguro que desea eliminar este cultivo?
      </DialogTitle>

      {cropData.find((item) => item.name === nameCrop).modules.length>0 && (
        <DialogContent>
          Al eliminar el cultivo los siguientes módulos pasarán a sus valores por
          defecto:
          <ul>
            {cropData
              .find((item) => item.name === nameCrop)
              .modules.map((module, i) => (
                <li key={i}>Módulo: "{module.name}"</li>
              ))}
          </ul>
        </DialogContent>
      )}

      {loading && (
        <div style={{ margin: "auto" }}>
          <CircularProgress color="primary" />
        </div>
      )}

      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={deleteCrop} color="secondary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default (BarTileIconDeleteDialog)