import { Grid, MenuItem, TextField } from '@material-ui/core'
import React from 'react'

export const OffsetsLoraNodes = ({ sensorTitle, sensorId, signValue, offsetValue, handleSignChange, handleOffsetChange }) => {
  return (
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-4">{sensorTitle}</div>
          <div className="col-6">
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id={`sign-${sensorId}`}
                  label="+ / -" 
                  value={signValue} 
                  select 
                  onChange={handleSignChange} 
                  size="small"
                  variant="outlined">
                  <MenuItem value={"0"}>+</MenuItem>
                  <MenuItem value={"1"}>-</MenuItem>
                </TextField>
              </Grid>
    
              <Grid item xs={12} sm={4}>
                <TextField
                  id={`offset-${sensorId}`}
                  label="Valor"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={offsetValue}
                  onChange={handleOffsetChange}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    step: 0.1, // Permite incrementar en 0.1
                    min: "0", // Valor mínimo que puedes definir según tus necesidades
                    // max: "10", // Valor máximo, opcional según tus necesidades
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
  )
}
