import { Collapse, FormControlLabel, IconButton, makeStyles, Switch, TableCell, TableRow, useMediaQuery, withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { IrrigationContext } from '../../../../context/IrrigationContext/IrrigationContext';
import { SelectorForm } from './SelectorForm';
import { KeyboardArrowDown, KeyboardArrowUp, RemoveCircle } from '@material-ui/icons';
import { TimeOnOff } from './TimeOnOff';
import { HourSelector } from './HourSelector';
import { AutomaticModeMobileView } from './AutomaticModeMobileView';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3B3C43",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
}))

export const AutomaticRows = ({ Id, numberOfRule }) => {
    //const {names} = useContext(UserContext)
    const {waterSource,waterPumps,zonesNames,startHour, setStartHour,minutos, setMinutos,segundos, setSegundos,
      pumpsSelected,setPumpsSelected,waterSelected, setWaterSelected,
      zonesSelected, setZonesSelected,daysOfWeek, setDaysOfWeek, typeSwitch,setTypeSwitch, timeON,timeOFF,setTimeON,
      setTimeOFF,endHour,setEndHour,rows, setRows, resetDataRow,setRemoveFlag,setRowsBeforeRemoved,days
    } = useContext(IrrigationContext);
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles();

    const optionsMinuts = [0,1,2,3,4,5,6,7,8,9,10]
    const optionsSeconds = [0,30]
    const minutsOnOff = [1,2,3,4,5,6,7,8,9,10,15,20,25,30,35,40,45,50,55,60]

    const [openB, setOpenB] = useState(false);
    const [openA, setOpenA] = useState(false);

    const removeRule = () => {
      const newRows = [...rows]
      setRowsBeforeRemoved([...rows])
      resetDataRow(Id);
      newRows.splice(Id,1);
      const newOrder = newRows.map((number,i) => {
        const newValue = i + 1;
        return newValue
      })
      setRows(newOrder)
      setRemoveFlag(true);      
    } 
  
    const handleSwitchChange = (event) => {
      //setState({ ...state, [event.target.name]: event.target.checked });
      const newStates = [...typeSwitch];
      const nameSwitch = event.target.name
      newStates[Id] = nameSwitch === 'checkedA' ? {
        [nameSwitch]: event.target.checked,
        checkedB: false  
      } 
      :
      {
        checkedA: false,
        [nameSwitch]: event.target.checked
      }
      if(nameSwitch === "checkedA") {
        event.target.checked && setOpenA(true)
        event.target.checked && setOpenB(false)
        !event.target.checked && setOpenA(false)

      } else {
        event.target.checked && setOpenB(true)
        event.target.checked && setOpenA(false)
        !event.target.checked && setOpenB(false)

      }
      
      setTypeSwitch([...newStates])
    };   
  
    const handleChangePumps = (event) => {
      const hArray = [...pumpsSelected]
      hArray[Id] = event.target.value
      setPumpsSelected(hArray) 
    };

    const handleChangeWater = (event) => {
      const hArray = [...waterSelected]
      hArray[Id] = event.target.value
      setWaterSelected(hArray);
    }
  
    const handleChangeZones = (event) => {
      const hArray = [...zonesSelected]
      hArray[Id] = event.target.value
      setZonesSelected(hArray)
    }

    const handleChangeMinutos = (event) => {
      const newMinuts = [...minutos];
      newMinuts[Id] = event.target.value; 
      setMinutos([...newMinuts])
    }

    const handleChangeSegundos = (event) => {
      const newSeconds = [...segundos];
      newSeconds[Id] = event.target.value; 
      setSegundos([...newSeconds])
    }

    const handleChangeDays = (event) => {
      const hArray = [...daysOfWeek]
      hArray[Id] = event.target.value
      setDaysOfWeek(hArray)
    }

    const handleStarHour = (event) => {
      const newSHour = [...startHour];
      newSHour[Id] = event.target.value;
      setStartHour([...newSHour])
    }

    const handleEndHour = (event) => {
      const newEHour = [...endHour];
      newEHour[Id] = event.target.value;
      setEndHour([...newEHour])
    }

    const handleChangeTimeOn = (event) => {
      const newTimes = [...timeON]
      newTimes[Id] = event.target.value
      setTimeON([...newTimes])
    }

    const handleChangeTimeOff = (event) => {
      const newTimes = [...timeOFF]
      newTimes[Id] = event.target.value
      setTimeOFF([...newTimes])
    }
  
    if (matches) {
      return <div className={classes.root}>{
        <AutomaticModeMobileView 
        numberOfRule={numberOfRule} 
        Id={Id}
        handleChangePumps={handleChangePumps}
        handleChangeWater={handleChangeWater} 
        removeRule={removeRule}
        handleChangeZones={handleChangeZones}
        handleStarHour={handleStarHour}
        handleSwitchChange={handleSwitchChange}
        handleChangeMinutos={handleChangeMinutos}
        handleChangeSegundos={handleChangeSegundos}
        openA={openA}
        openB={openB}
        setOpenA={setOpenA}
        setOpenB={setOpenB}
        optionsSeconds={optionsSeconds}
        optionsMinuts={optionsMinuts}
        minutsOnOff={minutsOnOff}
        handleEndHour={handleEndHour}
        handleChangeTimeOn={handleChangeTimeOn}
        handleChangeTimeOff={handleChangeTimeOff}
        handleChangeDays={handleChangeDays}/> 
        }
      </div>;
    }

  return (
    <>
            <StyledTableRow key={`StyledTable_${Id}`}>
              <StyledTableCell component="th" scope="row" key={`Row1_${Id}`}>
                {numberOfRule}
              </StyledTableCell>
              <StyledTableCell key={`Row2_${Id}`}>
                <SelectorForm
                valueSelected={pumpsSelected[Id]}
                handleChangeSelect={handleChangePumps} 
                arrayOfOptions={waterPumps}
                labelName={"Bombas"} 
                id={`1_${Id}`}
                />
               
              </StyledTableCell>

              <StyledTableCell key={`Row3_${Id}`}>
                    <SelectorForm
                    valueSelected={waterSelected[Id]}
                    handleChangeSelect={handleChangeWater} 
                    arrayOfOptions={waterSource}
                    labelName={"Agua"} 
                    id={`2_${Id}`}
                    />
              </StyledTableCell>

              <StyledTableCell key={`Row4_${Id}`}>
                    <SelectorForm
                    valueSelected={zonesSelected[Id]}
                    handleChangeSelect={handleChangeZones} 
                    arrayOfOptions={zonesNames}
                    labelName={"Zonas"} 
                    id={`3_${Id}`}
                    />
              </StyledTableCell>

              <StyledTableCell key={`Row5_${Id}`} >
                <HourSelector 
                Id={Id} 
                value={startHour[Id]} 
                handleChangeHour={handleStarHour} 
                name={"Hora de Inicio"}
                />
              </StyledTableCell>
             

              <StyledTableCell key={`Row6_${Id}`} >

              <FormControlLabel
                control={
                  <Switch
                    checked={typeSwitch[Id].checkedA}
                    onChange={handleSwitchChange}
                    name="checkedA"
                    color="primary"
                  />
                }
                // label="Activacion Unica"
                // labelPlacement="top"
              />

              <IconButton 
              aria-label="expand row" 
              size="small" 
              onClick={() => setOpenA(!openA)}
              disabled={typeSwitch[Id].checkedB}>
                  {openA ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  {openA ? "Cerrar" : "Mostrar"}
              </IconButton>


              <Collapse in={openA} timeout="auto" unmountOnExit> 

              <TimeOnOff 
              Id={Id} 
              value={minutos[Id]} 
              handleChangeSelect={handleChangeMinutos}
              arrayOfOptions={optionsMinuts}
              name={"Minutos"}
              />

              <TimeOnOff 
              Id={Id} 
              value={segundos[Id]} 
              handleChangeSelect={handleChangeSegundos}
              arrayOfOptions={optionsSeconds}
              name={"Segundos"}
              />
                    
              </Collapse>
              </StyledTableCell>

              <StyledTableCell key={`Row8_${Id}`}>

                <FormControlLabel
                  control={
                    <Switch
                      checked={typeSwitch[Id].checkedB}
                      onChange={handleSwitchChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  // label="Activacion Multiple"
                  // labelPlacement="top"
                />

                <IconButton aria-label="expand row" 
                size="small" 
                onClick={() => setOpenB(!openB)} 
                disabled={typeSwitch[Id].checkedA}>
                  {openB ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  {openB ? "Cerrar" : "Mostrar"}
                </IconButton>

                <Collapse in={openB} timeout="auto" unmountOnExit> 
                <HourSelector 
                Id={Id} 
                value={endHour[Id]} 
                handleChangeHour={handleEndHour} 
                name={"Hora de Fin"}
                />

                <TimeOnOff 
                Id={Id} 
                value={timeON[Id]} 
                handleChangeSelect={handleChangeTimeOn} 
                arrayOfOptions={minutsOnOff} 
                name={"Minutos ON"}
                />

                <TimeOnOff 
                Id={Id} 
                value={timeOFF[Id]} 
                handleChangeSelect={handleChangeTimeOff} 
                arrayOfOptions={minutsOnOff} 
                name={"Minutos OFF"}
                />

              </Collapse>
                
              </StyledTableCell>

              <StyledTableCell key={`Row9_${Id}`}>
                    <SelectorForm
                    valueSelected={daysOfWeek[Id]}
                    handleChangeSelect={handleChangeDays} 
                    arrayOfOptions={days}
                    labelName={"Dias"} 
                    id={`6_${Id}`}
                    />
              </StyledTableCell>

              <StyledTableCell key={`Row10_${Id}`} >
                <IconButton key={`removeRule_${Id}`} color="secondary" aria-label="remove_rule" id={`remove_${Id}`} component="span" onClick={removeRule}>
                    <RemoveCircle />
                </IconButton>  
              </StyledTableCell>
            </StyledTableRow>
          
    </>
  )
}
