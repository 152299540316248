import React from "react";
import withWidth from "@material-ui/core/withWidth";
import TimelapseProvider from "../../../context/TimelapseProvider";
import TimelapseApp from "./components/TimelapseApp";

const TimelapseWithContext = (props) => {
  const { width } = props;
  return (
    <TimelapseProvider>
      <TimelapseApp width={width}/>
    </TimelapseProvider>
  );
};

const Timelapse = React.memo(withWidth()(TimelapseWithContext));

export { Timelapse };
export default Timelapse;
