import React from 'react';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {Backup} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  //buttonSuccess: {backgroundColor: green[500],'&:hover': {backgroundColor: green[700],},},
  
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));



const UpdatingButton = (props) => {

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          //className={buttonClassname}
          disabled={props.disabled}
          //onClick={handleButtonClick}
          fullWidth
          startIcon={<Backup />}
          type={props.type}
        >
          {props.disabled? "subiendo":"subir"}
          
        </Button>
        {props.disabled && <CircularProgress size={30} className={classes.buttonProgress} />}
      </div>
      
    </div>
  )
}

export default UpdatingButton
