import {
  getAllDeviceDataFromConfigModule,
} from "./sharedFunctions";
async function getAllUidConfigData({ username, dataMacCan }) {
  let dataConfiguracion = [];
  let uidValueList = [];
  const auxDataConfig = dataMacCan.map(async (itemMac) => {
    const mac = itemMac.mac;
    const auxDataConfigCanId = itemMac.cans.map(async (uniCanIDxMac) => {
      const dataConfig = await getAllDeviceDataFromConfigModule(
        mac,
        uniCanIDxMac.id,
        username
      );
      return { dataConfig };
    });
    const dataConfigCanId = await Promise.all(auxDataConfigCanId);
    return dataConfigCanId;
  });
  const data = await Promise.all(auxDataConfig);
  data.forEach((uniDataMac, idxMac) => {
    uniDataMac.forEach((uniDataCanId, idxCan) => {
      uniDataCanId.dataConfig.forEach((uniData) => {
        dataConfiguracion = [
          ...dataConfiguracion,
          {
            name: uniData.item.name,
            outid: uniData.item.outid,
            kind: uniData.item.kind,
            id: uniData.item.id,
            canid: uniData.item.canid,
            mac: uniData.item.mac,
            uid: uniData.id,
          },
        ];
        uidValueList.push({
          uid: uniData.id,
          typeCan: dataMacCan[idxMac].cans[idxCan].type,
        });
      });
    });
  });
  return {
    dataConfiguracion,
    uidValueList,
  };
}

export { getAllUidConfigData };
