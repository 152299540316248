import React from 'react'

import SelectMultiple from '../../../../Common/SelectMultiple'
import "../AdvancedConfig.css"
import { continuosSensors } from '../../../../../constants/globalConst';
import { Tooltip } from '@material-ui/core';

const Operators1=[
    {value:0,name:'=='},
    {value:1,name:'>'},
    {value:2,name:'>='},
    {value:3,name:'<'},
    {value:4,name:'<='},
    {value:5,name:'InsideOf'},
    {value:6,name:'OutsideOf'},
    {value:7,name:'Or_Igual'},
    {value:8,name:'!='},
    {value:9,name:'DiferentPast'},
    {value:10,name:'SendDiferentDate'},
];

/*
const decodifier=(name)=>{
    return String( Operators1.find(item=>name===item.name).value )
}
*/
const renderHinput=(operator)=>{//Dependiendo del operador muestra H input
    //if(kind1!=="D"){console.log("object",kind1)}
    
    if(operator==='5' || operator==='6' || operator==='7'){
        return true;
    }
}

const renderLinput=(operator)=>{
    if(operator==='9' || operator==='10'){
        
        return true;
    }
}


const AdvancedConfig = (props) => {

    
    const [operators,setOperators]=React.useState(Operators1)
    const [valueOperator,setValueOperator]=React.useState(props.data.operator)
    const [flag,setFlag]=React.useState(false)
    const [kind,setKind]=React.useState("0")
    const [toUidArray,setToUidArray]=React.useState(null)
    const typesOfEvents = [{value: "Notification", name: "Notificacion"},
                                    { value: "Actuation", name: "Accionamiento"}]
    
    
    React.useEffect(()=>{
        
        
        // Es para el select de lo operadores que solo se muestren los que queremos, dependiendo del seleccionaado
        const arrayDeCadenas = props.data.fromUid.split("@");
        const kind1=arrayDeCadenas[2]
        setKind(kind1)//Switch, H,Soil etc
        if(props.fromToComponents){
            if(kind1==="3"){//Switch
                setOperators(Operators1.filter((item)=> item.name==="==" || item.name==="!="))
                //console.log(props.fromToComponents.toUid)
                setToUidArray(props.fromToComponents.toUid)
                
            
                //setToUid(props.fromToComponents.toUid)
            }else if(continuosSensors.includes(kind1)){
                setOperators(Operators1.filter((item)=> item.name!=="==" && item.name!=="!=" && 
                item.name!=="DiferentPast" && item.name!=="SendDiferentDate" && 
                item.name!=="Or_Igual" && item.name !== "InsideOf" && item.name !== "OutsideOf"))
                //console.log(props.data.toUid)
                setToUidArray(props.fromToComponents.toUid)         
                
            }else{
                setOperators(Operators1.filter((item)=> item.name==="SendDiferentDate"))
                
                const dataMac=props.dataMacCan.filter(item=>item.mac===props.macCan.mac)
                const dataCan=dataMac[0].cans.filter(item=>item.id!==props.macCan.canid)
                const arrayOptions=dataCan.map(item=>({
                    value:`mac@${item.id}@0@0`,
                    name:item.name? item.name:`Node_${item.id}`
                }))
                
                
                setToUidArray(arrayOptions)
                //array:arrayOptions,
                //value:`mac@${dataCan[0].id}@0@0`

                //const key=itemCan.name? itemCan.name:`Node_${itemCan.id}`
                //console.log(arrayOptions)
                
                
            }
        }
        
        

    },[props])

    
    React.useEffect(()=>{
        //Para que no se haga cuando se cargue pero si despues que se cargue
        //Valor por default de valueOperator cuando hace cambio de operador
        //Para cuando se modifique el select fromUid, el valor se ponga a default
        //y el usuario escoja, si se quita se puede quedar con el valor pasado sin darnos cuenta
        
        //const arrayDeCadenas = props.data.fromUid.split("@");
        //const kind1=arrayDeCadenas[2]
        if(flag===true){
            setValueOperator("")
            
        }else{
            setFlag(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.data.fromUid])
    


    React.useEffect(()=>{
        //Para que no se haga cuando se cargue pero si despues que se cargue
        //Valor por default de valueOperator cuando hace cambio de operador
        setValueOperator(props.data.operator)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.data.operator])

   

    

    
    
    return props.fromToComponents&&toUidArray&&(
        <div className="table-container-cell">
            <div className="table-row">
                <div className="table-cell-rows">
                    <span className="badge">#{props.i+1}</span>
                    <span className="icon ml-2">if</span>
                </div>
                <div className="table-cell-rows">
                    <SelectMultiple
                    id={`FromUid/${props.i}`}
                    value={props.data.fromUid}
                    handleToggle={props.onHandle}
                    desactivar={props.disabled}
                    arrayOptions={props.fromToComponents.fromUid}
                    maxWidth={"140px"}
                    className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2"
                    />
                </div>
                <div className="table-cell-rows">
                    <SelectMultiple
                        id={`Operator/${props.i}`}
                        value={valueOperator}
                        handleToggle={props.onHandle}
                        desactivar={props.disabled}
                        arrayOptions={operators}
                        maxWidth={"90px"}
                        className="col-lg-4 col-md-5 col-sm-5 col-5 mt-2"
                    />                    
                </div>
                <div className="table-cell-rows">
                    {/* <input type="text" placeholder="Value" /> */}
                    {
                        !renderLinput(props.data.operator)&&kind!=="D" &&(
                            <input 
                                className="col-lg-8 col-sm-6 col-6 mr-1 form-control" 
                                type="number" 
                                min="0"
                                max={props.data.operator==="0" && kind==="3"?"1":""}//operator=="0"  es "==" y Kind==="3" es Switch
                                id={`LValue/${props.i}`}
                                name={`LValue/${props.i}`}
                                value={props.data.whenLValue}
                                onChange={(e)=>props.onHandle(e.target)}
                                disabled={props.disabled}
                            />
                        )

                    }
                    
                    {  
                        renderHinput(props.data.operator)&&kind!=="D" && (
                            <>
                                
                                <span className="badge badge-info d-inline-flex align-items-center ">
                                    ,
                                </span>
                                

                                    <input 
                                    className="col-lg-5 col-sm-5 col-5  form-control " 
                                    type="number" 
                                    min={parseInt(props.data.whenLValue)+1}
                                    max={props.data.operator==="0" && kind==="3"?"1":""}//operator=="0"  es "==" y Kind==="3" es Switch
                                    id={`HValue/${props.i}`}
                                    name={`HValue/${props.i}`}
                                    value={props.data.whenHValue}
                                    onChange={(e)=>props.onHandle(e.target)}
                                    disabled={props.disabled}
                                />
                            </>
                            
                        )
                    }
                    <span className="icon">{ kind==="D"? "to":"Then"}</span>
                </div>
                <div className="table-cell-rows">
                    {/* <select>
                        <option>Event</option>
                    </select> */}
                    <SelectMultiple
                    id={`Event/${props.i}`}
                    value={props.data.event}
                    handleToggle={props.onHandle}
                    desactivar={props.disabled}
                    arrayOptions={typesOfEvents}
                    maxWidth={"100px"}
                    className="col-lg-10 col-sm-10 col-10 mt-2 "
                    />
                </div>
                <div className="table-cell">
                    {/* Salida */}
                    {/* <select>
                        <option>OFF</option>
                    </select> */}
                    {props.data.event === "Notification" && (
                        <SelectMultiple
                        id={`notification/${props.i}`}
                        value={props.data.typeOfNotification}
                        handleToggle={props.onHandle}
                        desactivar={props.disabled}
                        arrayOptions={props.arrayOfNotificationsTypes}
                        maxWidth={"100px"}
                        className="col-lg-10 col-sm-10 col-10 mt-2 "
                        />
                    )}
                    {props.data.event === "Actuation" && (
                        <SelectMultiple
                        id={`toUid/${props.i}`}
                        value={props.data.toUid}
                        handleToggle={props.onHandle}
                        desactivar={props.disabled}
                        arrayOptions={toUidArray}
                        maxWidth={"100px"}
                        className="col-lg-10 col-sm-10 col-10 mt-2 "
                        />
                    )}
                
                    
                </div>
                
                <div className="table-cell-rows">
                    
                    {
                        (kind!=="D" && props.data.event === "Actuation") && (
                            <>
                                {/* <span className="badge badge-info d-inline-flex align-items-center mt-2 col-1 col-lg-2 mt-2" >
                                    =
                                </span> */}
                                <span className='icon'> = </span>
                                <SelectMultiple
                                    id={`set/${props.i}`}
                                    value={props.data.set}
                                    handleToggle={props.onHandle}
                                    desactivar={props.disabled}
                                    arrayOptions={[{value:0,name:'OFF'},{value:1,name:'ON'}]}
                                    maxWidth={"80px"}
                                    className="mt-2  col-lg-7 col-10"
                                /> 
                            </>
                                                  

                        )

                    }
                    <Tooltip title="Eliminar Regla" arrow>
                    <button 
                        type="button" 
                        className="delete-btn" 
                        name={`delate/${props.i}`}
                        onClick={(e)=>props.onHandle(e.target)}
                        disabled={props.disabled}
                        >
                        X
                    </button>
                    </Tooltip>
                </div>
            </div>
        </div>
        // <div className="row p-2">



        //     <div className="col-lg-5 col-md-12 col-12 ">
        //         <div className="row">
        //             <span className="badge badge-dark d-inline-flex align-items-center mt-2">
        //                 #{props.i+1}
        //             </span>

        //             <span className="badge badge-info mx-1 d-inline-flex align-items-center mt-2">
        //                 If
        //             </span>
                    
        //             <SelectMultiple
        //                 id={`FromUid/${props.i}`}
        //                 value={props.data.fromUid}
        //                 handleToggle={props.onHandle}
        //                 desactivar={props.disabled}
        //                 arrayOptions={props.fromToComponents.fromUid}
        //                 className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2"
        //             />

        //             <SelectMultiple
        //                 id={`Operator/${props.i}`}
        //                 value={valueOperator}
        //                 handleToggle={props.onHandle}
        //                 desactivar={props.disabled}
        //                 arrayOptions={operators}
        //                 className="col-lg-4 col-md-5 col-sm-5 col-5 mt-2"
        //             />

        //         </div>
        //     </div>  
            

            

            


        //     <div className="col-lg-1 col-md-12 mt-2">
        //         <div className="row">

                    
        //             {
        //                 !renderLinput(props.data.operator)&&kind!=="D" &&(
        //                     <input 
        //                         className="col-lg-7 col-sm-6 col-6 mr-1 form-control " 
        //                         type="number" 
        //                         min="0"
        //                         max={props.data.operator==="0" && kind==="3"?"1":""}//operator=="0"  es "==" y Kind==="3" es Switch
        //                         id={`LValue/${props.i}`}
        //                         name={`LValue/${props.i}`}
        //                         value={props.data.whenLValue}
        //                         onChange={(e)=>props.onHandle(e.target)}
        //                         disabled={props.disabled}
        //                     />
        //                 )

        //             }
                    
        //             {  
        //                 renderHinput(props.data.operator)&&kind!=="D" && (
        //                     <>
                                
        //                         <span className="badge badge-info d-inline-flex align-items-center ">
        //                             ,
        //                         </span>
                                

        //                             <input 
        //                             className="col-lg-5 col-sm-5 col-5  form-control " 
        //                             type="number" 
        //                             min={parseInt(props.data.whenLValue)+1}
        //                             max={props.data.operator==="0" && kind==="3"?"1":""}//operator=="0"  es "==" y Kind==="3" es Switch
        //                             id={`HValue/${props.i}`}
        //                             name={`HValue/${props.i}`}
        //                             value={props.data.whenHValue}
        //                             onChange={(e)=>props.onHandle(e.target)}
        //                             disabled={props.disabled}
        //                         />
        //                     </>
                            
        //                 )
        //             }
        //         </div>
                    

        //     </div>

        //     <div className="col-lg-1 col-sm-12">
        //         <span className="badge badge-info d-inline-flex align-items-center mt-2 col-1 col-lg-2">
                    
        //             { kind==="D"? "to":"Then"}
        //         </span>
        //         <div className="row">

        //             <SelectMultiple
        //                 id={`toUid/${props.i}`}
        //                 value={props.data.toUid}
        //                 handleToggle={props.onHandle}
        //                 desactivar={props.disabled}
        //                 arrayOptions={toUidArray}
        //                 className="col-lg-5 col-sm-10 col-10 mt-2 "
        //             />
                
        //         </div>

        //     </div>

        //     <div className="col-lg-2 col-sm-12">
        //         <div className="row">
        //             {/* <span className="badge badge-info d-inline-flex align-items-center mt-2 col-1 col-lg-2">
                        
        //                 { kind==="D"? "to":"Then"}
        //             </span> */}

        //             <SelectMultiple
        //                 id={`toUid/${props.i}`}
        //                 value={props.data.toUid}
        //                 handleToggle={props.onHandle}
        //                 desactivar={props.disabled}
        //                 arrayOptions={toUidArray}
        //                 className="col-lg-10 col-sm-10 col-10 mt-2 "
        //             />
                
        //         </div>

        //     </div>

            
        //     <div className="col-lg-2 justify-content:center align-items:center">
        //         <div className="row">
                    

        //             {
        //                 kind!=="D" && (
        //                     <>
        //                         <span className="badge badge-info d-inline-flex align-items-center mt-2 col-1 col-lg-2 mt-2" >
        //                             =
        //                         </span>
        //                         <SelectMultiple
        //                             id={`set/${props.i}`}
        //                             value={props.data.set}
        //                             handleToggle={props.onHandle}
        //                             desactivar={props.disabled}
        //                             arrayOptions={[{value:0,name:'OFF'},{value:1,name:'ON'}]}
        //                             className="mt-2  col-lg-7 col-10"
        //                         /> 
        //                     </>
                                                  

        //                 )

        //             }
                    

        //             <button 
        //                 type="button" 
        //                 className="btn btn-danger btn-sm mt-2 col-lg-2 col-1" 
        //                 name={`delate/${props.i}`}
        //                 onClick={(e)=>props.onHandle(e.target)}
        //                 disabled={props.disabled}
        //                 >
        //                 X
        //             </button>

        //         </div>
        //     </div>
            

           

        //     {/* <hr/> */}
                               
        // </div>
    )
}

export default AdvancedConfig
