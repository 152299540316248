import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../context/UserProvider";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper } from "@material-ui/core";
import HeadTile from "./HeadTile";
import { HIGHCHART_OPT_SPANISH, continuosSensors } from "../../../../constants/globalConst";
import { db } from "../../../../config/firebase";
import {
  get_Uid_Value_ForAnalog,
  getDataFromDB,
} from "../../../../context/functions/DashboardFunctions/divideUIDByKind";
import { DashboardContext } from "../../../../context/DashboardProvider";

const useStyles = makeStyles({
  paperGraphics: {
    width: "215px",
    height: "170px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifycontent: "center",
    background: "transparent",
  },
  minmaxText: {
    color: "#ACACAC",
    fontSize: "8px",
  },
  noGraphText: {
    color: "#ACACAC",
  },
  titleGraph: {
    color: "#000",
    fontSize: "30px",
  },
  subtitleGraph: {
    color: "#5BB033",
    fontSize: "20px",
  },
});

const ContinousSensorTile = ({
  col,
  data,
  onClickCrossButton,
  style,
}) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const { allTiles } = useContext(DashboardContext);
  const classes = useStyles();
  const [minVal, setminVal] = useState(Infinity);
  const [maxVal, setmaxVal] = useState(-Infinity);
  const [series, setSeries] = useState([]);
  const [valArrayChart, setvalArrayChart] = useState([]);
  const { name, uid } = data;
  const [value, setValue] = useState(null);
  const [unixTime, setUnixTime] = useState(Math.floor(Date.now()));
  const [prevValue, setPrevValue] = useState(null);
  const { username } = usuario;
  const [optionsHighChart, setOptionsHighChart] = useState({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    chart: {
      height: "150px",
      type: "area",
      backgroundColor: "transparent",
      borderRadius: 10,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#5BB033"],
            [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: "",
      margin: 0,
    },
    xAxis: {
      type: "datetime",
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
    },
    yAxis: [
      {
        gridLineColor: "transparent",
        title: {
          align: "high",
          offset: 0,
          text: "",
          rotation: 0,
          y: -10,
        },
        labels: {
          enabled: false,
        },
      },
      {
        gridLineColor: "transparent",
        title: {
          align: "high",
          offset: 0,
          text: "",
          rotation: 0,
          y: -10,
        },
        labels: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  });
  
  Highcharts.setOptions(HIGHCHART_OPT_SPANISH);

  useEffect(() => {
    /* let addData = [];
      const takeValue = (newValue) => {
        setUnixTime(Math.floor(Date.now()));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        addData = [unixTime, newValue];
      };
    
      const setFullfillColor = (ini, fin) => {
        const js = {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.color("#5BB033").setOpacity(ini).get("rgba")],
            [1, Highcharts.color("#5BB033").setOpacity(fin).get("rgba")],
          ],
        };
        return js;
      };
      
      const updateData = () => {
        // Aquí se actualizan los datos de la gráfica
        let newData = [];
    
        newData = valArrayChart;
        //console.log("Esto es newData:", newData);
    
        takeValue(parseFloat(value));
        newData.push(addData);
    
        //Condicion que elimina datos cuando son mas de 'limit'
        const limit = 20; //Esta constante se modifica si quieres otro limite de valores a mostrar
        if (newData.length > limit) {
          newData.splice(0, newData.length - limit);
        }
    
        //Configuracion para enviar a highcharts con nuevos datos
        const newSerie = {
          name: name,
          label: name,
          data: newData,
          yAxis: 0,
    
          color: Highcharts.color("#5BB033").setOpacity(1).get("rgba"),
          type: "areaspline",
          fillColor: setFullfillColor(0.2, 0),
          uid: uid,
        };
    
        return newSerie;
      }; */

    const fetchAllData = async (uid) => {
          try {

            /* if (allTiles[1].length >= 2 || allTiles[2].length >= 2 || allTiles[3].length >= 2) {
              for (let index = 1; index < 4; index++) {
                const actualUid = allTiles[index].filter((item) => item.uid === uid)
                
              }
            } */
            // Usar Promise.all para esperar a que todas las peticiones se completen
            const results = await getDataFromDB(uid, username);
            //console.log("Esto es results:", results);
            setvalArrayChart([...results.valArray]);
            setminVal(results.minVal);
            setmaxVal(results.maxVal);
  
            // Una vez todas las promesas se resuelvan, actualizar el estado basado en los resultados
           /*  results.forEach(({ valArray, minVal, maxVal }) => {
              setvalArrayChart(prevValArray => [...prevValArray, ...valArray]);
              // Actualizar minVal y maxVal según sea necesario
              setminVal(prevMin => Math.min(prevMin, minVal));
              setmaxVal(prevMax => Math.max(prevMax, maxVal));
            }); */
          } catch(err) {
            console.log({ err });
            setvalArrayChart([]);
            setminVal(Infinity);
            setmaxVal(-Infinity);
          }
        
      
    };

    /* function getSerie() {
      
        let newSerie = updateData();
        setSeries([newSerie]);
        //console.log("Esto es value:",value)
    } */

    
    //console.log("Esto es uid:",uid)
    //const testUid = "M0CE9E68E0D84@1@8@0"
  
    fetchAllData(uid);
    //getSerie();
  }, [uid, username]);
  


  

  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const kind = String(uid.split("@")[2]);
    const outid = String(uid.split("@")[3]);
    const idxMac = dataMacCan.findIndex((elmt) => {
      return String(elmt.mac) === String(mac);
    });
    const idxCan = dataMacCan[idxMac].cans.findIndex((elmt) => {
      return String(elmt.id) === String(canId);
    });
    const { username } = usuario;
    const docPath = `${username}/infoDevices/${mac}/${canId}/fromModule/render`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      const resp = get_Uid_Value_ForAnalog({
        fromModuleActual: data,
        kind: kind,
        outid: outid,
        typeOfModule: dataMacCan[idxMac].cans[idxCan].type,
      });
      //console.log("Esto es resp:", resp);
      //console.log("Esto es valArrayChart:", valArrayChart)
      let lastValueGraph = null
      if (valArrayChart.length > 0) {
        lastValueGraph = valArrayChart[valArrayChart.length - 1][1];
        
      }
      if (resp.value !== prevValue) {
        //console.log({ uid, income: resp.value, lastValueGraph, if1: (Math.abs(resp.value - lastValueGraph) < Math.abs(lastValueGraph * 0.5)), minVal, maxVal });
        //console.log("Entre a condicional_1");
        //console.log("Esto es prevValue:",prevValue);
        if ((Math.abs(resp.value - lastValueGraph) < Math.abs(lastValueGraph * 0.5))) {
          setPrevValue(resp.value);
          setValue(resp.value);
          if (maxVal < resp.value) {
            setmaxVal(resp.value);
          } else if (resp.value < minVal) {
            setminVal(resp.value);
          }
        } else if (lastValueGraph === null) {
          setPrevValue(resp.value);
          setValue(resp.value);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [usuario, uid]);

  /* function getSerie() {
    if (value > 0.4) {
      let newSerie = updateData();
      setSeries([newSerie]);
    }
  } */

  /* const updateData = () => {
    // Aquí se actualizan los datos de la gráfica
    let newData = [];

    newData = valArrayChart;
    //console.log("Esto es newData:", newData);

    takeValue(parseFloat(value));
    newData.push(addData);

    //Condicion que elimina datos cuando son mas de 'limit'
    const limit = 20; //Esta constante se modifica si quieres otro limite de valores a mostrar
    if (newData.length > limit) {
      newData.splice(0, newData.length - limit);
    }

    //Configuracion para enviar a highcharts con nuevos datos
    const newSerie = {
      name: name,
      label: name,
      data: newData,
      yAxis: 0,

      color: Highcharts.color("#5BB033").setOpacity(1).get("rgba"),
      type: "areaspline",
      fillColor: setFullfillColor(0.2, 0),
      uid: uid,
    };

    return newSerie;
  }; */

  /* s */

  useEffect(() => {
    try {
      let addData = [];
      const takeValue = (newValue) => {
        setUnixTime(Math.floor(Date.now()));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        addData = [unixTime, newValue];
      };
    
      const setFullfillColor = (ini, fin) => {
        const js = {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.color("#5BB033").setOpacity(ini).get("rgba")],
            [1, Highcharts.color("#5BB033").setOpacity(fin).get("rgba")],
          ],
        };
        return js;
      };
      
      const updateData = () => {
        // Aquí se actualizan los datos de la gráfica
        let newData = [];
    
        newData = valArrayChart;
        //console.log("Esto es newData:", newData);
    
        takeValue(parseFloat(value));
        newData.push(addData);
    
        //Condicion que elimina datos cuando son mas de 'limit'
        const limit = 20; //Esta constante se modifica si quieres otro limite de valores a mostrar
        if (newData.length > limit) {
          newData.splice(0, newData.length - limit);
        }
    
        //Configuracion para enviar a highcharts con nuevos datos
        const newSerie = {
          name: name,
          label: name,
          data: newData,
          yAxis: 0,
    
          color: Highcharts.color("#5BB033").setOpacity(1).get("rgba"),
          type: "areaspline",
          fillColor: setFullfillColor(0.2, 0),
          uid: uid,
        };
    
        return newSerie;
      };

      function getSerie() {
        if (value > 0.4) {
          let newSerie = updateData();
          setSeries([newSerie]);
          //console.log("Esto es value:",value)
        }
      }

      getSerie();
    } catch(error){
      console.error("Este fue el error:",error)
    }
    
  }, [valArrayChart]);

  useEffect(() => {
    setOptionsHighChart((prevData) => {
      return { ...prevData, series: series };
  });
  }, [series]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile
        name={name}
        uid={uid}
        onClickCrossButton={onClickCrossButton}
        col={col}
      />

      <Grid item xs={5}>
        <h5>
          <span className="badge badge-dark">{value}</span>
        </h5>
      </Grid>
      {valArrayChart.length > 0 ? (
        <Paper className={classes.paperGraphics} elevation={0}>
          <Grid item xs={8}>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsHighChart}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justifycontent="space-between"
              alignItems="center"
              spacing={8}
            >
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>
                  {maxVal}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>
                  {minVal}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.paperGraphics} elevation={0}>
            <Typography variant={"h6"} className={classes.noGraphText}>
              Sin Gráfica
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
const areEqual = (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
};
export default React.memo(ContinousSensorTile, areEqual);
