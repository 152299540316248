import React from "react";
import DynamicBox from "./DynamicBox";
import Navbar from "./Navbar";

const NavDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const onClickDynamicBox = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Navbar onClickDynamicBox={onClickDynamicBox} />
      <DynamicBox open={open} onClickDynamicBox={onClickDynamicBox} />
    </div>
  );
};

export default NavDrawer;
