import { getTilesPositions } from "./sharedFunctions";
import { VIDEO_ITEM, REMINDER_ITEM } from "../../../constants/globalConst";

async function getAllTiles({ allUidConfigData, username }) {
  const render = await getTilesPositions(username);

  const keys = Object.keys(render);
  const keysOrdered = keys.map((item, i) => render[`col_${i}`]); //ordenamos ids

  let newTilesWithInfo = [];
  keysOrdered.forEach((col, idx) => {
    let uniDataXCol = [];
    uniDataXCol.push({
      uid: `${idx}_0`,
      kind: "ghost",
    });
    col.forEach((uidName) => {
      if (uidName === VIDEO_ITEM) {
        uniDataXCol.push({ uid: VIDEO_ITEM, kind: VIDEO_ITEM });
      } else if (uidName === REMINDER_ITEM) {
        uniDataXCol.push({ uid: REMINDER_ITEM, kind: REMINDER_ITEM });
      } else {
        const arrayCadenas = uidName.split("@");
        if(arrayCadenas[1] === "node"){
          uniDataXCol.push({ uid: uidName, kind: "nodeLora" });
          
        } else{
          let found = allUidConfigData.find((uniData) => {
            return uniData.uid === uidName;
          });
          uniDataXCol.push(found);
        }
        
      }
    });
    newTilesWithInfo.push(uniDataXCol);
  });
  return newTilesWithInfo;
}

export { getAllTiles };
