import React from "react";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
export default function ResponsiveDateTimeInterval({
  selectedDate,
  handleDateChange,
  id,
  ...props
}) {
  // The first commit of Material-UI

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        value={selectedDate}
        onChange={(e) => {
          handleDateChange(e, id);
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
export { ResponsiveDateTimeInterval };
