import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'


export const ConfirmationPopup = ({ popupIsOpen, handleClosePopup, handleChangeSettings }) => {
  return (
	<div>
		<Dialog
        open={popupIsOpen}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      	>
        <DialogTitle id="confirmation-title">{"¿Quieres guardar los nuevos Ajustes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          ¿Deseas guardar los cambios que has realizado en los ajustes? Si no guardas,
          se perderán todos los cambios realizados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleChangeSettings} color="primary" variant='contained' autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
	</div>
  )
}
