import { INITIAL_FIXED_GHOST_COLUMNS } from "../constants";

import { db } from "../../../../config/firebase";

const getTilesPositions = async (username) => {
  const renderMain = await db.collection(username).doc("renderMain").get();
  const renderMainData = renderMain.data();

  let tilesPositions = {};
  if (renderMainData !== undefined) {
    if (renderMainData.render !== undefined) {
      tilesPositions = renderMainData.render;
    }
  }
  return tilesPositions;
};

const saveTilesPositions = async (state, username) => {
  const render = getTilesJsonForPositions(state);

  try {
    await db.collection(username).doc("renderMain").set({ render });
  } catch (error) {
    console.log(error);
  }
};

const getTilesJsonForPositions = (state) => {
  let newState = [...state];

  newState = newState.map((col, index) =>
    col.filter((fil) => fil.uid !== INITIAL_FIXED_GHOST_COLUMNS[index].uid)
  );

  const cloneStateJustUid = newState.map((col) => col.map((fil) => fil.uid));

  //FireBase does not accept array nested so with Json
  let render = {};
  cloneStateJustUid.forEach((columnsItem, i) => {
    const nameColumn = `col_${i}`;
    render[nameColumn] = columnsItem;
  });
  return render;
};

const getNewPositionFromHiddenCards = (source, destination, cropCards, cardsMenu) => {
  const sInd = +source.droppableId;
  const dInd = +destination.droppableId;
  if(dInd === 0) {
    const result = moveTileDiferentColumn(
      cropCards[sInd],
      cardsMenu,
      source,
      destination
    );
    const newTabArray = [...cropCards]
    newTabArray[sInd] = result[sInd];
    const newTilesPos = {}
    newTilesPos[source.droppableId] = newTabArray
    newTilesPos[destination.droppableId] = result[0]
    return newTilesPos

  } else {
    const result = moveTileDiferentColumn(
      cardsMenu,
      cropCards[dInd],
      source,
      destination
    );
    const newTabArray = [...cropCards]
    newTabArray[dInd] = result[dInd];
    const newTilesPos = {}
    newTilesPos[source.droppableId] = result[0];
    newTilesPos[destination.droppableId] = newTabArray
    return newTilesPos
  }
}

const getNewPositionsWhenTileMoved = (source, destination, oldState) => {
  const sInd = +source.droppableId;
  const dInd = +destination.droppableId;
  const isSameColumn = sInd === dInd;
  if (isSameColumn) {
    const items = moveTileSameColumn(
      oldState[sInd],
      source.index,
      destination.index
    );
    const newState = [...oldState];
    newState[sInd] = items;
    return newState;
  }

  const result = moveTileDiferentColumn(
    oldState[sInd],
    oldState[dInd],
    source,
    destination
  );
  const newState = [...oldState];
  newState[sInd] = result[sInd];
  newState[dInd] = result[dInd];

  // return newState.filter((group) => group.length);
  return newState
};

const moveTileSameColumn = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const moveTileDiferentColumn = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getIndexWithMinLengthColumn = (state) => {
  const lengthColSate = state.map((item) => item.length);
  const smallerLengthColumn = Math.min(...lengthColSate);
  const indexSmallerLength = lengthColSate.findIndex(
    (item) => item === smallerLengthColumn
  );
  const colDestIndex = indexSmallerLength === 0 ? 1 : indexSmallerLength;
  return colDestIndex;
};

const createFirstVisibleCollumnIfNotExists = (state) => {
  const intialCol = [[], [], []];
  if (state.length <= intialCol.length) {
    return [...state, ...intialCol];
  }
  return [...state];
};

const getNewPositionsWhenCrossButonClick = (state, index, ind) => {
  let stateClone = [...state];
  stateClone = createFirstVisibleCollumnIfNotExists(stateClone);

  let colDestIndex;
  const hidedColumnIndex = 0;
  if (ind === hidedColumnIndex) {
    colDestIndex = getIndexWithMinLengthColumn(stateClone);
  } else {
    colDestIndex = 0;
  }

  const source = { index: index, droppableId: ind };
  const destination = {
    index: stateClone[colDestIndex].length,
    droppableId: colDestIndex,
  };

  const result = moveTileDiferentColumn(
    stateClone[ind],
    stateClone[colDestIndex],
    source,
    destination
  );

  stateClone[ind] = result[ind];
  stateClone[colDestIndex] = result[colDestIndex];
  const cloneState = stateClone.filter((group) => group.length);
  return cloneState;
};

export {
  moveTileSameColumn,
  moveTileDiferentColumn,
  getNewPositionsWhenTileMoved,
  saveTilesPositions,
  getTilesPositions,
  getNewPositionsWhenCrossButonClick,
  getNewPositionFromHiddenCards,
};
