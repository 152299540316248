import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "80%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	notificationStartText: {
		color: grey[600]
	}
}));
  
export const NotificationsSettings = ({ selectedNotifications,changeNotificationSelection,emailUser,setNewEmail,
	changeNotificationFlag, invalidEmail, newStateInvalidEmail
 }) => {
  const classes = useStyles();
  const [statesSwitches, setStatesSwitches] = useState({
	email: false,
  });
  const [emailForNotification, setEmailForNotification] = useState("")
//   const [error, setError] = useState(false);
  const [helperTextForEmail, setHelperTextForEmail] = useState('')

  const handleChangeSwitch = (event) => {
	setStatesSwitches({...statesSwitches, [event.target.name]: event.target.checked});
	changeNotificationFlag(true);
	const newArrayOfNotifications = [...selectedNotifications];
	if(event.target.checked){
		//Agregar
		if(event.target.name === "email") {
			newArrayOfNotifications.push("email")
		}
		changeNotificationSelection([...newArrayOfNotifications]);
	} else{
		//Eliminar
		const elementIndex = newArrayOfNotifications.indexOf(String(event.target.name));
		newArrayOfNotifications.splice(elementIndex,1);
		changeNotificationSelection([...newArrayOfNotifications]);
	}
  };

  const handleChangeEmail = (event) => {
	const { value } = event.target;
	setEmailForNotification(value);
	
	// Simple regex for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (emailRegex.test(value)) {
		newStateInvalidEmail(false);
		setHelperTextForEmail('');
		//Guardar el nuevo Email
		setNewEmail(value);
		changeNotificationFlag(true);
	  } else {
		newStateInvalidEmail(true);
		setHelperTextForEmail('Por favor ingrese un correo valido');
	  }
  }

  useEffect(() => {
	if(selectedNotifications.length > 0){
		const newStates = {email: false,}
		for (let index = 0; index < selectedNotifications.length; index++) {
			if(selectedNotifications[index] === "email") {
				newStates.email = true
			}
		}
		setStatesSwitches({...newStates})
	}
	if(emailUser !== "") {
		setEmailForNotification(emailUser)
	}
	
  }, [selectedNotifications,emailUser])

  useEffect(() => {
	if(!statesSwitches.email) {
		newStateInvalidEmail(false);
		setHelperTextForEmail('')
	}
  }, [statesSwitches])
  
  return (
	<div>
		<Typography variant="body1" className={classes.notificationStartText}>
			* En esta sección, puedes habilitar los diferentes tipos de notificaciones que deseas recibir.
			Las notificaciones se activarán según las configuraciones avanzadas que hayas establecido 
			para cada uno de tus dispositivos.
		</Typography>
		<div>
			<label className={"mt-3"}>Por correo electronico</label>
			<Switch
			checked={statesSwitches.email}
			onChange={handleChangeSwitch}
			color="primary"
			name="email"
			/>
		</div>
		<Grid container justifyContent='flex-start'>
			<label className={"mt-3"}>Email:</label>
			<TextField 
			id="email-address" 
			variant="outlined" 
			size="small"
			margin="dense"
			label="Correo"
			type="email"
			value={emailForNotification}
			onChange={handleChangeEmail}
			error={invalidEmail}
			helperText={helperTextForEmail}
			className={classes.textField}
			disabled={!statesSwitches.email}
			/>
		</Grid>
  	</div>
  )
}
