import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core'
import japyIcon from '../../../assets/openFresa.png';
import { CancelOutlined, ImageSearchOutlined, PhotoCameraOutlined, SendRounded } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { UserContext } from '../../../context/UserProvider';


const useStyles = makeStyles((theme) => ({
  formField: {
    margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    // Ajusta el tamaño del input y otros estilos aquí
    width: '100%', // Hace que el input ocupe todo el ancho del contenedor
    padding: theme.spacing(1), // Usa el sistema de espaciado de Material-UI para el padding
    [theme.breakpoints.down('sm')]: {
      // Ajustes para pantallas pequeñas (dispositivos móviles)
      padding: theme.spacing(0.5),
    },
    display: 'none',
  },
  buttonProgress: {
    color: green[500],
     marginLeft: 12,
  },
  input_IC: {
    display: 'none',
  },
}));


export const ConsultJapy = ({ username,cropType,weekStage,growingSite,synchronized,setSynchronized,actualCrop,loadingChat,setLoadingChat,cropCoordinates }) => {
  const classes = useStyles();
  // const [loadingChat, setLoadingChat] = useState(false);
  const {userTimezone} = useContext(UserContext)
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [secondImageUrl, setSecondImageUrl] = useState('');
  const [imageFlag, setImageFlag] = useState(false);
  const [imageName, setImageName] = useState('None');
  const [data, setData] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [isNewChat, setIsNewChat] = useState("yes");
  const [titleForChat, setTitleForChat] = useState("Nuevo Chat")
  const [ongoingCropChat, setOngoingCropChat] = useState(actualCrop)

  let message = '';
  let fechaYHora = '';

  const askJapyUrl = process.env.REACT_APP_ASK_JAPY;
  const resizeImageUrl = process.env.REACT_APP_RESIZE_IMAGE;

  //Subir Imagen
  const uploadImage = async () => {
    const formData = new FormData();

    // Agrega los campos al objeto formData
    formData.append('username', username);
    // formData.append('id', imageName);

    // Si tienes una variable `image` que representa la imagen a enviar:
    if (image) {
      formData.append('file', image); // Agregamos la imagen y su nombre
      formData.append('id', image.name); // Usamos el nombre de la imagen como 'id'
    } else {
      formData.append('id', 'none'); // Si no hay imagen, enviamos 'none' como 'id'
    }
    
  
    try {
      const response = await fetch(resizeImageUrl, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Error en la solicitud: ' + response.statusText);
      }
  
      const successfulResizing = await response.text();
      return successfulResizing;
      //alert(data); // Muestra el mensaje de éxito
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al subir la imagen.');
    }
  };

  //Enviar peticiones a Japy
  const handleButtonClick = async () => {
    
    setLoadingChat(true);
    if (isNewChat === "yes") {
      message = '';
      setData(message);
      // Obtener la fecha y hora actuales
      const ahora = new Date();
      // Formatear la fecha y hora como un string
      fechaYHora = ahora.toLocaleString(); // Ejemplo de salida: '1/4/2024, 10:00:00 AM'
    }
   
    const dataJson = isNewChat === 'yes' ? {
      crop: cropType,
      cultivation_stage: weekStage,
      cultivation_location: growingSite,
      username: username,
      newChat: isNewChat,
      //date: fechaYHora,
      imageUploaded: imageFlag,
      coordinates: cropCoordinates,
      timezone: userTimezone
    }
    :
    {
      newChat: isNewChat,
      username: username,
      message: newMessage,
      imageUploaded: imageFlag,

    }
  
    if(image !== null && imageFlag ){
      try{
        await uploadImage();
        setImageFlag(false);
        //imageOk = resizeImage;
      }catch (error) {
        console.error('Error:', error);
        setData('Error: Imagen no soportada')
      }
     
    }
      
    try {
      const dataToSend = JSON.stringify(dataJson);
      console.log("Esto es dataToSend:",dataToSend)
      const response = await fetch(askJapyUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      const responseData = await response.json(); // Espera a que se resuelva la promesa
      //console.log(responseData.respuesta);
      const responseMessage = isNewChat === 'yes' ? `¡Hola! Soy Japy, tu experto cultivador virtual y este es el resultado de mi análisis:\n
      ${responseData.respuesta}\n\n ¿Te puedo ayudar en algo más Gappier?...`
      :
      `\n\n¡Hola! Japy te ayuda:\n
      ${responseData.respuesta}\n\n ¡Espero haberte ayudado Gappier!`;
      message = data + responseMessage;
      //console.log("Esto es message:", message);
      setData(message); 
      isNewChat === "yes" && setTitleForChat(responseData.titulo);
      setNewMessage('');
      setSecondImageUrl('');
      //setData(dataToSend);
      setSynchronized(false);
      setIsNewChat('no');
      setImageName('None');
      setImageFlag(false);
    } catch (error) {
      console.error('Error:', error);
      
    }

    setLoadingChat(false);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (event.target.id === "firstImage") {
        setImage(img); // Almacenar el archivo de imagen en el estado
        setImageUrl(URL.createObjectURL(img)); // Crear y almacenar la URL de objeto para mostrar la imagen
        setImageName(img.name);
        setImageFlag(true);
      } else {
        setImage(event.target.files[0]);
        const reader = new FileReader();
        reader.onload = (e) => setSecondImageUrl(e.target.result);
        reader.readAsDataURL(event.target.files[0]);
        setImageName(img.name);
        setImageFlag(true);
      }
      
    } else {
      setImageName('None');
      setImage(null);
    }
  };

  const handleRemoveImage = (kindImage) => {
    setImage(null);
    kindImage === "firstImage" ? setImageUrl('') : setSecondImageUrl('');
    setImageName('None');
    setImageFlag(false);
  };

  const handleNewMessage = (e) =>{
    const {value} = e.target;
    setNewMessage(value);
  }

  useEffect(() => {
    if(actualCrop !== ongoingCropChat) {
      setData('');
      setIsNewChat("yes");
      setTitleForChat("Nuevo Chat");
      setImageName('None');
      setImageFlag(false);
      setNewMessage('');
      setSecondImageUrl('');
      setImage(null);
      setImageUrl('');
      setOngoingCropChat(actualCrop)
    }
    
  }, [actualCrop])
  

  return (

    <>
    <div style={{ marginTop: '15px' }}>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Typography variant="h6" className={classes.title}>
          Consulta a Japy
        </Typography>
      </Grid>

      <Grid container justifyContent='center' alignItems='center'>
        <img style={{width: '25%'}} src={japyIcon} alt="Japy Icon" />
      </Grid>

      <div style={{ marginTop: '10px' }}>
      <Grid container justifyContent='flex-start' alignItems='flex-start'>
        <Typography >
            {"Imagen (opcional):"}
        </Typography>
      </Grid>        
      </div>

      <div style={{ marginTop: '0px' }}>
        <Grid container justifyContent='center' alignItems='center'>
        <input
          accept="image/*"
          className={classes.input}
          id="firstImage"
          //multiple
          type="file"
          onChange={handleImageChange}
          disabled={data !== ''}
        />
        <label htmlFor="firstImage">
          <Button variant="contained" color="primary" component="span" disabled={data !== ''} startIcon={<PhotoCameraOutlined />}>
            Subir Imagen
          </Button>
        </label>
        <Box mt={1}> 
        
        {imageUrl && (
          <Box m={1} style={{ position: 'relative', display: 'inline-block' }}>
            <img src={imageUrl} alt="Primer Imagen seleccionada" height="100" />
            <IconButton 
              onClick={() => handleRemoveImage("firstImage")} 
              color="secondary"
              style={{
                position: 'absolute',
                right: -14,
                top: -15,
                backgroundColor: 'transparent', // Adding background color for visibility
                margin: 0,
                padding: '0px', // Reducing padding to make it less intrusive
              }}
            >
                {data === '' && <CancelOutlined />}
            </IconButton>
          </Box>
        )}
        
        </Box>
        </Grid>
      </div>

      <div style={{ marginTop: '5px' }}>
      <Grid container justifyContent='flex-start' alignItems='flex-start'>
        <Typography variant="h6">
            Titulo: {titleForChat}
        </Typography>
      </Grid>        
      </div>
    </div>
    
    <div>
      <Grid container >
        <TextField
          margin="dense"
          id="descripcion"
          label={loadingChat ? "Analizando datos..." : ""}
          value={data}
          multiline
          minRows={4} // Puedes cambiar el número de líneas que deseas mostrar inicialmente
          //maxRows={100}
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          disabled={data === ''}
        />            
      </Grid>
      
    </div>

      {isNewChat === 'no' && 
        <div style={{ marginTop: '15px' }}>
          <Grid container >
            <TextField
            className={classes.margin}
            id="input-chat"
            //label="Realiza otra consulta a Japy"
            placeholder="Realiza otra consulta a Japy..."
            value={newMessage}
            onChange={handleNewMessage}
            variant="outlined"
            fullWidth
            multiline
            minRows={secondImageUrl ? 3 : 1}
            disabled={loadingChat}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                  aria-label="send-message"
                  color='primary'
                  onClick={handleButtonClick}    
                  disabled={newMessage === ''}
                  
                  >  
                      {loadingChat ? <CircularProgress size={30} className={classes.buttonProgress} /> 
                      : <SendRounded /> }
                  </IconButton> 
                  
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <input 
                  accept="image/*" 
                  className={classes.input_IC} 
                  id="icon-button-file" 
                  type="file" 
                  onChange={handleImageChange} 
                  disabled={loadingChat}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload new picture" component="span" disabled={loadingChat} >
                      <ImageSearchOutlined />
                    </IconButton>
                  </label>
                  {secondImageUrl && (
                    <Box style={{ position: 'relative', display: 'inline-flex', maxWidth: '100%' }}>
                      <img src={secondImageUrl} alt="Selected" style={{ maxHeight: '70px', width: 'auto', maxWidth: '100%'}}/>
                      <IconButton 
                      onClick={() => handleRemoveImage("SecondImage")} 
                      color="secondary"
                      disabled={loadingChat}
                      style={{
                        position: 'absolute',
                        right: -14,
                        top: -10,
                        backgroundColor: 'transparent', // Adding background color for visibility
                        margin: 0,
                        marginRight: 8,
                        padding: '0px', // Reducing padding to make it less intrusive
                      }}
                      >
                        <CancelOutlined />
                      </IconButton>
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
            />           
          </Grid>
          
        </div>
      }

      {isNewChat === "yes" && 
      <div style={{ marginTop: '15px' }}>
        <Grid container justifyContent='center' alignItems='center'>
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={handleButtonClick}
            disabled={ cropType === '' || weekStage === '' || growingSite === '' || loadingChat || !synchronized}
          >
            Enviar análisis
          </Button>
          {loadingChat && <CircularProgress size={30} className={classes.buttonProgress} />}
        </Grid>
      </div>
      }
    </>
  )
}
