import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DialogConfirm = (props) => {
    return (
        <div>
                <Dialog
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                    keepMounted
                >
                    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.text}

                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={props.handleClose} 
                        color={props.color?props.color:"primary"}
                        disabled={props.disabled?props.disabled:false}
                        >
                        Descartar
                    </Button>
                    <Button 
                        onClick={props.agreeDialog} 
                        color={props.color?props.color:"primary"} 
                        autoFocus
                        disabled={props.disabled?props.disabled:false}
                        >
                        Aceptar
                    </Button>
                    </DialogActions>
                </Dialog>
        </div>
    )
}

export default DialogConfirm
