import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { AvatarApp } from "../../../Common";
import ImageAvatarDialog from "./ImageAvatarDialog";
import { UserContext } from "../../../../context/UserProvider";

const imgSize = 250;
const imgSizeXs = imgSize / 2.5;

const size = {
  height: imgSize,
  width: imgSize,
};

const sizeXs = {
  height: imgSizeXs,
  width: imgSizeXs,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  image: {
    position: "relative",
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.4,
      },
      "& $cameraIcon": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageBackdrop: {
    position: "absolute",

    backgroundColor: theme.palette.common.black,
    opacity: 0, //
    transition: theme.transitions.create("opacity"),
    borderRadius: "50%",

    ...size,
    [theme.breakpoints.down("xs")]: { ...sizeXs },
  },
  cameraIcon: {
    position: "relative",
    fontSize: imgSize / 6.25,
    [theme.breakpoints.down("xs")]: { fontSize: imgSizeXs / 6.25 },
  },
}));

export default function ButtonBases() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {usuario}=React.useContext(UserContext)
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        key="Avatar"
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{ width: "100%" }}
        onClick={handleClickOpen}
      >
        <AvatarApp large responsive title={usuario.username} src={usuario.photoURL} label={usuario.username.charAt(0).toUpperCase()}/>
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <CameraAltIcon className={classes.cameraIcon} />
        </span>
      </ButtonBase>

      

      <ImageAvatarDialog open={open} setOpen={setOpen} />
    </div>
  );
}
