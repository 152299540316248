import React from "react";
import { LinearProgress, Grid } from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab';

export const Steps_1288 = ({
  header_text,
  subActiveStep,
  info_alerts,
  percentage,
}) => {
  return (
    <Grid container spacing={2}>
      <LinearProgress
        color="success"
        variant="determinate"
        value={percentage}
      />
      <p>{header_text[subActiveStep]}</p>
      {subActiveStep === 0 && (
        <Alert severity="info">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[0].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[0].points}
        </Alert>
      )}

      {subActiveStep === 0 && (
        <Alert severity="warning">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[1].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[1].points}
        </Alert>
      )}

      {subActiveStep === 0 && (
        <Alert severity="success">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[3].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[3].point_1} <strong>{info_alerts[3].point_2}</strong>{" "}
          {info_alerts[3].point_3}
        </Alert>
      )}

      {subActiveStep === 0 && (
        <Alert severity="error">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[2].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[2].points}
        </Alert>
      )}

      {subActiveStep === 1 && (
        <Alert severity="info">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[4].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[4].points}
        </Alert>
      )}

      {subActiveStep === 1 && (
        <Alert severity="warning">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[5].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[5].points}
        </Alert>
      )}

      {subActiveStep === 2 && (
        <Alert severity="success">
          <AlertTitle>
            {" "}
            <strong>{info_alerts[6].main}</strong>{" "}
          </AlertTitle>
          {info_alerts[6].points}
        </Alert>
      )}
    </Grid>
  );
};
