/**
 * Para la calibracion de bombas PH y sensores
 */

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CalibrationStepper from "./CalibrationStepper";

export default function CalibrationDialogForm(props) {

  const [confirmation, setConfirmation] = useState(false);

  const textConfirmation = "¿Seguro que quieres cancelar la calibración?, si cancelas ahora tendrás que repetir todo el proceso de calibración desde el inicio.";

  const onClose = () => {
    setConfirmation(false);
  }

  const onConfirm = () => {
    setConfirmation(false);
    props.handleClose();
  }

  const dialogConfirm = () => {
    setConfirmation(true);
    // console.log("Entre a dialogConfirm")
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{confirmation ? "¿Seguro quieres Cancelar?" : props.dialogTittle}</DialogTitle>

        <DialogContent>
          <DialogContentText>{confirmation ? textConfirmation : props.dialogText}</DialogContentText>

          
          <CalibrationStepper
            infoStepper={props.infoStepper}
            handleClose={props.handleClose}
            sendingOperationCalibration={props.sendingOperationCalibration}
            canid={props.canid}
            outid={props.outid}
            typeCalibration={
              props.typeCalibration ? props.typeCalibration : "CalBomba"
            }
            selecOptions={props.selecOptions}
            onPause={confirmation}
          />
          
        </DialogContent>

        <DialogActions>
          {!confirmation && (
          <Button
            onClick={dialogConfirm}
            color="secondary"
            variant="contained"
          >
            Cancelar
          </Button>
          )}
          {confirmation && (
            <>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            Si
          </Button>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
          >
            No
          </Button>
          </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
