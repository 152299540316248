import React from "react";
import { CropContext } from "../../../../context/CropProvider";
import { UserContext } from "../../../../context/UserProvider";
import IconButtonEditPhoto from "../../../Common/IconButtonEditPhoto/IconButtonEditPhoto";

const IconButtonCamera = ({ classNameIcon, nameCrop, photoURL }) => {
  const { usuario } = React.useContext(UserContext);
  const { cropData, setCropData } = React.useContext(CropContext);
  const addr = `${usuario.username}/crop/${nameCrop}`;
  const namePhoto = nameCrop;
  const localTimelapseAddr = "crop";
  const docFirestore = "information";
  
  return (
    <IconButtonEditPhoto
      classNameIcon={classNameIcon}
      namePhoto={namePhoto}
      photoURL={photoURL}
      photosData={cropData}
      setPhotosData={setCropData}
      addr={addr}
      localPhotoAddr={localTimelapseAddr}
      docFirestore={docFirestore}
      dialogTitle="Foto de cultivo"
      dialogContentText="Una foto te permitirá saber que cultivo es"
      deleteLabel="Borrar"
      editLabel="Cambiar"
    />
  );
};

export default IconButtonCamera;
