import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import {Avatar,Tooltip} from '@material-ui/core/';



const imgSize = 250;
const imgSizeXs=imgSize/2.5

const sizeXs={
  height:imgSizeXs,
  width:imgSizeXs
}

const size = {
  height: imgSize,
  width: imgSize,
};

const useStyles = makeStyles((theme) => ({
  avatar:{
    backgroundColor: theme.palette.primary.main,
  },
  large:{
    fontSize: imgSize / 1.25,
    ...size,
  },
  responsive: {
    [theme.breakpoints.down("xs")]: {...sizeXs,fontSize:imgSizeXs/1.25},
  },
}));


const AvatarApp = ({className,large,responsive,title,src,label}) => {
const classes = useStyles();

const classNameSize=large?classes.large:null
const classNameResponsive=responsive?classes.responsive:null

  return (
    <Tooltip title={title} arrow>
      <Avatar 
      alt="photURL" 
      src={src} 
      className=
      {`${classes.avatar} ${className} ${classNameSize} ${classNameResponsive}`}
      >
        {label}
      </Avatar>
    </Tooltip>
  );
};

export {AvatarApp};
