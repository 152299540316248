import React from 'react'
import {withRouter} from 'react-router-dom'
import {db} from '../../../config/firebase'
import { customAlphabet } from 'nanoid'
import {UserContext} from '../../../context/UserProvider'

const Token = (props) => {
    //const [user,setUser]=React.useState(null)
    //const [username,setUsername]=React.useState(null)
    const [error,setError]=React.useState(null);

    const [token,setToken]=React.useState(null);
    const {usuario}=React.useContext(UserContext)



    const generate=async(e)=>{
        e.preventDefault()

        try {
            /////////////// BORRA Datos mas antiguos de 10 minutos
            const cutoff = Date.now() - 15 * 60 * 1000;
            const snapshot= await db.collection('NoUser').orderBy('time').endAt(cutoff).get();
            const docs=snapshot.docs.map(doc=>{
                return doc.id
              })
            await Promise.all(
                docs.map(async(item)=>{
                    await db.collection('NoUser').doc(item).delete()
                })
            )
            ///////////////////////////
            const add="NoUser";
            const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-', 5)
            //const nanoid = customAlphabet('01', 2)
            const uid=nanoid();
            
            const exist=await db.collection(add).doc(uid).get();
                if(exist.exists){
                    setError("Genere otro Token");
                    setToken(null);
                }
                else{
                    await db.collection(add).doc(uid).set({
                        user:usuario.username,
                        time: Date.now()
                    })
                    setError(null)
                    setToken(uid)
                }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        
            <div className="container mt-3">
               <div className="row ">
                  <div className="col-md-6">
                      <ul className="list-group">
                          <li className="list-group-item">
                              <h5>Pasos</h5>
                          </li>
                          <li className="list-group-item">
                              <ul>
                                  <li>Presione Generar Token</li>
                                  <li>Ingrese Token Generado en Pagina Local GAPY no más de 10 min</li>
                                  <li>Espere a que Led se ponga en Verde</li>
                              </ul>
                          </li>

                      </ul>
                   </div>
                  <div className="col-md-6">
                    <ul className="list-group">
                       <li className="list-group-item"> <h5>Token</h5> </li> 
                       <li className="list-group-item">
                           <form onSubmit={generate}>
                                <button 
                                className="btn btn-warning btn-block"
                                type="submit"
                                >Generar Token</button>
                           </form>
                            {//como operador ternario
                            token && (
                                <div className="col-md-12 mt-3">
                                <div className="alert alert-success alert-block">
                                    {token}
                                </div>

                                </div>
                                
                            )
                            }

                             {//como operador ternario
                            error && (
                                <div className="col-md-12 mt-3">
                                <div className="alert alert-danger alert-block">
                                    {error}
                                </div>

                                </div>
                                
                            )
                            }

                       </li>
                      
                    </ul>
                  </div>
               </div>
            </div>
              
       
    )
}

export default withRouter(Token)
export{Token}
