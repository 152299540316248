import React from "react";

import { IconButton } from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info';
import BarTileIconIndoDayDialog from "./BarTileIconIndoDayDialog";

const IconButtonDelete = ({classNameIcon}) => {

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const onClickDeleteIcon = () => {
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <IconButton
        aria-label="DeleteIcon"
        size="medium"
        onClick={onClickDeleteIcon}
      >
        <InfoIcon className={classNameIcon} fontSize="large" />
      </IconButton>

      <BarTileIconIndoDayDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

export default IconButtonDelete;
