import React from "react";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { auth } from "../../../../config/firebase";
import { ALL_PATHS } from "../../../../constants/routerConst";
import FooterForm from "./FooterForm";


const useStyles = makeStyles((theme) => ({
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
  }));

const Reset = ({setKindFormUser,...props}) => {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError("Ingrese email");
      return;
    }
    setError(null);
    recuperar();
  };

  const recuperar = React.useCallback(async () => {
    try {
      await auth.sendPasswordResetEmail(email);
      console.log("correro enviado");
      props.history.push(ALL_PATHS.PATH_LOGIN);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  }, [email, props.history]);

  return (
    <>
      <h3 >Reiniciar Contraseña</h3>
      <hr />
      
        <form noValidate onSubmit={procesarDatos}>
          {error && <div className="alert alert-danger">{error}</div>}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          color="primary"
          className={classes.submit}
          >
            Enviar
          </Button>
        </form>
      
      <FooterForm
      setKindFormUser={setKindFormUser}
      Link1={{text:"¿No tienes cuenta?",to:"LogInForm"}}
      Link2={{text:"¿Ya tienes cuenta?",to:"SignInForm"}}
      />
    </>
  );
};

export default withRouter(Reset);
export { Reset };
