import React from 'react'

const DosSelect = (props) => {
    const id="DosSelect"+props.id;
    //console.log("2Se",props.value)
    return (
        <div className="row">
            <div className="form-group col-6">
                <label htmlFor={id}> {props.label}</label>
            </div>
            <div className="col-6" >
                <select className="form-control" name={props.id} id={id} value={props.value} onChange={(e)=>props.handleToggle(e.target)}>
                    <option value="0" >{props.label0}</option>
                    <option value="1" >{props.label1}</option>
                </select>
            </div>
        </div>
    )
}

export default React.memo(DosSelect)
