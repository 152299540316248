import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const TransitionAlerts = ({ data, onClick, enableHead}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(data.open);
  }, [data.open]);

  return (
    data.open && (
      <div className={classes.root}>
        <Collapse in={open}>
          <Alert
            severity={data.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  onClick();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {!enableHead && <AlertTitle>{data.tittle}</AlertTitle>}

            {data.txt}
          </Alert>
        </Collapse>
      </div>
    )
  );
};

export default TransitionAlerts;
export { TransitionAlerts };
