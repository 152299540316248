import React from 'react';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { Grid, Input } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
  root: {
    width: "100%",
  },
  margin: {
    height: theme.spacing(3),
  },
  input: {
    width: 42,
  },
}));

const PrettoSlider = withStyles({
    root: {
      color: 'primary',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);



export default function InputRange(props) {

  const classes = useStyles();
 
  return (
    <div className={classes.root}>
        
       
       <Grid container spacing={2} alignItems="center">
           
           <Grid item xs={12} md={6}>
                <div className={classes.margin} />
                <Typography id="range-slider" gutterBottom>
                    {props.label}
                </Typography>
           </Grid>


           <Grid item xs={1} md={1}>
               
            <Input
                className={classes.input}
                value={props.value[0]}
                margin="dense"
                onChange={e=>props.handleInputChangeRange(e,0)}
                
                inputProps={{
                step: 0.1,
                min: 0,
                max: 14,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
            />
            </Grid>
            
           <Grid item xs={10} md={4}>
           <div className={classes.margin} />
            <PrettoSlider
                    value={props.value}
                    onChange={props.handleChangeRange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    
                    step={0.1}
                    marks
                    min={0}
                    max={14}
                />
           </Grid>

           <Grid item xs={1} md={1}>
               
            <Input
                className={classes.input}
                value={props.value[1]}
                margin="dense"
                onChange={e=>props.handleInputChangeRange(e,1)}
                
                inputProps={{
                step: 0.1,
                min: 0,
                max: 14,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
            />
            </Grid>


       </Grid>
      
      
    </div>
  );
}