import { db } from "../../../config/firebase";

const getAllDeviceDataFromConfigModule = async (mac, canId, username) => {
  const addr = username + "/infoDevices/" + mac + "/" + canId + "/configModule";
  const data = await db.collection(addr).get();
  const dataConfig = data.docs.map((doc) => ({
    id: doc.id.trim(),
    ...doc.data(),
  }));
  return dataConfig;
};

const getAllNodesData = async (username) => {
  try {
    const addr = username + "/loraDevices/nodes";
    const data = await db.collection(addr).get();
    
    // Verificar si la colección está vacía
    if (data.empty) {
      return "No Lora Devices";
    }
    
    // Si la colección no está vacía, extraer los nombres de los documentos
    //const dataConfig = data.docs.map((doc) => doc.id); // Asumiendo que quieres los IDs como nombres
    const dataConfig = data.docs.map((doc) => doc.data());
    // console.log("Esto es data-loraDevices:",dataConfig);
    return dataConfig;
  } catch (error) {
    // Manejo de errores, por ejemplo, si la ruta de la colección no existe
    console.error("Error accediendo a la colección:", error);
    return "No Lora Devices";
  }
};


const getAllDeviceDataFromToModule = async (mac, canId, username) => {
  const addr = username + "/infoDevices/" + mac + "/" + canId + "/toModule";
  const data = await db.collection(addr).get();
  const toModuleData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  return toModuleData;
};

const getAllDeviceDataFromModule = async (mac, canId, username) => {
  const addr = username + "/infoDevices/" + mac + "/" + canId + "/fromModule";
  const data = await db.collection(addr).doc("render").get();
  const fromModule = await data.data();

  return fromModule;
};

const getTilesPositions = async (username) => {
  const renderMain = await db.collection(username).doc("renderMain").get();
  const renderMainData = renderMain.data();

  let tilesPositions = {};
  if (renderMainData !== undefined) {
    if (renderMainData.render !== undefined) {
      tilesPositions = renderMainData.render;
    }
  }
  // console.log("Esto es tilesPosition:",tilesPositions);
  return tilesPositions;
};

export {
  getTilesPositions,
  getAllDeviceDataFromConfigModule,
  getAllDeviceDataFromModule,
  getAllDeviceDataFromToModule,
  getAllNodesData,
};
