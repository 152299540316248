import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";

function CrossButton({ onClick, hidden }) {
  return (
    <IconButton
      aria-label="delete"
      color="secondary"
      size="medium"
      onClick={onClick}
      hidden={hidden}
      style={{bottom: 20}}
    >
      <CancelIcon fontSize="inherit" />
    </IconButton>
  );
}

export default CrossButton;
