import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { IrrigationContext } from '../../../../context/IrrigationContext/IrrigationContext';
import { UserContext } from '../../../../context/UserProvider';
import { db } from '../../../../config/firebase';



export const SelectWaterOut = ({ canId, }) => {
  const { names }=useContext(UserContext);
  const { waterPumps, waterSource, deviceType, setDeviceType, 
    setWaterSource, setWaterPumps, outletForPump, setOutletForPump, outState, setOutState } = useContext(IrrigationContext)
  const [remainingToggles, setRemainingToggles] = useState([...names]);
  const [waterSelected, setWaterSelected] = useState("");

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  }

  const handleWaterChange = (e) => {
    setWaterSelected(e.target.value)
  }

  useEffect(() => {
    try{
      if(waterPumps.length > 0  && deviceType === "") {
        setDeviceType(waterPumps[0])
      } else if(waterPumps.length === 0 ){
        setDeviceType("")

      }

      if(waterSource.length > 0 && waterSelected === "") {
        setWaterSelected(waterSource[0])
      } else if(waterSource.length === 0) {
        setWaterSelected("")
      }
    } catch(error) {
      console.error("Error al seleccionar el primer radiobutton:",error)
    }
    
  }, [waterPumps,waterSource]) 

  useEffect(() => {
    try{
      let outValues = [...outState]
      
      //setOutValues(newOutValues);
      if(waterPumps.length > 0) {
        for (let index = 0; index < waterPumps.length; index++) {
          if(waterPumps[index] !== deviceType) { 
            const otherIndex = names.indexOf(waterPumps[index]);
            if(otherIndex !== -1) { outValues[otherIndex] = false}
            
          } else if(waterPumps[index] === deviceType) {
            const deviceTypeIndex = names.indexOf(deviceType);
            if(deviceTypeIndex !== -1 ) {outValues[deviceTypeIndex] = true;}
            //console.log("Esto es OutValues:",outValues)
          }
          
        }
      }
      
      if(waterSource.length > 0) {
        for (let index = 0; index < waterSource.length; index++) {
          if(waterSource[index] !== waterSelected) {
            const otherIndex = names.indexOf(waterSource[index])
            if(otherIndex !== -1) {outValues[otherIndex] = false;}
          } else if(waterSource[index] === waterSelected) {
            const waterSelectedIndex = names.indexOf(waterSelected);
            if(waterSelectedIndex !== -1){outValues[waterSelectedIndex] = true;}
            //console.log("Esto es OutValues:",outValues)
          }        
        }
      }
      setOutState(outValues)

    } catch(error) {
      console.error("Error al actualizar el radioButton-State:",error)
    }
    
  }, [deviceType,waterSelected,setOutState])  

  return (
    <>
    <div style={{ marginTop: '15px'}}>
      <Grid
      container
      justifyContent='center'
      alignItems='center'
      >
          <FormLabel>Bomba seleccionada: </FormLabel>
          <RadioGroup row aria-label="position" name="position" 
          value={deviceType} 
          onChange={handleDeviceChange}
          >
            {waterPumps?.map((item,index) => (
              <FormControlLabel
              key={`item_${index}`}
              value={item}
              control={<Radio color="primary" />}
              label={item} 
              labelPlacement="top"
              />
            ))}
              
          </RadioGroup>
      </Grid>
    </div>

    <div style={{ marginTop: '15px'}}>
      <Grid 
      container
      justifyContent='center'
      alignItems='center'
      >

        <FormLabel>Fuente de agua seleccionada: </FormLabel>
        <RadioGroup row aria-label="position2" name="position2" value={waterSelected} onChange={handleWaterChange}>
          {waterSource?.map((item,index) => (
            <FormControlLabel
            key={`item_${index}`}
            value={item}
            control={<Radio color="primary" />}
            label={item} 
            labelPlacement="top"
            />
          ))}
            
        </RadioGroup>

      </Grid>

    </div>
    </>
  )
}
