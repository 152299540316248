import React, { useState, useEffect, Fragment } from "react";
//import axios from "axios";
//import MensajesAlerta from "./Components/MensajesAlerta";
//import ProgressBar from "./Components/ProgressBar";
//import XLSX from "xlsx";
//import download from "downloadjs";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import {
  HIGHCHART_OPT_SPANISH,
  ALL_KINDS,
} from "../../../constants/globalConst";

import {
  Grid,
  makeStyles,
  Paper,
  FormControl,
  Button,
  InputLabel,
  MenuItem,
  FormLabel,
  Select,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { UserContext } from "../../../context/UserProvider";
import { functions } from "../../../config/firebase";
const setDataValues2BQ = functions.httpsCallable("setDataValues2BQ");

const useStyles = makeStyles({
  progressBar: {
    width: "100%",
  },
  paperTitle: {
    alignContent: "center",
    textAlign: "center",
    padding: "5px",
  },
  mainPaper: {
    textAlign: "center",
    padding: "5px",
  },
});

function getRows(numrows) {
  let auxRows = [];
  let auxFecha = "";
  let auxHora = "";
  let auxValor = "";
  let auxDate = new Date();
  let nowUTC = 12345;
  let auxSeries = [];
  for (let i = 0; i < numrows; i++) {
    auxFecha = `${auxDate.getFullYear()}-${auxDate.getMonth()}-${auxDate.getDate()}`;
    auxHora = `${auxDate.getUTCHours()}:${
      auxDate.getUTCMinutes() + i
    }:${auxDate.getUTCSeconds()}`;
    auxValor = Math.random() * 1000;
    auxRows.push({
      fecha: auxFecha,
      hora: auxHora,
      datos: auxValor.toFixed(3),
    });
    nowUTC = Date.UTC(
      auxDate.getFullYear(),
      auxDate.getMonth(),
      auxDate.getDate(),
      auxDate.getUTCHours(),
      auxDate.getUTCMinutes() + i,
      auxDate.getUTCSeconds()
    );
    auxSeries.push([nowUTC, parseFloat(auxValor.toFixed(3))]);
  }

  return { rows: auxRows, series: auxSeries };
}

const NewDataByCSV = () => {
  const { usuario, dataMacCan } = React.useContext(UserContext);
  const classes = useStyles();
  //states for files
  const [progress, setProgress] = React.useState(0);
  const [file, setfile] = useState("");
  const [filename, setfilename] = useState("Elija El Archivo");
  const [param, setParam] = useState("");
  //const [uploadedFile, setuploadedFile] = useState({});
  const [open, setOpen] = React.useState(false);
  const [message, setmessage] = useState({ msg: "", rol: "" });
  const [series, setseries] = useState([]);
  //banderas
  const [activarBtn, setactivarBtn] = useState(false);
  const [existeSitio, setexisteSitio] = useState(false);
  const [archivoVal, setarchivoVal] = useState(false);
  const [optionsHighChart, setOptionsHighChart] = useState();

  Highcharts.setOptions(HIGHCHART_OPT_SPANISH);
  //functions
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  const rowsTable = getRows(Math.floor(Math.random() * 50));

  const onChangeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setfile(e.target.files[0]);
      setfilename(e.target.files[0].name);
      const exten = e.target.files[0].name.split(".");
      const extenL = exten.length;
      if (exten[extenL - 1] === "xlsx" || exten[extenL - 1] === "csv") {
        setarchivoVal(true);
        setProgress(0);
      } else {
        setarchivoVal(false);
        setmessage({
          msg: "El Archivo Debe Ser XLSX (Plantilla) o CSV con formato específico.",
          rol: "Archivo no válido",
        });
        setOpen(true);
      }
    }
  };
  /*
  const subirCSV = async (e) => {
    e.preventDefault();
    if (activarBtn && archivoVal) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("idSitio", sitio);

      try {
        const token = JSON.parse(
          localStorage.getItem("the_main_app_" + process.env.REACT_APP_PROYECTO)
        ).token;
        const res = await axios.post(
          process.env.REACT_APP_URL_MACHOTE + "/api/g/files/savefile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "an-access-token": "Bearer " + token,
              "project-token": process.env.REACT_APP_PROYECTO,
            },
            onUploadProgress: (ProgressEvent) => {
              setuploadPer(
                parseInt(
                  Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
                )
              );
              setTimeout(() => setuploadPer(0), 10000);
            },
          }
        );

        if (res.data.success) {
          setmessage({
            msg: `El Archivo ${res.data.fileName} Se Ha Cargado Correctamente`,
            rol: "success",
          });
          setactivarBtn(false);
          setarchivoVal(false);
          setfilename("Elija El Archivo");
          setfile("");
        }
      } catch (err) {
        if (err.response.status === 500) {
          setmessage({ msg: "Hubo Un Problema En El Backend.", rol: "error" });
        } else {
          //en caso de error porque el archivo estaba mal o se pudo procesar aquí que salga el error
          setmessage({ msg: "err.response.data.msg.", rol: "error" });
        }
      }
    } else {
      setmessage({
        msg: "Debe Elegir Un Sitio y Un Archivo CSV.",
        rol: "warning",
      });
    }
  };

  const descargarPlantilla = async () => {
    if (existeSitio) {
      const jsonEnvio = { idSitio: sitio };
      try {
        const token = JSON.parse(
          localStorage.getItem("the_main_app_" + process.env.REACT_APP_PROYECTO)
        ).token;
        const res = await axios.post(
          process.env.REACT_APP_URL_MACHOTE + "/api/g/files/downloadfile",
          jsonEnvio,
          {
            headers: {
              "an-access-token": "Bearer " + token,
              "project-token": process.env.REACT_APP_PROYECTO,
            },
          }
        );
        console.log(res.data);
        if (res.data.success) {
          let filexlsx = XLSX.read(res.data.buff, { type: "base64" });
          let wbout = XLSX.write(filexlsx, {
            bookType: "xlsx",
            type: "binary",
          });
          download(
            new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
            sitio + ".xlsx",
            {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }
          );
          setmessage({
            msg: `La Plantilla De  ${sitio} Se Ha Descargado Correctamente`,
            rol: "success",
          });
        }
      } catch (err) {
        //en caso de error porque el archivo estaba mal o se pudo procesar aquí que salga el error
        console.log(err);
        setmessage({ msg: "Error al descargar la plantilla", rol: "error" });
      }
    } else {
      setmessage({
        msg: "Debe Elegir Un Sitio y Un Archivo CSV.",
        rol: "warning",
      });
    }
  };
*/
  const handleChangeParam = (event) => {
    setParam(event.target.value);
    setseries(rowsTable.series);
  };

  useEffect(() => {
    if (filename !== "Elija El Archivo" && param !== "") {
      setactivarBtn(true);
    } else {
      setactivarBtn(false);
    }
    if (param !== "") {
      setexisteSitio(true);
    } else {
      setexisteSitio(false);
    }
  }, [filename, param]);

  useEffect(() => {
    if (archivoVal) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          /*if (oldProgress === 100) {
          return 0;
        }*/
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [archivoVal]);

  const setFullfillColor = (ini, fin) => {
    const js = {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, Highcharts.color("#5BB033").setOpacity(ini).get("rgba")],
        [1, Highcharts.color("#5BB033").setOpacity(fin).get("rgba")],
      ],
    };
    return js;
  };

  useEffect(() => {
    const newSerie = [
      {
        name: "S. Par",
        label: "S. Par",
        data: series,
        yAxis: 1,
        color: Highcharts.color("#5BB033").setOpacity(1).get("rgba"),
        type: "areaspline",
        fillColor: setFullfillColor(0.2, 0),
      },
    ];
    const opt = {
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      chart: {
        type: "area",
        backgroundColor: "transparent",
        borderRadius: 10,
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#5BB033"],
              [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: "",
        margin: 0,
      },
      xAxis: {
        type: "datetime",
        /*lineColor: "transparent",
        labels: {
          enabled: false,
        },*/
      },
      yAxis: [
        {
          gridLineColor: "transparent",
          title: {
            align: "high",
            offset: 0,
            text: "",
            rotation: 0,
            y: -10,
          } /*
          labels: {
            enabled: false,
          },*/,
        },
        {
          gridLineColor: "transparent",
          title: {
            align: "high",
            offset: 0,
            text: "",
            rotation: 0,
            y: -10,
          } /*
          labels: {
            enabled: false,
          },*/,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: newSerie,
    };
    console.log("Actualizo Gráfica");
    setOptionsHighChart(opt);
    console.log("opt", optionsHighChart);
    if (Math.floor(Math.random() * 10) > 5) {
      setmessage({
        msg: "El archivo contiene un intervalo de fechas donde ya existe registro de este sensor, por favor verifique que la información a registrar sea completamente nueva",
        rol: "Lecturas repetidas",
      });
      setOpen(true);
    }
  }, [series]);

  const handleClose = () => {
    setOpen(false);
  };
  async function testFuncion() {
    const respuesta = await setDataValues2BQ({
      user: usuario.username,
      mac12: dataMacCan[0].mac,
      can: "99",
      kind: "99",
      outId: "99",
      val: 99999,
    })
    console.log("[TestFuncion] Respuesta: ", respuesta)
  }

  return (
    <Fragment>
      <Paper elevation={0} className={classes.mainPaper}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paperTitle}>
              <h2>Subir Archivos CSV</h2>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <Button variant="contained" onClick={(e)=>{testFuncion()}} >Prueba</Button>
          </Grid>
          <Grid item xs={12}>
            <h4>
              Siga atentamente las instrucciones para subir los archivos CSV o
              XLSX.
            </h4>
          </Grid>
          <Grid item xs={12}>
            <p>1.- Elija el parámetro que desea cargar datos. 👇</p>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={param}
                onChange={handleChangeParam}
              >
                <MenuItem value={1}>Ph</MenuItem>
                <MenuItem value={2}>Humedad</MenuItem>
                <MenuItem value={3}>PAR (Liminosidad)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <p>
              Nota: Si ha elegido un parámetro para cargar los datos, recuerde
              que debe tener un formato específico, si aun no lo tiene, de click
              en el botón de Descargar Plantilla. 👇
            </p>
          </Grid>
          {param != "" && (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Subir Archivo con las lecturas del parámetro
                </FormLabel>
                <Button variant="contained" component="label">
                  Seleccione archivo
                  <input type="file" hidden onChange={onChangeFile} />
                </Button>
                <Button variant="contained">Descargue la plantilla</Button>
              </FormControl>
            </Grid>
          )}

          {archivoVal && (
            <Grid item xs={12}>
              <div className={classes.progressBar}>
                <LinearProgress variant="determinate" value={progress} />
              </div>
            </Grid>
          )}

          {progress == 100 && (
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Fecha (yyyy-mm-dd)</TableCell>
                      <TableCell align="center">Hora (hh:mm:ss)</TableCell>
                      <TableCell align="center">Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsTable.rows.map((row) => (
                      <TableRow key={row.fecha}>
                        <TableCell align="center">{row.fecha}</TableCell>
                        <TableCell align="center">{row.hora}</TableCell>
                        <TableCell align="center">{row.datos}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {progress == 100 && (
            <Grid item xs={6}>
              <HighchartsReact
                highcharts={Highcharts}
                options={optionsHighChart}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Atención: ${message.rol}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            `${message.msg}`
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default NewDataByCSV;
export { NewDataByCSV };
