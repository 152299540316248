import React from 'react'
import { FormControl, Input, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      maxWidth: 120,
    },
  }));

export const TimeOnOff = ({ Id, value, handleChangeSelect, arrayOfOptions, name }) => {
    const classes = useStyles();

  return (
    <FormControl key={`Form_${Id}_${name}`} className={classes.formControl}>
        <InputLabel id={`${name}_${Id}`} key={`${name}_${Id}`}>{name}</InputLabel>
        <Select
            key={`Select_${Id}_${name}`}
            id={`Select_${Id}_${name}`}
            value={value}
            onChange={handleChangeSelect}
            input={<Input id="demo" />}
        >
            {arrayOfOptions.map((valor,i) => (
            <MenuItem value={valor} key={`${i}_${Id}_${name}`}>{valor}</MenuItem>
            ))}
            
        </Select>
    </FormControl>
  )
}
