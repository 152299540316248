import React, { useRef, useState, useEffect } from 'react';
import { GoogleMap, Marker, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { TextField } from '@material-ui/core';

const libraries = ['places', 'marker']; // Añadir 'marker' para cargar AdvancedMarkerElement
const containerStyle = {
  width: '100%',
  height: '340px', // Tamaño del mapa dentro del modal
};

const center = {
  lat: 19.42847, // Coordenadas de Ciudad de Mexico
  lng: -99.12766,
};

// Aquí debes incluir el Map ID que hayas generado en Google Cloud Console
const MAP_ID = process.env.REACT_APP_MAPS_MAP_ID;

export const MapForCrop = ({ setCurrentCoordinates, initialCoordinates }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });
  // const center = {
  //   lat: initialCoordinates !== null ? initialCoordinates.lat : 19.42847, // Coordenadas de Ciudad de Mexico
  //   lng: initialCoordinates !== null ? initialCoordinates.lng : -99.12766,
  // };

  const [markerPosition, setMarkerPosition] = useState(initialCoordinates);
  const [map, setMap] = useState(null);
  const searchBoxRef = useRef(null);
  const markerRef = useRef(null); // Referencia para el marcador

  // Manejador de clic en el mapa
  const onMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });

    // Guardar las coordenadas en localStorage (o enviar al backend)
    localStorage.setItem('markerCoordinates', JSON.stringify({ lat, lng }));
    console.log(`Coordenadas guardadas: Latitud: ${lat}, Longitud: ${lng}`);
  };

  // Manejador para cargar el SearchBox
  const onLoadSearchBox = (searchBoxInstance) => {
    searchBoxRef.current = searchBoxInstance;
  };

  // Manejador para el lugar seleccionado desde SearchBox
  const onPlacesChanged = () => {
    if (searchBoxRef.current !== null) {
      const places = searchBoxRef.current.getPlaces();
      if (places.length === 0) {
        console.error('No se encontró la ubicación especificada.');
        return;
      }

      const place = places[0];
      if (place.geometry) {
        const location = place.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        setMarkerPosition({ lat, lng });
        map.panTo({ lat, lng });

        // Guardar las coordenadas en localStorage (o enviar al backend)
        localStorage.setItem('markerCoordinates', JSON.stringify({ lat, lng }));
        console.log(`Coordenadas guardadas: Latitud: ${lat}, Longitud: ${lng}`);
      } else {
        console.error('No se encontró la geometría de la ubicación especificada.');
      }
    }
  };

  // Efecto para gestionar la adición de `AdvancedMarkerElement` al mapa
  useEffect(() => {
    if (map && markerPosition && window.google) {
      // Verificar si ya existe un marcador, si es así eliminarlo antes de agregar uno nuevo
      if (markerRef.current) {
        markerRef.current.map = null;
      }

      // Crear un nuevo marcador utilizando `AdvancedMarkerElement`
      if (window.google.maps.marker && window.google.maps.marker.AdvancedMarkerElement) {
        const { AdvancedMarkerElement } = window.google.maps.marker;
        markerRef.current = new AdvancedMarkerElement({
          position: markerPosition,
          map: map, // Asignar el mapa donde se mostrará
          title: "Ubicación del cultivo", // Título opcional
        });
        setCurrentCoordinates(markerPosition);
      } else {
        console.error('AdvancedMarkerElement no está disponible.');
      }
    }
  }, [map, markerPosition]);

  // useEffect(() => {
  //   if (map && initialCoordinates && window.google) {
  //     // Crear un nuevo marcador utilizando `AdvancedMarkerElement`
  //     if (window.google.maps.marker && window.google.maps.marker.AdvancedMarkerElement) {
  //       const { AdvancedMarkerElement } = window.google.maps.marker;
  //       markerRef.current = new AdvancedMarkerElement({
  //         position: initialCoordinates,
  //         map: map, // Asignar el mapa donde se mostrará
  //         title: "Ubicación del cultivo", // Título opcional
  //       });
  //     }
  //   }
    
  // }, [initialCoordinates])
  



  if (loadError) return <div>Error cargando los mapas.</div>;
  if (!isLoaded) return <div>Cargando mapas...</div>;

  return (
    <div>
      <StandaloneSearchBox onLoad={onLoadSearchBox} onPlacesChanged={onPlacesChanged}>
        {/* <input
          type="text"
          placeholder="Introduce una dirección"
          style={{ width: '100%', padding: '10px', marginBottom: '10px', zIndex: 1500 }}
        /> */}
        <TextField 
        id="outlined-search" 
        type="search" 
        variant="outlined" 
        fullWidth 
        placeholder="Introduce una dirección" 
        //onChange={(event) => setCropDirection(event.target.value)}
        />
      </StandaloneSearchBox>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCoordinates !==null ? initialCoordinates : center}
        zoom={initialCoordinates !== null ? 18 : 10}
        onLoad={(mapInstance) => setMap(mapInstance)}
        onClick={onMapClick}
        options={{
          gestureHandling: 'greedy',
          streetViewControl: false,
          mapTypeControl: false,
          mapId: MAP_ID, // Usar el Map ID generado
          mapTypeId: 'hybrid', // Vista satelital con nombres de lugares superpuestos
        }}
      >
        {/* Marcador en las coordenadas iniciales */}
        <Marker position={markerPosition} />
      </GoogleMap>
    </div>
  );
};
