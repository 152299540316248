import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import UserProvider from "./context/UserProvider";
import { IrrigationProvider } from "./context/IrrigationContext";
import { MulticropProvider } from "./context/MultiCropContext";

ReactDOM.render(
  <UserProvider>
    <MulticropProvider>
      <IrrigationProvider>
        {console.log("V2.0.3")}
        <App />
      </IrrigationProvider>
    </MulticropProvider>
  </UserProvider>,
  document.getElementById("root")
);
