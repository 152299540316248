import React from 'react'
import { withRouter } from "react-router-dom";

import {IconButton} from "@material-ui/core";
import TimelapseIcon from "@material-ui/icons/Timelapse";

import { ALL_PATHS } from "../../../../constants/routerConst";

const IconButtonTimelapse = ({classNameIcon,nameCrop,...props}) => {

    const onClickTimelapseIcon=()=>{
        const {PATH_TIMELAPSE}=ALL_PATHS
        const addr=`${PATH_TIMELAPSE}/${nameCrop}`
        props.history.push(addr);
      }

    return (
        <IconButton aria-label="TimelapseIcon" size="medium" onClick={onClickTimelapseIcon}>
            <TimelapseIcon className={classNameIcon} fontSize="large" />
        </IconButton>
    )
}

export default withRouter(IconButtonTimelapse)
