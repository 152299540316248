import React from "react";
import { Slide, DialogTitle, Dialog } from "@material-ui/core";
import { MeasureContext } from "../../../../../context/MeasureProvider";
import FooterDialog from "./FooterDialog";
import BodyDialog from "./BodyDialog";
import { UserContext } from "../../../../../context/UserProvider";
import { db } from "../../../../../config/firebase";
import { defaultFormData } from "../../Constant";
import { TransitionAlerts } from "../../../../Common";
const defaultOpenAlert = { open: null, type: null, tittle: null, txt: null };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RADIO_VAL_EXIST = "0";
const RADIO_VAL_NEW = "1";
const RADIO_VAL_DELETE = "2";

const dataRadios = [
  {
    value: RADIO_VAL_EXIST,
    label: "Existente",
    color: "default",
  },
  {
    value: RADIO_VAL_NEW,
    label: "Nuevo",
    color: "primary",
  },
  {
    value: RADIO_VAL_DELETE,
    label: "Borrar",
    color: "secondary",
  },
];

export default function AlertDialogSlide() {
  const { usuario } = React.useContext(UserContext);
  const {
    sensorsName,
    setSensorsName,
    setSeriesModifier,
    openDialog,
    setOpenDialog,
    isDialogActivatedByRowConfig,
    formData,
    setFormData,
    allManualMeasurements,
    setAllManualMeasurements,
  } = React.useContext(MeasureContext);

  const [openAlert, setOpenAlert] = React.useState(defaultOpenAlert);
  const [selectedRadio, setSelectedRadio] = React.useState(dataRadios[0].value);

  const handleCheckedEraseAll = (event) => {
    setFormData({
      ...formData,
      checkedEraseAll: event.target.checked,
    });
  };

  const handleChangeRadio = (event) => {
    setSelectedRadio(event.target.value);
    setFormData({
      ...formData,
      value: "0",
      name: "",
      unit: "",
    });
  };

  const closeAlert = (e) => {
    setOpenAlert(defaultOpenAlert);
  };

  const handleDateChange = (date) => {
    if (!date) {
      setFormData({
        ...formData,
        date: NaN,
      });
      return;
    }
    setFormData({
      ...formData,
      date: date._d.getTime(),
    });
  };

  const handleFromDateChange = (date) => {
    if (!date) {
      setFormData({
        ...formData,
        fromDateDelete: NaN,
      });
      return;
    }
    setFormData({
      ...formData,
      fromDateDelete: date._d.getTime(),
    });
  };

  const isInputNameCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (!formData.name) {
      resOpenAlert = {
        open: true,
        type: "error",
        tittle: "Error",
        txt: "Campo 'Nombre' esta vacio",
      };
      return { isCorrect: false, resOpenAlert };
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isInputUnitCorrect = () => {
    let resOpenAlert = defaultOpenAlert;

    if (!formData.unit) {
      resOpenAlert = {
        open: true,
        type: "error",
        tittle: "Error",
        txt: "Campo 'Unidad' esta vacio",
      };

      return { isCorrect: false, resOpenAlert };
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isInputDateCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isNaN(formData.date)) {
      resOpenAlert = {
        open: true,
        type: "error",
        tittle: "Error",
        txt: "Campo 'Fecha' esta vacio",
      };
      return { isCorrect: false, resOpenAlert };
    }

    return { isCorrect: true, resOpenAlert };
  };

  const isInputFromDateCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isNaN(formData.fromDateDelete)) {
      resOpenAlert = {
        open: true,
        type: "error",
        tittle: "Error",
        txt: "Campo 'Fecha desde' esta vacio",
      };
      return { isCorrect: false, resOpenAlert };
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isFormExistCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isInputNameCorrect().isCorrect === false) {
      return isInputNameCorrect();
    }
    if (isInputDateCorrect().isCorrect === false) {
      return isInputDateCorrect();
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isFormNewCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isInputNameCorrect().isCorrect === false) {
      return isInputNameCorrect();
    }
    if (isInputDateCorrect().isCorrect === false) {
      return isInputDateCorrect();
    }

    if (isInputUnitCorrect().isCorrect === false) {
      return isInputUnitCorrect();
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isFormDeleteCorrect = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isInputNameCorrect().isCorrect === false) {
      return isInputNameCorrect();
    }
    if (isInputDateCorrect().isCorrect === false) {
      return isInputDateCorrect();
    }
    if (isInputFromDateCorrect().isCorrect === false) {
      return isInputFromDateCorrect();
    }

    return { isCorrect: true, resOpenAlert };
  };

  const isFormCorrectFromButtonConfig = () => {
    let resOpenAlert = defaultOpenAlert;

    switch (selectedRadio) {
      case RADIO_VAL_EXIST:
        return isFormExistCorrect();

      case RADIO_VAL_NEW:
        return isFormNewCorrect();

      case RADIO_VAL_DELETE:
        return isFormDeleteCorrect();

      default:
        return { isCorrect: true, resOpenAlert };
    }
  };

  const isFormCorrectFromRowConfig = () => {
    let resOpenAlert = defaultOpenAlert;
    if (isInputDateCorrect().isCorrect === false) {
      return isInputDateCorrect();
    }
    return { isCorrect: true, resOpenAlert };
  };

  const isFormCorrect = () => {
    if (isDialogActivatedByRowConfig) {
      return isFormCorrectFromRowConfig();
    }
    return isFormCorrectFromButtonConfig();
  };

  const newMeasurement = async () => {
    const addToSensorsName = () => {
      const clone = [
        ...sensorsName,
        {
          value: formData.name,
          label: formData.name,
          uid: formData.name,
        },
      ];
      setSensorsName(clone);
    };

    const newMeasurementToDB = async () => {
      try {
        const add = `${usuario.username}/manualMeasurements/${formData.name}`;
        return await db.collection(add).add(formData);
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    const isOnSensorsName = () => {
      const existsMeasure = sensorsName.find(
        (item) => formData.name === item.value
      );
      if (existsMeasure) {
        settingAlert("error", "Error", "Medida ya existe");
        return true;
      }
      return false;
    };

    const newMeasureInCollapaseTable = (cloneformData) => {
      const cloneAllManualMeasurements = [
        ...allManualMeasurements,
        {
          name: cloneformData.name,
          data: [cloneformData],
        },
      ];
      setAllManualMeasurements(cloneAllManualMeasurements);
    };

    try {
      if (isOnSensorsName()) {
        return;
      }
      const res = await newMeasurementToDB();
      let cloneformData = { ...formData, id: res.id };
      settingAlert("success", "Exito", "Se agrego el nuevo dato correctamente");
      addToSensorsName();
      setSeriesModifier({ action: "new", formData: cloneformData });
      newMeasureInCollapaseTable(cloneformData);
    } catch (error) {
      console.log(error);
    }
  };

  const addMeasurement = async () => {
    const addMeasurementToDB = async () => {
      try {
        const add = `${usuario.username}/manualMeasurements/${formData.name}`;
        return await db.collection(add).add(formData);
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    const addMeasureInCollapaseTable = (cloneFormData) => {
      const addJustWhenNamesMacthes = (MeasureName) => {
        const isNamesEquals = MeasureName.name === cloneFormData.name;
        return isNamesEquals
          ? [...MeasureName.data, cloneFormData]
          : MeasureName.data;
      };
      const arrayFiltrado = allManualMeasurements.map((MeasureName) => ({
        name: MeasureName.name,
        data: addJustWhenNamesMacthes(MeasureName),
      }));
      setAllManualMeasurements(arrayFiltrado);
    };

    try {
      const res = await addMeasurementToDB();
      const cloneFormData = { ...formData, id: res.id };
      settingAlert("success", "Exito", "Se agrego el dato correctamente");
      setSeriesModifier({ action: "add", formData: cloneFormData });
      addMeasureInCollapaseTable(cloneFormData);
    } catch (error) {
      console.log(error);
    }
  };

  const gettingDataByTime = () => {
    const timeInit = formData.fromDateDelete;
    const timeFin = formData.date;
    let arrayFiltrado = [];
    const conditionToGetDataNameChoose = (item) => item.name === formData.name;

    if (formData.checkedEraseAll) {
      arrayFiltrado = allManualMeasurements.find(
        conditionToGetDataNameChoose
      ).data;
    } else {
      const conditionToGetDataByRangeTime = (item) =>
        item.date >= timeInit && item.date <= timeFin;
      arrayFiltrado = allManualMeasurements.find(
        conditionToGetDataNameChoose
      ).data;
      arrayFiltrado = arrayFiltrado.filter(conditionToGetDataByRangeTime);
    }

    return arrayFiltrado;
  };

  const settingAlert = (type, tittle, txt) => {
    setOpenAlert({
      open: true,
      type,
      tittle,
      txt,
    });
  };

  const deleteMeasurementByRangeTime = async () => {
    const deleteFromSensorsName = () => {
      const clone = sensorsName.filter((item) => item.value !== formData.name);
      setSensorsName(clone);
      setFormData({
        ...formData,
        name: "",
      });
    };

    const deleteMeasurementByRangeTimeToDB = async () => {
      try {
        const addr = `${usuario.username}/manualMeasurements/${formData.name}`;
        const data = gettingDataByTime();
        await Promise.all(
          data.map(async (item) => {
            await db.collection(addr).doc(item.id).delete();
          })
        );
      } catch (error) {
        console.log(error);
      }
    };

    const deleteMeasureInCollapaseTable = () => {
      const timeInit = formData.fromDateDelete;
      const timeFin = formData.date;
      let arrayFiltrado = [];

      const droppingMeasureName = () => {
        const isNotTheDataWithChoosenName = (item) =>
          !(item.name === formData.name);
        const dataFiltered = allManualMeasurements.filter(
          isNotTheDataWithChoosenName
        );
        return dataFiltered;
      };

      const droppingMeasuresByRangeJustWhenNameMatches = (
        timeInit,
        timeFin
      ) => {
        const isElementOutsideRangeTime = (item) =>
          !(item.date >= timeInit && item.date <= timeFin);

        const filterJustWhenNamesMacthes = (MeasureName) => {
          const isNamesEquals = MeasureName.name === formData.name;
          return isNamesEquals
            ? MeasureName.data.filter(isElementOutsideRangeTime)
            : MeasureName.data;
        };

        const dataFiltered = allManualMeasurements.map((MeasureName) => ({
          name: MeasureName.name,
          data: filterJustWhenNamesMacthes(MeasureName),
        }));
        return dataFiltered;
      };

      if (formData.checkedEraseAll) {
        arrayFiltrado = droppingMeasureName();
      } else {
        arrayFiltrado = droppingMeasuresByRangeJustWhenNameMatches(
          timeInit,
          timeFin
        );
      }

      setAllManualMeasurements(arrayFiltrado);
    };

    try {
      await deleteMeasurementByRangeTimeToDB();
      if (formData.checkedEraseAll) {
        deleteFromSensorsName();
      }
      settingAlert("success", "Exito", "Se borraron los datos correctamente");
      setSeriesModifier({ action: "deleteByTime", formData: { ...formData } });

      deleteMeasureInCollapaseTable();
    } catch (error) {
      console.log(error);
    }
  };

  const actionFormFromButtonConfig = () => {
    switch (selectedRadio) {
      case RADIO_VAL_EXIST:
        addMeasurement();
        return;

      case RADIO_VAL_NEW:
        newMeasurement();
        return;

      case RADIO_VAL_DELETE:
        deleteMeasurementByRangeTime();
        return;

      default:
        return;
    }
  };

  const updateMeasurement = async () => {
    const editToDB = async () => {
      try {
        const add = `${usuario.username}/manualMeasurements/${formData.name}`;
        await db.collection(add).doc(formData.id).update(formData);
      } catch (error) {
        console.log(error);
      }
    };

    const editMeasureInCollapaseTable = () => {
      let lastData = { ...formData };
      const isElementToEdit = (item) => {
        const condition =
          item.name === formData.name && item.id === formData.id;
        if (condition) {
          lastData = item;
          return formData;
        }
        return item;
      };

      const arrayFiltrado = allManualMeasurements.map((MeasureName) => ({
        name: MeasureName.name,
        data: MeasureName.data.map(isElementToEdit),
      }));

      setAllManualMeasurements(arrayFiltrado);
      return lastData;
    };

    try {
      await editToDB();
      settingAlert("success", "Exito", "Se actualizo correctamente");
      const lastData = editMeasureInCollapaseTable();
      setSeriesModifier({
        action: "update",
        formData: { ...formData },
        lastData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const actionFormFromRowConfig = () => {
    updateMeasurement();
  };

  const actionFormToDB = () => {
    if (isDialogActivatedByRowConfig) {
      return actionFormFromRowConfig();
    }
    return actionFormFromButtonConfig();
  };

  const agreeComandDialogForm = () => {
    const { isCorrect, resOpenAlert } = isFormCorrect();
    if (isCorrect === false) {
      setOpenAlert(resOpenAlert);
      return;
    }
    actionFormToDB();
  };

  const handdleDataForm = async (e, jsField, isSelected) => {
    const clone = { ...formData };
    clone[jsField] = e.target.value;
    setFormData(clone);
  };

  const cancelMeasurement = () => {
    setOpenAlert(defaultOpenAlert);
    setOpenDialog(false);
    setFormData(defaultFormData);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancelMeasurement}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Medición de párametros manuales"}
        </DialogTitle>

        <BodyDialog
          handdleDataForm={handdleDataForm}
          handleDateChange={handleDateChange}
          dataRadios={dataRadios}
          handleChangeRadio={handleChangeRadio}
          selectedRadio={selectedRadio}
          handleFromDateChange={handleFromDateChange}
          fromDateDelete={formData.fromDateDelete}
          checkedEraseAll={formData.checkedEraseAll}
          handleCheckedEraseAll={handleCheckedEraseAll}
        />
        <TransitionAlerts data={openAlert} onClick={closeAlert} />

        <FooterDialog
          cancelMeasurement={cancelMeasurement}
          agreeComandDialogForm={agreeComandDialogForm}
        />
      </Dialog>
    </div>
  );
}
export { AlertDialogSlide };