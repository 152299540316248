import { TextField } from '@material-ui/core'
import React from 'react'

export const HourSelector = ({ Id, value, handleChangeHour, name }) => {
  return (
	<TextField
	key={`Hour_${name}_${Id}`}
	id={`Hour_${name}_${Id}`}
	label={name}
	type="time"
	//defaultValue="07:30" // Puedes cambiar esto para definir un valor por defecto
	value={value}
	onChange={handleChangeHour}
	InputLabelProps={{
	  shrink: true,
	}}
	inputProps={{
	  step: 300, // 5 min
	}}
	/>
  )
}
