import React from 'react'
import { FormControl, Grid, MenuItem, Select, } from '@material-ui/core';

const SelectMultiple = (props) => {
    const id = "Select" + props.id;

    return (
        <Grid 
            container 
            justifyContent="center" 
            style={{ 
                width: '100%', 
                maxWidth: `${props.maxWidth}`, // Tamaño máximo del componente
                padding: '6px' // Espaciado interno del componente
            }}
        >
            <FormControl 
                fullWidth 
                style={{ 
                    width: '100%' 
                }}
            >
                <Select
                    name={props.id}
                    id={id}
                    value={props.value}
                    label=""
                    onChange={(e) => props.handleToggle(e.target)}
                >
                    {
                        props.arrayOptions.map((item, i) =>
                            <MenuItem value={item.value} key={i}>{item.name}</MenuItem>
                        )
                    }


                </Select>
            </FormControl>
        </Grid>

        // <div>
        //     <FormControl>
        //         <Select
        //             name={props.id}
        //             id={id}
        //             value={props.value}
        //             label=""
        //             onChange={(e) => props.handleToggle(e.target)}
        //         >
        //             {
        //                 props.arrayOptions.map((item, i) =>
        //                     <MenuItem value={item.value} key={i}>{item.name}</MenuItem>
        //                 )
        //             }


        //         </Select>
        //     </FormControl>
        // </div>
    )
}

export default React.memo(SelectMultiple)
