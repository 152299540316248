import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MenuItem,
  TextField,
  FormGroup,
  Input,
  InputLabel,
  Select,
  Grid,
  useTheme,
} from "@material-ui/core";
import SelectColor from "../../../Common/SelectColor";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { UserContext } from "../../../../context/UserProvider";
import { db } from "../../../../config/firebase";
import { CropContext } from "../../../../context/CropProvider";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 400,
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const cropDefault = {
  name: "Mi primer cultivo",
  color: "#ff0000",
  zipCode: 57700,
  system: "DWC",
  size: "md 1x1x2 [m]",
  numberOfPlants: 4,
  initDate: moment(new Date()).format("YYYY-MM-DD"),
  modules: [],
  strainPlant: "Blue Haze",
  germinationWeeks: 1,
  seedlingWeeks: 3,
  vegetativeWeeks: 16,
  floweringWeeks: 8,
  note: "Es mi primer cultivo",
  photoURL:null
};

const SystemCrops = ["DWC", "Sustrato", "NFT", "Raiz flotante"];
const sizes = ["sm 1x1x1 [m]", "md 1x1x2 [m]", "lg 2x4x2 [m]", "otro"];
const strainPlants = ["Blue Haze", "Blue Dream", "Pineapple Express"];

const transformBasicModuleData = (dataMacCan) => {
  let basicModuleData = [];

  dataMacCan.forEach(({ mac, cans }) => {
    cans.forEach((can) => {
      let { id, type, name } = can;
      const moduleId = `${mac}@${id}`;
      name = name ? name : moduleId;
      const basicData = { id, type, mac, moduleId, name };
      basicModuleData = [...basicModuleData, basicData];
    });
  });
  return basicModuleData;
};

const getRepetObj = (arr) => {
  let uniqueObjects = arr
    .map((e) => e.moduleId)
    .map((item, index, arr) => arr.indexOf(item) === index && index);

  const indexToDelete = uniqueObjects.indexOf(false);

  if (indexToDelete === -1) {
    return arr;
  }

  const id = arr[indexToDelete].moduleId;
  const deleteRepetedObj = arr.filter((item) => item.moduleId !== id);
  return deleteRepetedObj;
};

const defineDefaultValue=(cropData,nameCropToModify)=>{
  const cropFinded=cropData.find(crop=>crop.name===nameCropToModify)
  return cropFinded?cropFinded:cropDefault
}

export default function NewCropDialog({ open, handleClose,nameCropToModify}) {
  
  const classes = useStyles();
  const theme = useTheme();

  const { cropData, setCropData } = React.useContext(CropContext);
  const { usuario, dataMacCan } = React.useContext(UserContext);
  const { username } = usuario;
  const [crop, setCrop] = React.useState(defineDefaultValue(cropData,nameCropToModify));
  const [modules, setModules] = React.useState([]);

  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    const res = transformBasicModuleData(dataMacCan);
    setModules(res);
  }, [username, dataMacCan]);

  const handleChange = (e, arg) => {
    let value = e.target.value;

    if (arg === "modules") {
      value = getRepetObj(value);
    }

    const cropClone = {
      ...crop,
      [arg]: value,
    };
    console.log(cropClone);
    setCrop(cropClone);
  };

  const saveCropFireStore = async () => {
    try {
      const addrCollection = username + "/crop/" + crop.name;
      await db.collection(addrCollection).doc("information").set(crop);
    } catch (error) {
      const newError = new Error(error);
      console.error(newError);
    }
  };

  const updateCropPage = () => {
    if(nameCropToModify){
      const clone=cropData.map(item=>item.name===nameCropToModify?crop:item)
      setCropData(clone)
      localStorage.setItem("crop", JSON.stringify(clone));
      return 
    }
    const clone=[...cropData, crop]
    setCropData(clone);
    localStorage.setItem("crop", JSON.stringify(clone));
  };

  
  const submit = async (e) => {
    e.preventDefault();
    const cropExists = cropData.find((item) => item.name === crop.name);
    const isNotACropToModify=!nameCropToModify
    if (cropExists && isNotACropToModify) {
      setAlert("Nombre de cultivo ya existe");
      return;
    }

    await saveCropFireStore();
    updateCropPage();
    onClose();
  };

  const onClose=()=>{
    handleClose();
    setAlert(null)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        Información de cultivo
      </DialogTitle>

      <form onSubmit={submit}>
        <DialogContent>
          <FormGroup className={classes.formControl}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <SelectColor
                  color={crop.color}
                  handleChange={(e) => handleChange(e, "color")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Nombre de cultivo"
                  name="name"
                  autoComplete="Cultivo"
                  onChange={(e) => handleChange(e, "name")}
                  value={crop.name}
                  disabled={nameCropToModify?true:false}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Código postal"
                  name="zipCode"
                  autoComplete="zipCode"
                  onChange={(e) => handleChange(e, "zipCode")}
                  value={crop.zipCode}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  required
                  fullWidth
                  label="system"
                  value={crop.system}
                  onChange={(e) => handleChange(e, "system")}
                  variant="outlined"
                >
                  {SystemCrops.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  required
                  fullWidth
                  label="size"
                  value={crop.size}
                  onChange={(e) => handleChange(e, "size")}
                  variant="outlined"
                >
                  {sizes.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Número de plantas"
                  name="numberOfPlants"
                  autoComplete="numberOfPlants"
                  onChange={(e) => handleChange(e, "numberOfPlants")}
                  value={crop.numberOfPlants}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Fecha de inicio"
                  name="initDate"
                  autoComplete="initDate"
                  onChange={(e) => handleChange(e, "initDate")}
                  value={crop.initDate}
                  type="date"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  required
                  fullWidth
                  label="Variedad"
                  value={crop.strainPlant}
                  onChange={(e) => handleChange(e, "strainPlant")}
                  variant="outlined"
                >
                  {strainPlants.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Semanas de germinación"
                  name="germinationWeeks"
                  autoComplete="germinationWeeks"
                  onChange={(e) => handleChange(e, "germinationWeeks")}
                  value={crop.germinationWeeks}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Semanas de plántula"
                  name="seedlingWeeks"
                  autoComplete="seedlingWeeks"
                  onChange={(e) => handleChange(e, "seedlingWeeks")}
                  value={crop.seedlingWeeks}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Semanas de vegetación"
                  name="vegetativeWeeks"
                  autoComplete="vegetativeWeeks"
                  onChange={(e) => handleChange(e, "vegetativeWeeks")}
                  value={crop.vegetativeWeeks}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Semanas de floración"
                  name="floweringWeeks"
                  autoComplete="floweringWeeks"
                  onChange={(e) => handleChange(e, "floweringWeeks")}
                  value={crop.floweringWeeks}
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel id="demo-mutiple-chip-label">
                  GAPY Módulos
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={crop.modules}
                  onChange={(e) => handleChange(e, "modules")}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value.moduleId}
                          label={value.name}
                          className={classes.chip}
                          color="primary"
                          size="medium"
                        />
                      ))}
                    </div>
                  )}
                >
                  {modules.map(({ moduleId, name }) => (
                    <MenuItem key={moduleId} value={{ moduleId, name }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Nota"
                  name="note"
                  autoComplete="note"
                  onChange={(e) => handleChange(e, "note")}
                  value={crop.note}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </DialogContent>

        {alert && <Alert severity="error">{alert}</Alert>}


        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button color="primary" type="submit">
            Agree
          </Button>
        </DialogActions>

        
      </form>
    </Dialog>
  );
}
