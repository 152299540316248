import React from 'react'
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.gapy.io/">
          GAPY- Grow Any Plant Yourself
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    )
}

export default Copyright
export {Copyright}
