import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'



const MultiSelectMui = ({valueSelect,labelSelect,onChange,selectOptions,placeHolder}) => {
   

    return (
        <div>
            <FormControl variant="outlined" size="small" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">{labelSelect}</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={valueSelect}
                onChange={onChange}
                label={labelSelect}
                displayEmpty
                size="small"
                >
                    
                <MenuItem value="" disabled>
                    {placeHolder}
                </MenuItem>
                
                {
                    selectOptions.map((item,i)=>(
                        <MenuItem 
                        key={`${i}_select`} 
                        value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))
                }
                </Select>
            </FormControl>
        </div>
    )
}

export default MultiSelectMui
export {MultiSelectMui}