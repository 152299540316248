import React from 'react'
import { withRouter } from "react-router-dom";
import { ALL_PATHS } from "../../../../constants/routerConst";

import {Button,Avatar} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import { UserContext } from "../../../../context/UserProvider";
import facebookIcon from "../../../../assets/icons/facebookIcon.png"
import {facebookProvider} from "../../../../config/firebase";

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1, 0, 0),
      background:"#1c74d4",
      color:"#fff",
      fontWeight:"bold",
      fontFamily: "sans-serif"
    },
    avatar:{
      width:"25px",
      height:"25px"
    }
  }));

const FacebookAuthButton = ({label,...props}) => {
    const {inWithPopup } = React.useContext(UserContext);
    const classes = useStyles();

    const signIn=async()=>{
        await inWithPopup(facebookProvider)
        props.history.push(ALL_PATHS.PATH_DASHBOARD);
        
    }
    return (
        <Button
          fullWidth
          variant="contained"
          onClick={signIn}
          className={classes.button}
          startIcon={
            
            <Avatar
              src={ facebookIcon}
              className={classes.avatar}
            />
          }
        >
          {label}
        </Button>
    )
}

export default withRouter(FacebookAuthButton)
