import React from "react";

import { svgKindIO } from "../../../constants/globalConst";
import TableEnhanced from "../TableEnhanced";

const headCells = [
  { id: "1Col", numeric: false, disablePadding: true, label: "Nombre" },
  { id: "2Col", numeric: false, disablePadding: false, label: "Tipo" },
  { id: "3Col", numeric: false, disablePadding: false, label: "Máximo" },
  { id: "4Col", numeric: true, disablePadding: false, label: "Minimo" },
  { id: "5Col", numeric: true, disablePadding: false, label: "Promedio" },
];

const TableDataChart = (props) => {
  const dataTable = props.dataTable;

  const dataTransform = (item) => {
    const calculateMean = (...numbers) =>
      parseFloat(
        numbers.reduce((acc, val) => acc + val, 0) / numbers.length
      ).toFixed(2);

    const setImg = (uid) => {
      const arrayDeCadenas = uid.split("@");
      const kind = arrayDeCadenas[2];
      return svgKindIO(kind);
    };
    const infoKind = setImg(item.uid);

    const digitalToOnOff = (digital) => {
      const value = parseInt(digital);
      if (value === 0) return "OFF";
      return "ON";
    };

    const max = `${Math.max(...item.points)} ${infoKind.unit}`;
    const min = `${Math.min(...item.points)} ${infoKind.unit}`;
    const mean = `${calculateMean(...item.points)} ${infoKind.unit}`;

    const second = item.yAxis === 0 ? digitalToOnOff(max) : max;
    const third = item.yAxis === 0 ? digitalToOnOff(min) : min;
    const fourth = item.yAxis === 0 ? "N/A" : mean;

    return {
      key: item.uid,
      img: infoKind,

      first: item.label,
      second,
      third,
      fourth,
    };
  };
  const newData = dataTable.map((item) => dataTransform(item));

  return (
    <TableEnhanced
      rows={newData}
      headCells={headCells}
      tittle="Información básica"
      enableLink={false}
      //enableCheckBox={true}
    />
  );
};
export default TableDataChart;
