import React from 'react'
import {UserContext} from '../../../context/UserProvider'
import TableEnhanced from '../../Common/TableEnhanced'

import {db} from '../../../config/firebase'
//import {withRouter} from 'react-router-dom'


import {svgKindIO,svgTypeModule} from '../../../constants/globalConst'




const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
  { id: 'kind', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'can', numeric: false, disablePadding: false, label: 'CAN-Bus' },
  { id: 'mac', numeric: false, disablePadding: false, label: 'MAC' },
  { id: 'date', numeric: true, disablePadding: false, label: 'Actualización' },
];

  
 

const IOList = () => {
    
    const {dataMacCan,usuario}=React.useContext(UserContext)
    const [data,setData]=React.useState([])
    

    React.useEffect(()=>{
        const obtenerDatos=async()=>{
         try{
  
          
           if(dataMacCan.length>0){
   
             
             let dataExample=[]
             await Promise.all(
             dataMacCan.map(async(itemMac)=>{
  
                
                   await Promise.all(
  
                    itemMac.cans.map( async(itemCan)=>{
  
  
                      const addr=usuario.username+'/infoDevices/'+itemMac.mac+'/'+itemCan.id+'/configModule'
                      
                      const data=await db.collection(addr).get();
                      
                      const dataConfig=data.docs.map(doc=>( { id: doc.id,...doc.data()} ))
                      
  
                      //const _IO=dataConfig.map(item=>({ key: item.id,name:item.item.name,nodeSvgShape: item.item.kind==="5"?svgOut:svgIn, }))
                      const keyConfig=itemCan.name? itemCan.name:`Node_${itemCan.id}`

                      const _IO=dataConfig.map(item=>(
                        { key: item.id,
                          img:svgKindIO(item.item.kind),

                          first:item.item.name,
                          kind:item.item.kind,
                          second:keyConfig,
                          third:item.item.mac,
                          date:item.item.fecha
                        }))
                      
                      let IO=_IO.filter(item=>item.key!=="sendConfig")

                      
                      dataExample=[
                        {
                          key: `${itemMac.mac}@${itemCan.id}@N`,
                          img:svgTypeModule(itemCan.type),
                          
                          first:keyConfig, 
                          kind:itemCan.type,
                          second:keyConfig,
                          third:itemMac.mac,
                        },...IO,...dataExample]
                     })
  
                   )
  
              })
              )
          
              console.log(dataExample)
          setData(dataExample)
         }
         }catch(error){
           console.log(error)
         }
       }
       obtenerDatos()
       
         
       },[usuario.username,dataMacCan]);
    
    return (
        <div>
            <TableEnhanced
            rows={data}
            headCells={headCells}
            tittle="I/O"
            />
        </div>
    )
}

export default IOList
export {IOList}
