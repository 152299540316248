import React from "react";
import { AlertDialogSlide } from "./ComponentsMeasure";
import { GraphicDataDevices } from "../../Common";
import CollapseTable from "./ComponentsMeasure/CollapseTable/CollapseTable";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { MeasureContext } from "../../../context/MeasureProvider";

const ManualMeasure = () => {
  const {
    seriesModifier,
    setSeriesModifier,
    inputDevices,
    outDevices,
    getDataFromDB,
    handleClickOpenDialog,
  } = React.useContext(MeasureContext);

  return (
    <div>
      <CollapseTable />
      <AlertDialogSlide />
      <GraphicDataDevices
        inputDevices={[]}
        outDevices={[]}
        manualDevices={inputDevices}
        getDataFromDB={getDataFromDB}
        seriesModifier={seriesModifier}
        setSeriesModifier={setSeriesModifier}
        component={{
          alert: (
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => handleClickOpenDialog(e, null)}
              startIcon={<AddIcon />}
            >
              Agregar
            </Button>
          ),
        }}
      />
    </div>
  );
};

export default ManualMeasure;
export { ManualMeasure };
