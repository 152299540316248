import { Grid, Link } from "@material-ui/core";
import React from "react";

const FooterForm = ({ setKindFormUser, Link1, Link2 }) => {
  return (
    <Grid container>
      <Grid item xs>
        <Link
          href="#"
          variant="body2"
          onClick={() => setKindFormUser(Link1.to)}
        >
          {Link1.text}
        </Link>
      </Grid>

      <Grid item>
        <Link
          href="#"
          variant="body2"
          onClick={() => setKindFormUser(Link2.to)}
        >
          {Link2.text}
        </Link>
      </Grid>
    </Grid>
  );
};

export default FooterForm;
