import React from "react";
import withWidth from "@material-ui/core/withWidth";
import CropProvider from "../../../context/CropProvider";
import CropApp from "./Components/CropApp";

const CropWithContext = (props) => {
  const { width } = props;
  return (
    <CropProvider>
      <CropApp width={width}/>
    </CropProvider>
  );
};

const Crop = React.memo(withWidth()(CropWithContext));

export { Crop };
export default Crop;
