import React from "react";

import { IconButton } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import BarTileIconDeleteDialog from "./BarTileIconDeleteDialog";

const IconButtonDelete = ({ classNameIcon, nameCrop }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const onClickDeleteIcon = () => {
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <IconButton
        aria-label="DeleteIcon"
        size="medium"
        onClick={onClickDeleteIcon}
      >
        <DeleteIcon className={classNameIcon} fontSize="large" />
      </IconButton>

      {openDeleteDialog && (
        <BarTileIconDeleteDialog
          open={openDeleteDialog}
          handleClose={() => setOpenDeleteDialog(false)}
          nameCrop={nameCrop}
        />
      )}
    </>
  );
};

export default IconButtonDelete;
