import app from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "@firebase/functions";
// Your web app's Firebase configuration

let firebaseConfig = {
  apiKey: "AIzaSyB2qs1NAoqd9GBEv08QloOcf_XAvVTZNik",
  authDomain: "gapy-c999c.firebaseapp.com",
  databaseURL: "https://gapy-c999c.firebaseio.com",
  projectId: "gapy-c999c",
  storageBucket: "gapy-c999c.appspot.com",
  messagingSenderId: "311871399082",
  appId: "1:311871399082:web:b75c9250b7a122a1f75cd3",
};

// Initialize Firebase
app.initializeApp(firebaseConfig);
var db = app.firestore();
const functions = app.functions();
const auth = app.auth();
const storage = app.storage();
const googleProvider = new app.auth.GoogleAuthProvider();
const facebookProvider = new app.auth.FacebookAuthProvider();

/*if (window.location.host === "localhost:3000") {
  // db.settings({ host: 'localhost:8080', ssl: false });
  app.functions().useFunctionsEmulator("http://localhost:5001");
  //app.auth().useEmulator("http://localhost:9099/");
}*/

export { db, auth, functions, googleProvider, facebookProvider, storage };
