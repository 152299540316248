import React from "react";
import { functions, db } from "../config/firebase";
import { UserContext } from "./UserProvider";
export const CropContext = React.createContext();

const getCropCollections = async (docPath) => {
  try {
    const getSubCollections = functions.httpsCallable("getSubCollections");
    const { data } = await getSubCollections({ docPath });
    return data.collections;
  } catch (error) {
    const newError = new Error(error);
    console.error(newError);
  }
};

const CropProvider = (props) => {
  const { usuario } = React.useContext(UserContext);
  const { username } = usuario;

  const [cropData, setCropData] = React.useState([]);

  React.useEffect(() => {
    const docPath = `${username}/crop`;

    const getAllCropsData = async (cropCollections) => {
      let allCropsInfo = [];
      await Promise.all(
        cropCollections.map(async (collection) => {
          const addr = username + "/crop/" + collection;
          const res = await db.collection(addr).doc("information").get();
          allCropsInfo = [...allCropsInfo, {...res.data(),id:collection}];
        })
      );
      return allCropsInfo;
    };

    const getStateCropData = async () => {
      const cropCollections = await getCropCollections(docPath);
      if (!cropCollections || cropCollections.length === 0) return;
      const allCropsInfo = await getAllCropsData(cropCollections);
      return allCropsInfo;
    };

    const setStateCropData = async () => {
      const local = JSON.parse(localStorage.getItem("crop"));

      if (local) {
        setCropData(local);
      } else {
        const allCropsInfo=await getStateCropData();
        setCropData(allCropsInfo);
        localStorage.setItem("crop", JSON.stringify(allCropsInfo));
        console.log("updating local crop");
      }
    };

    setStateCropData();
  }, [username]);

  return (
    <CropContext.Provider
      value={{
        cropData,
        setCropData,
      }}
    >
      {props.children}
    </CropContext.Provider>
  );
};

export default CropProvider;
