import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, LinearProgress, MenuItem, Select, makeStyles, withStyles } from '@material-ui/core'
import { green, grey } from '@material-ui/core/colors';
import { UserContext } from '../../../context/UserProvider';
import { db } from '../../../config/firebase';
import { SelectWaterOut } from './components/SelectWaterOut';
import { IrrigationContext } from '../../../context/IrrigationContext/IrrigationContext';
import { Alert } from '@material-ui/lab';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
     marginLeft: 12,
  },
  root2: {
    width: '100%',
  },
  miniAlert: {
    marginLeft: 12,
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#5BB033",
  },
}))(LinearProgress);

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
      '&$disabled': {
        color: grey[500],
      },
    },
    checked: {},
    disabled: {},
  })((props) => <Checkbox color="default" {...props} />);

export const ManualIrrigation = ({ canId, macId, arrayNames, handleAlert}) => {
  const classes = useStyles();
  const { usuario, names }=useContext(UserContext);
  const { zonesNames, outState, setOutState,completeManual, waterPumps, progressFlag, setCompleteManual } = useContext(IrrigationContext);
  const [minutos, setMinutos] = useState(() => {
    // Recuperar el tiempo guardado o establecer a 1
    const savedTime = localStorage.getItem('minuts');
    return savedTime ? JSON.parse(savedTime) : 1;
  });
  const [segundos, setSegundos] = useState(() => {
    // Recuperar el tiempo guardado o establecer a 0
    const savedTime = localStorage.getItem('seconds');
    return savedTime ? JSON.parse(savedTime) : 0;
  });
  const [started, setStarted] = useState(false);
  const [estadoBoton, setEstadoBoton] = useState("Iniciar");
  const [buttonState, setButtonState] = useState(1);
  const [progress, setProgress] = useState(0);
  const [flagMiniConfirm, setFlagMiniConfirm] = useState(false)

  // Actualizar localStorage cuando `time` cambia
  useEffect(() => {
    localStorage.setItem('minuts', JSON.stringify(minutos));
    localStorage.setItem('seconds', JSON.stringify(segundos));
    localStorage.setItem('buttonState', JSON.stringify(estadoBoton));
    localStorage.setItem('buttonValue', JSON.stringify(buttonState));
  }, [minutos,segundos,buttonState,estadoBoton]);
  

  const isButtonEnabled = outState.some(estado => estado);

  const handleMinutsChange = (event) => {
    setMinutos(event.target.value);
  };

  const handleSegundosChange = (event) => {
    setSegundos(event.target.value);
  };

  const handleZonaChange = (index) => {
    const newZone = [...outState];
    newZone[index] = !newZone[index];
    //setZona(newZone);
    setOutState(newZone)
  };

  const sendingIrrigationRoutine = async (e, n) => {

    const item = {
        msMqtt: e,
        mac: macId,
        action: "Irrigation Routine",
        fecha: Date.now(),
        uid: n
    }

    try {
        const addr = `${usuario.username}/infoDevices/${macId}/${canId}/sendConfigModule`
        await db.collection(addr).doc("sendConfig").set({ item })
        
        console.log("Esto es macId:", macId)
        // console.log(item)
    } catch (error) {
        console.log(error)
    }
  }

  const handleSendMessage = () => {
    let mqtt = "";
      const len = 20;
      const action = 246;
      const minutesToSend = buttonState === 0 ? 0 : minutos;
      const secondsToSend = buttonState === 0 ? 0 : segundos;
      const arrayZones = buttonState === 0 ? Array(16).fill(0) : outState.map(state => state ? 1 : 0);
      // const buttonActive = toggleValor();
      mqtt =
          len +
          "," +
          action +
          "," +
          canId + 
          "," + 
          buttonState + 
          "," + 
          [...arrayZones] +
          "," +
          minutesToSend +
          "," +
          secondsToSend;
    //console.log(mqtt);
    const nodeUid = macId + "@" + canId;
    // console.log(nodeUid);
    sendingIrrigationRoutine(mqtt,nodeUid);
  }

  const handleButtonAction = () => {
    setButtonState(valorAnterior => valorAnterior === 0 ? 1 : 0);
    setStarted(valorAnterior => valorAnterior ? false : true);
    handleAlert(true,"info");
    handleSendMessage();   
    setEstadoBoton((prevEstado) => (prevEstado === 'Iniciar' ? 'Detener' : 'Iniciar'));  
  } 

  // Temporizador para la barra de progreso
  useEffect(() => {
    if(progressFlag) {
    const totalSeconds = minutos * 60 + segundos;
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(interval);
          return prevProgress; // Mantener en 90% hasta que `completed` sea true
        }
        const increment = 90 / totalSeconds;
        return prevProgress + increment;
      });
    }, 1000);

    return () => clearInterval(interval);
    }
  }, [minutos,segundos,completeManual, progressFlag]);

   // Observar cambios en `completed` para llenar al 100%
   useEffect(() => {
    if (completeManual && estadoBoton === "Detener") {
      setProgress(100);
      setEstadoBoton((prevEstado) => (prevEstado === 'Iniciar' ? 'Detener' : 'Iniciar'));
      setButtonState(valorAnterior => valorAnterior === 0 ? 1 : 0);
      setFlagMiniConfirm(true) 
      setStarted(false)
      setCompleteManual(false)

      //console.log("Entre a completeManual1")

    } else if(progressFlag === false) {
      setProgress(0)
      //setFlagMiniConfirm(false)
    }
  }, [completeManual,progressFlag]);

  useEffect(() => {
    if(flagMiniConfirm){
      const timer = setTimeout(() => {
        setFlagMiniConfirm(false);
      }, 3000); // Ocultar el alerta después de 2 segundos
  
      return () => {
        clearTimeout(timer);
      };
    }

  }, [flagMiniConfirm])
  
     
  return (
    <>
    <div style={{ marginTop: '0px',marginBottom: '25px' }}>
      <SelectWaterOut canId={canId} />
    </div>
    

    <Divider />

    <div style={{ marginTop: '20px',marginBottom: '20px' }}>
      <Grid 
      container 
      justifyContent="center"
      alignItems="center"
      >
        <Grid item xs={0} sm={3}></Grid>

        <Grid item xs={6} sm={6}>

        <FormControl>
          <FormLabel>Zonas de Riego</FormLabel>
          <FormGroup row>

            {names?.map((_, index) => (
            
              <Grid key={`grid-${index}`} container
              justifyContent="flex-start"
              alignItems="center" spacing={2}>
              <FormControlLabel
              control={
                <GreenCheckbox  checked={outState[index]} 
                onChange={() => handleZonaChange(index)} 
                  />}
              label={names.length ? names[index] : "Cargando..."}
              hidden={!zonesNames.includes(names[index])}
              
              />              
              </Grid>
            
            ))}
          </FormGroup>
          <FormHelperText>Selecciona las zonas a regar</FormHelperText>
        </FormControl>
        </Grid>

      </Grid>
    </div>

    <Divider />

    <div style={{ marginTop: '30px',marginBottom: '20px' }}>
      < Grid 
      container 
      direction='row'
      justifyContent="center"
      alignItems="center"
      spacing={2}
      >
        
        <Grid item xs={3}>

          <FormControl>
            <FormLabel>Minutos :</FormLabel>
            <Select value={minutos} onChange={handleMinutsChange}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>

        </Grid> 
          
        <Grid item xs={2}>

          <FormControl>
            <FormLabel>Segundos :</FormLabel>
            <Select value={segundos} onChange={handleSegundosChange}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>

        </Grid>

      </Grid>
        
    </div>

    <div style={{ marginTop: '30px',marginBottom: '20px' }}>
      < Grid 
      container 
      direction='row'
      justifyContent="center"
      alignItems="center"
      spacing={2}
      >     
        <Button
          variant="contained"
          color="primary"
          onClick={handleButtonAction}
          disabled={!isButtonEnabled || (minutos === 0 && segundos === 0) || waterPumps.length === 0 }
        >
          {estadoBoton}
        </Button>

        {started && <CircularProgress size={30} className={classes.buttonProgress} />}
        {(!started && flagMiniConfirm ) && 
          <Alert className={classes.miniAlert} iconMapping={{ success: <CheckCircleOutline fontSize="inherit" /> }}>
            ¡Rutina terminada con exito!
          </Alert>
        }         
        
        
      </Grid>
    </div>

    <div className={classes.root2} style={{ marginBottom: "15px"}}>
      { (progressFlag) && <BorderLinearProgress variant="determinate" value={progress} />}
    </div>
    </>
  )
}
