import React from "react";
import { NavLink } from "react-router-dom";

import { IconButton, Tooltip, Grid, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";

import UserMenu from "./UserMenu";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    [theme.breakpoints.up("lg")]: { display: "none" },
  },
}));



const NavPrivateLinks = ({ onClickDynamicBox }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <IconButton
          color="primary"
          aria-label="menu"
          className={classes.menuButton}
          onClick={onClickDynamicBox}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
      </Grid>

      <Grid item>
        <NavLink to="/dashboard">
          <Tooltip title="Tablero" arrow>
            <IconButton color="primary" aria-label="menu">
              <DashboardIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </NavLink>
      </Grid>

      <Grid item>
        <UserMenu />
      </Grid>
    </Grid>
  );
};

export default NavPrivateLinks;
