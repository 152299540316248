import React, { useContext, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';
import { UserContext } from '../../../../context/UserProvider';
import { MapForCrop } from '../../Maps/MapForCrop';


const useStyles = makeStyles((theme) => ({
	dialogPaper: {
	  minWidth: '40vw', // Asegura un mínimo de ancho para el diálogo
	  maxWidth: '80vw', // Un máximo para evitar que sea demasiado grande
	  [theme.breakpoints.down('xs')]: {
		minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	root: {
		'& .MuiTextField-root': {
		  margin: theme.spacing(1),
		  
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
  }));

export const NewCropConfigPopup = ({openPopup, handleClosePopup, totalCrops, setTotalCrops, setOpenConfigNewCrop}) => {
	const classes = useStyles();
	const {usuario} = useContext(UserContext);
	const { addNewTabName, sendToCRcrop} = useContext(MultiCropContext)
	const [nameNewCrop, setNameNewCrop] = useState("");
	const [weekNewCrop, setWeekNewCrop] = useState("");
	const [dayNewCrop, setDayNewCrop] = useState("");
	const [placeNewCrop, setPlaceNewCrop] = useState("");
	const [newCrop, setNewCrop] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [cropCoordinates, setCropCoordinates] = useState(null);

	const handleWeekChange = (event) => {
		const numberValue = event.target.value
		if((parseInt(numberValue) >= 0 && parseInt(numberValue) < 53) || numberValue === "") {
			setWeekNewCrop(event.target.value)
		}
	}

	const handleDayChange = (event) => {
		const numberValue = event.target.value
		if((Number(numberValue) >= 0 && Number(numberValue) < 8) || numberValue === "") {
			setDayNewCrop(event.target.value)
		}
	}

	const handlePlaceChange = (event) => {
		const { value } = event.target;
		const newPlace = value === "indoor" ? "Interior" : "Exterior";
		setPlaceNewCrop(newPlace)
	}


	const addNewCrop = async() => {
		setIsLoading(true)
		const placeToSend = placeNewCrop === "Indoor" ? "Interior" : "Exterior";
		const data = {
			username: usuario.username,
			action: "addCrop",
			nameOfCrop: nameNewCrop,
			typeOfCrop: newCrop,
			weekOfCrop: weekNewCrop,
			dayOfCrop: dayNewCrop,
			placeOfCrop: placeToSend,
			coordinates: cropCoordinates
		}
		console.log("Esto es data:",data)
		//const response = await saveData(nameNewCrop,weekNewCrop,dayNewCrop);
		const response = await sendToCRcrop(data);
		if(response === "allOK") {
			setTimeout(async () => {
				// const placeToSend = placeNewCrop === "Indoor" ? "Interior" : "Exterior";
				// const data = {
				// 	username: usuario.username,
				// 	action: "addCrop",
				// 	nameOfCrop: nameNewCrop,
				// 	typeOfCrop: newCrop,
				// 	weekOfCrop: weekNewCrop,
				// 	dayOfCrop: dayNewCrop,
				// 	placeOfCrop: placeToSend,
				// 	coordinates: cropCoordinates
				// }
				// console.log("Esto es data:",data)
				//const response = await saveData(nameNewCrop,weekNewCrop,dayNewCrop);
				// const response = await sendToCRcrop(data);
				// if(response === "allOK") {
					setOpenConfigNewCrop(false);
					//addNewTabName(nameNewCrop);
					setNameNewCrop("");
					setWeekNewCrop("");
					setDayNewCrop("");
					setNewCrop("");
					setPlaceNewCrop("");
					setIsLoading(false);
				// }
			}, 15000);
		}
		  
	}

  return (
	<div>
		<Dialog 
        // fullScreen={fullScreen}
        open={openPopup}
        onClose={handleClosePopup}
        classes={{ paper: classes.dialogPaper }} 
        >
			<DialogTitle id="newCrop-title">{"Configuración de Nuevo Cultivo"}
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClosePopup}>
				<CloseOutlined />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<div style={{marginTop: "5px", marginBottom: "10px"}}> 
					<Divider />
				</div>
				<Grid container justifyContent='center' alignItems='center'>
					<label className={"mt-3"}>Nombre del Cultivo:</label>				
					<TextField 
					id="name-crop" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Nombre"
					value={nameNewCrop}
					onChange={(event) => {setNameNewCrop(event.target.value)}}
					className={classes.textField}
					/>
				</Grid>

				<div style={{marginTop: "10px", marginBottom: "10px"}}> 
					<Divider />
				</div>
				<Grid container justifyContent='center' alignItems='center'>
					<h5>Datos del cultivo</h5>
				</Grid>

				<Grid container justifyContent='flex-start'>
				
					<Grid item xs={12}>
						<label className={"mt-3"}>¿Qué estás cultivando?:</label>
						<TextField 
						id="name-crop" 
						variant="outlined" 
						size="small"
						margin="dense"
						label="Tipo de cultivo"
						value={newCrop}
						onChange={(event) => {setNewCrop(event.target.value)}}
						className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<label className={"mt-3"}>Semana:</label>
						<TextField 
						id="week-crop" 
						size="small"
						margin="dense"
						label="Semana"
						value={weekNewCrop}
						type='number'
						variant="outlined" 
						onChange={handleWeekChange}
						className={classes.textField}
						inputProps={{ min: 0, max: 53 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<label className={"mt-3"}>Día:</label>
						<TextField 
						id="day-crop" 
						variant="outlined" 
						size="small"
						margin="dense"
						label="Día"
						value={dayNewCrop}
						type='number'
						onChange={handleDayChange}
						className={classes.textField}
						inputProps={{ min: 0, max: 7 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<label className={"mt-3"}>Entorno del cultivo:</label>
						<FormControl variant="outlined" className={classes.formControl}>
							{/* <InputLabel id="demo-simple-select-outlined-label">Lugar</InputLabel> */}
							<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							value={placeNewCrop}
							onChange={(event) => setPlaceNewCrop(event.target.value)}
							displayEmpty
							// label="Lugar"
							labelWidth={0}
							>
							<MenuItem value={""}>
								<em>Interior/Exterior</em>
							</MenuItem>
							<MenuItem value={"Indoor"}>Interior</MenuItem>
							<MenuItem value={"Exterior"}>Exterior</MenuItem>
							</Select>
						</FormControl>
						{/* <TextField 
						id="site-crop" 
						variant="outlined" 
						size="small"
						margin="dense"
						label="Lugar"
						value={placeNewCrop}
						onChange={(event) => setPlaceNewCrop(event.target.value)}
						className={classes.textField}
						/> */}
					</Grid>
				</Grid>
				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={12} sm={12}>
						<div style={{ textAlign: 'center'}}>
							<label className={"mt-3"}>Ubicacion del Cultivo:</label>
						</div>
						
					</Grid>
					<Grid item xs={12} sm={12}>
						<MapForCrop setCurrentCoordinates={setCropCoordinates} initialCoordinates={null}/>
					</Grid>
				</Grid>

				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					{isLoading ? 
					<CircularProgress color="primary" /> 
					:
					<Button 
					variant="contained" 
					color="primary" 
					onClick={addNewCrop}
					disabled={nameNewCrop === "" || dayNewCrop === "" || weekNewCrop === "" || newCrop === "" || 
					placeNewCrop === "" || cropCoordinates === null}>
						Crear Cultivo
					</Button>
					}
				</Grid>					
            	
          	</DialogContent>

		</Dialog>
	</div>
  )
}
