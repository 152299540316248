import React from 'react'

const SelectType = (props) => {
    const id="SelectType"+props.id;
    //console.log(id)
    return (
        <div>
            <div className="row">
                <div className="form-group col-6">
                    <label htmlFor={id}>{props.label}</label>
                </div>
                <div className="col-6">
                    <select 
                    className="form-control"
                    name={props.id} 
                    id={id} 
                    onChange={(e)=>props.handleToggle(e.target)}
                    value={props.value}
                    disabled={props.desactivar? true:false}
                    >
                    <option value="0" >Ventilador</option>
                    <option value="1" >Extractor</option>
                    <option value="2" >Humidificador</option>
                    <option value="3" >Leds</option>
                    {/* <option value="3" >Pulso Diario</option> */}
                    <option value="4" >Bomba</option>
                    <option value="5" >Always</option>
                    
                    </select>
                    
                    
                </div>
            </div>
        </div>
    )
}

export default React.memo(SelectType)
