import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Grid, Paper } from "@material-ui/core";
import HeadTile from "./HeadTile";
import { db } from "../../../../config/firebase";
import { UserContext } from "../../../../context/UserProvider";
import { get_Uid_State_CurrenteValue_ForToggle } from "../../../../context/functions/DashboardFunctions/divideUIDByKind";
const useStyles = makeStyles({
  paperGraphics: {
    width: "215px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
});

const PrettoSlider = withStyles({
  root: {
    color: "#5BB033",
    height: 8,
    marginRight: "20px",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const PWMSlider = ({ col, data, onClickCrossButton, style }) => {
  const { usuario, dataMacCan } = React.useContext(UserContext);
  const { username } = usuario;
  const { name, uid } = data;
  const canId = String(uid.split("@")[1]);
  const mac = String(uid.split("@")[0]);
  const kind = String(uid.split("@")[2]);
  const outid = String(uid.split("@")[3]);
  const classes = useStyles();
  const [valuePWM, setValuePWM] = useState(0);
  async function sendData({value}) {
    try {
      const configAddr =
        username + "/infoDevices/" + mac + "/" + canId + "/sendConfigModule";
      const msMQTT = `3,10,1,${outid},${value}`;
      const item = {
        msMqtt: msMQTT,
        mac: mac,
        action: "Advanced Config PWM New Set",
        fecha: Date.now(),
        uid: uid,
      };
      console.log({ msg: "[PWM New Set]", item });
      await db.collection(configAddr).doc("sendConfig").set({ item });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const idxMac = dataMacCan.findIndex((elmt) => {
      return String(elmt.mac) == String(mac);
    });
    const idxCan = dataMacCan[idxMac].cans.findIndex((elmt) => {
      return String(elmt.id) == String(canId);
    });
    const docPath = `${username}/infoDevices/${mac}/${canId}/fromModule/render`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      const resp = get_Uid_State_CurrenteValue_ForToggle({
        fromModuleActual: data,
        kind: kind,
        outid: outid,
        toModuleDataActual:[],
        typeOfModule: dataMacCan[idxMac].cans[idxCan].type,
      });
      setValuePWM(resp.state);
      
    });

    return () => {
      unsubscribe();
    };
  }, [usuario, uid]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile
        name={name}
        uid={uid}
        onClickCrossButton={onClickCrossButton}
        col={col}
      />

      <Grid item xs={5}>
        <h5>
          <span className="badge badge-dark">
            {valuePWM != "nan" && valuePWM != "inf" ? valuePWM : "N/D"}
          </span>
        </h5>
      </Grid>
      <Paper className={classes.paperGraphics} elevation={0}>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="name"
          value={Number(valuePWM)}
          min={0}
          step={1}
          max={255}
          onChangeCommitted={(obj, value) => {
            setValuePWM(value);
            sendData({value});
          }}
        />
      </Paper>
    </Grid>
  );
};

export default React.memo(PWMSlider, (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
});
