import React, { useEffect, useState } from 'react'
import { IrrigationContext } from './IrrigationContext'


export const IrrigationProvider = ({ children }) => {
    const [waterPumps, setWaterPumps] = useState([]);
    const [waterSource, setWaterSource] = useState([]);
    const [deviceType, setDeviceType] = useState("");
    const [outletForPump, setOutletForPump] = useState(Array(16).fill(false));
    const [zonesNames, setZonesNames] = useState([]); 
    const [outState, setOutState] = useState(Array(16).fill(false))
    const [completeManual, setCompleteManual] = useState(false)
    const [progressFlag, setProgressFlag] = useState(false);
    const [autoArray, setAutoArray] = useState(Array(10).fill(Array(16).fill(0)));
    const [startHour, setStartHour] = useState(Array(10).fill("23:00"));
    const [minutos, setMinutos] = useState(Array(10).fill(1));
    const [segundos, setSegundos] = useState(Array(10).fill(0));
    const [automaticDays, setAutomaticDays] = useState(Array(10).fill(Array(7).fill(0)));
    const [rows, setRows] = useState([]);
    const [pumpsSelected, setPumpsSelected] = useState(Array(10).fill([]))
    const [waterSelected, setWaterSelected] = useState(Array(10).fill([]))
    const [zonesSelected, setZonesSelected] = useState(Array(10).fill([]))
    const [daysOfWeek, setDaysOfWeek] = useState(Array(10).fill([]))
    const [disableAutoButton, setDisableAutoButton] = useState(false)
    const [autoFlagSave, setAutoFlagSave] = useState(false);
    const [typeSwitch, setTypeSwitch] = useState(Array(10).fill({
      checkedA: false,
      checkedB: false,
    }));

    const [timeON, setTimeON] = useState(Array(10).fill(1))
    const [timeOFF, setTimeOFF] = useState(Array(10).fill(1))
    const [endHour, setEndHour] = useState(Array(10).fill("00:00"));
    const [removeFlag, setRemoveFlag] = useState(false);
    const [rowsBeforeRemoved, setRowsBeforeRemoved] = useState([])
    const days = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"];

    const resetDataRow = (index) => {
      const pumps = [...pumpsSelected]
      const water = [...waterSelected]
      const zones = [...zonesSelected]
      const hourS = [...startHour]
      const minuts = [...minutos]
      const seconds = [...segundos]
      const endH = [...endHour]
      const minutsOn = [...timeON]
      const minutsOff = [...timeOFF]
      const config = [...typeSwitch]
      const daysSelec = [...daysOfWeek]
      pumps.splice(index,1)
      water.splice(index,1)
      zones.splice(index,1)
      hourS.splice(index,1)
      minuts.splice(index,1)
      seconds.splice(index,1)
      endH.splice(index,1)
      minutsOn.splice(index,1)
      minutsOff.splice(index,1)
      config.splice(index,1)
      daysSelec.splice(index,1)
      pumps.push([])
      water.push([])
      zones.push([])
      hourS.push("23:00")
      minuts.push(1)
      seconds.push(0)
      endH.push("00:00")
      minutsOn.push(1)
      minutsOff.push(1)
      config.push({
        checkedA: false,
        checkedB: false,
      })
      daysSelec.push([])

      setPumpsSelected([...pumps])
      setWaterSelected([...water])
      setZonesSelected([...zones])
      setStartHour([...hourS])
      setMinutos([...minuts])
      setSegundos([...seconds])
      setEndHour([...endH])
      setTimeON([...minutsOn])
      setTimeOFF([...minutsOff])
      setTypeSwitch([...config])
      setDaysOfWeek([...daysSelec])

    }
    
    
  return (
    <IrrigationContext.Provider value={{ 
      waterPumps, 
      deviceType,
      outletForPump,
      waterSource,
      zonesNames,
      outState,
      completeManual,
      progressFlag,
      autoArray,
      startHour,
      minutos,
      segundos,
      automaticDays,
      rows,
      pumpsSelected,
      waterSelected,
      daysOfWeek,
      zonesSelected,
      disableAutoButton,
      autoFlagSave,
      typeSwitch,
      timeON,
      timeOFF,
      endHour,
      removeFlag,
      rowsBeforeRemoved,
      days,
      setDeviceType, 
      setWaterPumps, 
      setOutletForPump,
      setWaterSource,
      setZonesNames,
      setOutState,
      setCompleteManual,
      setProgressFlag,
      setAutoArray,
      setStartHour,
      setMinutos,
      setSegundos,
      setAutomaticDays,
      setRows,
      setPumpsSelected,
      setWaterSelected,
      setZonesSelected,
      setDaysOfWeek,
      setDisableAutoButton,
      setAutoFlagSave,
      setTypeSwitch,
      setTimeON,
      setTimeOFF,
      setEndHour,
      resetDataRow,
      setRemoveFlag,
      setRowsBeforeRemoved
      }}>
        { children }
    </IrrigationContext.Provider>
  )
}
