
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
export default function ResponsiveDateTimePickers({selectedDate,handleDateChange,id,...props}) {
  // The first commit of Material-UI
  
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justifyContent="space-around">
        
        <KeyboardDateTimePicker
          margin="normal"
          id={id}
          label="Fecha y hora"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          //allowKeyboardControl={false}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
export {ResponsiveDateTimePickers}
