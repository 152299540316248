import React from "react";
import { DialogActions, Button } from "@material-ui/core";
const FooterDialog = ({ cancelMeasurement, agreeComandDialogForm }) => (
  <DialogActions>
    <Button onClick={cancelMeasurement} color="primary">
      Cancelar
    </Button>
    <Button onClick={agreeComandDialogForm} color="primary">
      Enviar
    </Button>
  </DialogActions>
);
export default FooterDialog;
export { FooterDialog };
