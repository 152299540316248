import React from "react";
import { useParams } from "react-router-dom";

import { TimelapseContext } from "../../../../context/TimelapseProvider";
import { UserContext } from "../../../../context/UserProvider";
import IconButtonEditPhoto from "../../../Common/IconButtonEditPhoto/IconButtonEditPhoto";

const IconButtonCamera = ({ classNameIcon, nameCrop, photoURL }) => {
  const { usuario } = React.useContext(UserContext);
  const { timelapseData, setTimelapseData } =
    React.useContext(TimelapseContext);
  const { crop } = useParams();
  const addr = `${usuario.username}/crop/${crop}/timelapse/plant`;
  const namePhoto = nameCrop;
  const localTimelapseAddr = `${crop}/timelapse/plant`;
  const docFirestore = nameCrop;

  return (
    <IconButtonEditPhoto
      classNameIcon={classNameIcon}
      namePhoto={namePhoto}
      photoURL={photoURL}
      photosData={timelapseData}
      setPhotosData={setTimelapseData}
      addr={addr}
      localPhotoAddr={localTimelapseAddr}
      docFirestore={docFirestore}
      dialogTitle="Foto de cultivo"
      dialogContentText="Una foto te permitirá almacenar el estado actual de tu planta"
      editLabel="Cambiar"
      deleteLabel="Borrar"
      deleteAllItem={true}
    />
  );
};

export default IconButtonCamera;
