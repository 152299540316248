import React, { useState } from 'react';
import { LinearProgress, Grid, Box, Divider, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';


export const NutrientsForm = ({ arrayData1, handleNewData }) => {

  const [nutrientContainer, setNutrientContainer] = useState(arrayData1[1]);
  const [container, setContainer] = useState(arrayData1[0]);
  const [timeD, setTimeD] = useState(arrayData1[4]);
  const [mililiters, setMililiters] = useState({
    nutrientA: arrayData1[2],
    nutrientB: arrayData1[3]
  })
  const newValue = [...arrayData1];
  const [nameA, setNameA] = useState('');
  const [nameB, setNameB] = useState('');

  const handleOptionSelect = (e) => {
    const { value } = e.target;
    setNutrientContainer(parseInt(value));
    newValue[1] = parseInt(value);
    handleNewData(newValue);
  }

  const handleMlt = (e) => {
    const { value, id } = e.target;
    if (id === 'nutrientsValue1') {
      setMililiters({ ...mililiters, nutrientA: value });
      newValue[2] = parseInt(value);
      handleNewData(newValue);
    } else {
      setMililiters({ ...mililiters, nutrientB: value });
      newValue[3] = parseInt(value);
      handleNewData(newValue);
    }

  }

  const handleContainerChange = (e) => {
    const { value } = e.target
    setContainer(value);
    newValue[0] = parseInt(value);
    handleNewData(newValue);
  }

  const handleTimeDosi = (e) => {
    const { value } = e.target
    setTimeD(value);
    newValue[4] = parseInt(value);
    handleNewData(newValue);
  }

  const handleNames = (e) => {
    const { value, id } = e.target
    if (id === 'nutrients1') {
      setNameA(value);
    } else {
      setNameB(value);
    }
  }

  return (
    // <Box sx={{ width: '100%' }}>
    <>
    {/* <Grid> */}
      {/* <Grid container
        justifyContent="center"
        alignItems="center" spacing={4} > */}
        {/* <div style={{ marginBottom: '10px', marginTop: '20px' }}> */}
           
          < Grid container 
          direction='row'
          justifyContent="center"
          alignItems="center"
          spacing={3}>

            <Grid item xs={2}>
            Contenedor:
            </Grid>
       
            
              <TextField id="Volumen"
                label="Volumen en litros"
                type="number"
                value={container}
                onChange={handleContainerChange}
                 />
            
          
          </Grid>

        {/* </div> */}

        
        <div style={{ marginTop: '20px',marginBottom: '20px' }}>
         
          <FormControl fullWidth>

            <InputLabel id="demo-simple-select-label">Nutrientes por</InputLabel>
            <Select
              labelId="Cantidadpor"
              id="nutrientContainer"
              value={nutrientContainer}
              label="Cantidad por"
              onChange={handleOptionSelect}
            >
              <MenuItem value={1}>1 Galón</MenuItem>
              <MenuItem value={2}>1 Litro</MenuItem>
              <MenuItem value={3}>10 Litros</MenuItem>
              <MenuItem value={4}>100 Litros</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          
        </div>
      

        <div style={{ marginBottom: '10px', marginTop: '35px' }}></div>
          <Grid container direction="row"
            justifyContent="center"
            alignItems="center" spacing={4}>
            <Grid item xs={2} >

                <TextField
                label="Nutriente A"
                id="nutrients1"
                defaultValue=""
                size="small"
                variant="standard"
                onChange={handleNames}
                />

              <FormControl sx={{ m: 1, width: '5%' }} variant="outlined">
                <OutlinedInput
                  id="nutrientsValue1"
                  value={mililiters.nutrientA}
                  onChange={handleMlt}
                  endAdornment={<InputAdornment position="end">ml</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
              
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item xs={2}>
              <TextField
                label="Nutriente B"
                id="nutrients2"
                defaultValue=""
                size="small"
                variant="standard"
                onChange={handleNames}
              />
              <FormControl sx={{ m: 1, width: '5%' }} variant="outlined">
                <OutlinedInput
                  id="nutrientsValue2"
                  value={mililiters.nutrientB}
                  onChange={handleMlt}
                  endAdornment={<InputAdornment position="end">ml</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
            </Grid>

          </Grid>
        <div/>
        

        <div style={{ marginBottom: '10px', marginTop: '35px' }}>
          <Grid container direction="row"
            justifyContent="center"
            alignItems="center" spacing={2}>
            <Grid item xs={2}>
            Dosifica cada:
            </Grid>
            <FormControl sx={{ m: 1, width: '20%' }} variant="outlined">
              {/* <TextField id="outlined-search" label="Volumen del contendor" /> */}

              <OutlinedInput
                id="DosTime"
                value={timeD}
                onChange={handleTimeDosi}
                endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </FormControl>
          </Grid>
        </div>

      {/* </Grid> */}
    {/* // </Box> */}
    {/* // // </Grid> */}
    </>
  )
}
