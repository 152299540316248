import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

import { UserContext } from "./context/UserProvider";
import theme from "./Theme/temaConfig";

import NavDrawer from "./components/Common/NavDrawer/NavDrawer";

import { PublicNPrivateRoute } from "./Router";



const estilos = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const AppRender=React.memo(({user})=>{
  const classes = estilos();
  return user.estado !== null ? (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={classes.root}>
          <NavDrawer/>
          <div className={classes.content}>
            <div className={classes.toolbar} />
            <PublicNPrivateRoute />
          </div>
        </div> 

      </Router>
    </ThemeProvider>
  ) : (
    <div>Cargando...</div>
  )
})


function App() {
  const { usuario } = React.useContext(UserContext);
  
  return <AppRender user={usuario}/>
}

export default App;
