import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { drawerWidth } from "../../../../constants/NavBarConst";
import DialogNewCrop from "./NewCropDialog"

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    top: "auto",
    bottom: 0,
    height: 60,
    background: "#fff",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    background: "#fff",
  },
  offset: {
    height: 60,
    width: "100%",
  },
}));

export default function BottomAppBarCrop() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.offset} />
      
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Fab
            aria-label="add"
            className={classes.fabButton}
            onClick={handleClickOpen}
          >
            <AddIcon color="primary" />
          </Fab>
        </Toolbar>
      </AppBar>

      <DialogNewCrop 
      open={open}
      handleClose={handleClose}
      />

    </>
  );
}
