import React from 'react'
import FacebookAuthButton from './FacebookAuthButton'
import GoogleAuthButton from './GoogleAuthButton'

const SocialAuhtButtons = ({labelGoogle,labelFacebook}) => {
    return (
        <div>
            <GoogleAuthButton label={labelGoogle}/>
            <FacebookAuthButton label={labelFacebook}/>
        </div>
    )
}

export default SocialAuhtButtons
