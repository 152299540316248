import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ImageToEditDialogActionsApp from "./ImageToEditDialogActionsApp";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 0 10px 0",
  },
}));

export default function ImageToEditDialogApp({
  open,
  setOpen,
  ImgComponent,
  dialogTitle,
  dialogContentText,
  deletePhoto,
  selectFile,
  error,
  loading,
  deleteLabel,
  editLabel
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>{ImgComponent}</Grid>

        <Grid item xs={12}>
          <DialogTitle id="alert-dialog-title" align="center">
            {dialogTitle}
          </DialogTitle>
        </Grid>

        <Grid item xs={12}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogContentText}
            </DialogContentText>
          </DialogContent>
        </Grid>

        <ImageToEditDialogActionsApp
          deletePhoto={deletePhoto}
          selectFile={selectFile}
          error={error}
          loading={loading}
          deleteLabel={deleteLabel}
          editLabel={editLabel}
        />
      </Grid>
    </Dialog>
  );
}
