import React from 'react'
import InputNumber from '../ConfigComponents/InputNumber'
import DragChart from '../ConfigComponents/DragChart'

const ConfigFan = (props) => {
    //console.log(props.data)
    const value=(data)=>{
        if(data===undefined){
            const dt=[0,0];
            //console.log(dt)
            return dt
        }
        else {
            const dt=data;
            //console.log(dt)
            return dt;
        }
        
    }
    const data3=value(props.data.data3);
    return (
        <div>
            <div className="row">
                <div className="col-md-6">  
                    <InputNumber
                    id="HI"
                    label="Hora de Inicio"
                    value={props.horaInicio}
                    handleToggle={props.handdleHoraInicio}
                    desactivar={props.desactivar}
                    />
                </div>
                <div className="col-md-6">
                    <InputNumber
                    id="CH"
                    label="Cuantas Horas de Día"
                    value={props.cuantasHoras}
                    handleToggle={props.handdleCuantasHoras}
                    desactivar={props.desactivar}
                    />
                </div>
            </div>

            
            <div className="row">
            <h5 className="col-md-12">Tiempo ON/OFF Noche</h5>
                <div className="col-md-6">
                    
                    <InputNumber
                    id="MON"
                    label="Minutos ON"
                    value={data3[0]}
                    handleToggle={props.handdleData30}
                    desactivar={props.desactivar}
                    />
                </div>
                <div className="col-md-6">
                    <InputNumber
                    id="MOFF"
                    label="Minutos OFF"
                    value={data3[1]}
                    handleToggle={props.handdleData31}
                    desactivar={props.desactivar}
                    />
                </div>
            </div>
            
            <h5 className="col-md-12">Gráfica de Dia ON/OFF</h5>
            <DragChart data={props}/>

            


        </div>
    )
}

export default ConfigFan
