import React from 'react'

const InputNumber = (props) => {
    const id="Numb"+props.id;
    //console.log(id)
    return (
        <div className="row">
            <div className="form-group col-6">
                <label htmlFor={id}> {props.label}</label>
            </div>
            <div className="col-6" >
                    
                    <input 
                    className="form-control" 
                    type="number" 
                    min="1" 
                    id={id} 
                    value={props.value}
                    onChange={(e)=>props.handleToggle(e.target)}
                    disabled={props.desactivar? true:false}
                     />
            </div>
            
        </div>
    )
}

export default React.memo(InputNumber)
