import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Chip, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, Select, makeStyles, withStyles } from '@material-ui/core';
import { db } from '../../../../config/firebase';
import { UserContext } from '../../../../context/UserProvider';
import { IrrigationContext } from '../../../../context/IrrigationContext/IrrigationContext';
import { green, grey } from '@material-ui/core/colors';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 300,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
      '&$disabled': {
        color: grey[500],
      },
    },
    checked: {},
    disabled: {},
  })((props) => <Checkbox color="default" {...props} />);

export const ConfigPumpsWater = () => {
    const classes = useStyles();
    const {dataMacCan,usuario,names,currentMac,canIdIrrigation, configIrrigation}=useContext(UserContext);
    const { waterPumps, waterSource, setWaterPumps, setWaterSource,setZonesNames } = useContext(IrrigationContext)
    const [pumpsFlag, setPumpsFlag] = useState(false);
    const [waterFlag, setWaterFlag] = useState(false);

    const handleChangePumps = (event) => {
      setWaterPumps(event.target.value);
      setPumpsFlag(true);
    };

    const handleChangeWater = (event) => {
      setWaterSource(event.target.value);
      setWaterFlag(true);
    }

    useEffect(() => {
      if (configIrrigation.length > 0 && names.length > 0) {
        const pumpsDetected = [];
        const waterDetected = [];
        const zonesDetected = [];
        for (let index = 0; index < configIrrigation.length; index++) {
          switch (configIrrigation[index]) {
            case "pump":
              pumpsDetected.push(names[index])
              break;
            case "waterSource":
              waterDetected.push(names[index])
              break;          
            default:
              zonesDetected.push(names[index])
              break;
          }
        }
        setWaterPumps([...pumpsDetected]);
        setWaterSource([...waterDetected]);
        setZonesNames([...zonesDetected]);
        
       
      }
    }, [configIrrigation,names])
    
    
    useEffect(() => {
      
      const saveInDB = async () => {
        if(currentMac !== "" && canIdIrrigation !== "") {
          const path = `${usuario.username}/infoDevices/${currentMac}/${canIdIrrigation}/configModule`;
          for (let index = 0; index < names.length; index++) {
            const uid = `${currentMac}@${canIdIrrigation}@5@${index}`
            const docRef = db.collection(path).doc(uid);
            let irrigationSetup = "";
            if (waterPumps.includes(names[index])) {
              irrigationSetup = "pump"
            } else if(waterSource.includes(names[index])) {
              irrigationSetup = "waterSource"
            } else {
              irrigationSetup = "zone"
            }
            //console.log("Esto es uid:",uid)
            //console.log("Esto es irrigationSetup:",irrigationSetup)
            await docRef.set({
              irrigationSetup: irrigationSetup
            }, { merge: true });
            
          }

        }
        
        
      }
      if(pumpsFlag || waterFlag) {
        saveInDB();
        setPumpsFlag(false);
        setWaterFlag(false);
      }
        
    }, [pumpsFlag,waterFlag,currentMac,canIdIrrigation,usuario.username])


    useEffect(() => {
      const firstFilter = names?.filter(nombre => !waterPumps.includes(nombre))
      const secondFilter = firstFilter?.filter(nombre => !waterSource.includes(nombre))
      setZonesNames(secondFilter)
    
    }, [names,waterPumps,waterSource,setZonesNames])
    

  return (
    <>
    <div style={{ marginBottom: '15px'}}>    
        <Grid 
        container
        direction="row"
        justifyContent='center'
        alignItems='center'
        spacing={3}
        >
          <Grid item xs={12} sm={6}>
              <Grid
              container
              justifyContent='flex-end'
              alignItems='center'
              >                
                  <FormControl className={classes.formControl}>
                      <InputLabel id="SelectPumps">Bombas añadidas</InputLabel>
                      <Select
                      labelId="SelectPumps"
                      id="pumps"
                      multiple
                      value={waterPumps}
                      onChange={handleChangePumps}
                      input={<Input id="imputPump" />}
                      renderValue={(selected) => (
                          <div className={classes.chips}>
                          {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                          ))}
                          </div>
                      )}
                      MenuProps={MenuProps}
                      
                      > 
                      {names.map((name) => (
                          <MenuItem key={name} value={name} disabled={waterSource.includes(name)}  >
                              <GreenCheckbox 
                              checked={waterPumps.indexOf(name) > -1}
                              disabled={waterSource.includes(name)} />
                              <ListItemText primary={name} />
                          </MenuItem>
                      ))}
                      </Select>
                  </FormControl>
              </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
              <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              >
                  <FormControl className={classes.formControl}>
                      <InputLabel id="label-Water">Fuentes de agua</InputLabel>
                      <Select
                      labelId="Select-waterSource"
                      id="waterSource"
                      multiple
                      value={waterSource}
                      onChange={handleChangeWater}
                      input={<Input id="inputWater" />}
                      renderValue={(selected) => (
                          <div className={classes.chips}>
                          {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                          ))}
                          </div>
                      )}
                      MenuProps={MenuProps}
                      > 
                      {names.map((name) => (
                          <MenuItem key={name} value={name} disabled={waterPumps.includes(name)} >
                              <GreenCheckbox 
                              checked={waterSource.indexOf(name) > -1} 
                              disabled={waterPumps.includes(name)}/>
                              <ListItemText primary={name} />
                          </MenuItem>
                      ))}
                      </Select>
                  </FormControl>
              </Grid>
          </Grid>
            
        </Grid>
    </div>
    </>
  )
}
