import React, { useContext, useEffect, useState } from 'react'
import { MultiCropContext } from '../../../context/MultiCropContext/MultiCropContext'
import { Button, Checkbox, CircularProgress, FormControl, Grid, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField, withStyles } from '@material-ui/core'
import { continuosSensors } from '../../../constants/globalConst';
import { green, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 210,
	  maxWidth: 500,
	},
	chips: {
	  display: 'flex',
	  flexWrap: 'wrap',
	},
	chip: {
	  margin: 2,
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
      '&$disabled': {
        color: grey[500],
      },
    },
    checked: {},
    disabled: {},
})((props) => <Checkbox color="default" {...props} />);

const nodesLoraNames = [
	"Temp. Amb.",
	"Hum. Amb.",
	"CO2",
	"EC Sustr.",
	"pH Sustr.",
	"Temp. Sustr.",
	"Hum. Sustr."
]


export const SelectSensors = ({ indexOfCrop, username, confirmSync, loadingChat }) => {
	const {allTilesTest} = useContext(MultiCropContext)
	const classes = useStyles();
	const [listOfSensors, setListOfSensors] = useState([]);
	const [sensorsSelected, setSensorsSelected] = useState([]);
	const [sensorsValues, setSensorsValues] = useState([]);
	const [loadingParameters, setLoadingParameters] = useState(false);
	const [listOfUids, setListOfUids] = useState([]);

	const handleChangeSensors = (event) => {
		const actualArray = event.target.value;
		setSensorsSelected(event.target.value);
		setSensorsValues(Array(actualArray.length).fill(''));
	};

	const handleSincronizar = async () => {
		setLoadingParameters(true);
		const uidsToSend = []
		for (let index = 0; index < sensorsSelected.length; index++) {
			const uidIndex = listOfSensors.indexOf(sensorsSelected[index]);
			uidsToSend.push(listOfUids[uidIndex])
		}
		const formData = {
		  username: username,
		  //mac: dataMacCan[0].mac,
		  sensors: [...uidsToSend]
		  //deviceType: deviceType
		}
		try{
		  const dataToSend = JSON.stringify(formData);
		  const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/getDataFromFirebase', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: dataToSend,
		  });
		  const jsonData = await response.json();
		  setSensorsValues([...jsonData])
		  confirmSync();

		} catch(error) {
		  console.error('Error:', error);
		}
		setLoadingParameters(false);
	};
	

	useEffect(() => {
		if (allTilesTest.length > 0) {
			const arrayOfTiles = allTilesTest[indexOfCrop];
			const arrayOfSensorsNames = [];
			const arrayOfUids = [];
			for (let index = 1; index < arrayOfTiles.length; index++) {
				arrayOfTiles[index].map((item) => {
					if(continuosSensors.includes(item.kind)) {
						arrayOfSensorsNames.push(item.name)
						arrayOfUids.push(item.uid)
					} else if(item.kind === 'nodeLora') {
						const uidSplited = item.uid.split("@")
						for (let i = 0; i < 7; i++) {
							const nodeName = `Nodo ${uidSplited[2]}-${nodesLoraNames[i]}`;
							const newUid = `nodeLora@${uidSplited[2]}@${nodesLoraNames[i]}`;
							arrayOfSensorsNames.push(nodeName)	
							arrayOfUids.push(newUid)			
						}
					}
				})
			}
			setListOfSensors([...arrayOfSensorsNames])
			setListOfUids([...arrayOfUids])
			setSensorsSelected([])
			setSensorsValues([])
		}
	}, [allTilesTest,indexOfCrop])
	

  return (
	<>
	<div style={{ marginBottom: "15px"}}>
	<Grid container alignItems='center' justifyContent='center'>
        <FormControl className={classes.formControl}>
        <InputLabel id="selec_title">Selecciona los parametros</InputLabel>
        <Select
          labelId="names_of_sensors"
          id="mutiple-checkbox-sensors"
          multiple
          value={sensorsSelected}
          onChange={handleChangeSensors}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
		  disabled={loadingChat}
        >
          {listOfSensors?.map((name) => (
            <MenuItem key={name} value={name}>
              <GreenCheckbox checked={sensorsSelected.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
	</div>

	<div style={{ marginBottom: "10px"}}>
	<Grid container justifyContent="center" alignItems="center" spacing={2}>
		{sensorsSelected?.map((sensor,index) => (
			<Grid item xs={12} sm={6} key={`grid_${index}`}>
				<TextField 
				key={`textFiled_${index}`}
				margin="dense"
				id={`id_${index}`}
				label={sensor}
				value={sensorsValues[index]}
				type="number"
				fullWidth
				InputProps={{
					readOnly: true,
				}}
				variant="outlined"
				multiline={true}
				/>
			</Grid>
		))}
	</Grid>
	</div>

	<div  style={{ marginTop: '15px' }}>
		<Grid container justifyContent='center' alignItems='center'>
		<Button variant="contained" color="primary" onClick={handleSincronizar} 
		disabled={loadingParameters || sensorsSelected.length === 0 || loadingChat} >
			Sincronizar ultimos valores
		</Button>
		{loadingParameters && <CircularProgress size={30} className={classes.buttonProgress} />}
		</Grid>  
    </div>
	</>
  )
}
