import { ALL_KINDS } from "../../../../constants/globalConst";
import { continuosSensors } from "../../../../constants/globalConst";
import { functions } from "../../../../config/firebase";
const getDataSetValues = functions.httpsCallable("getDataSetValues");

const MODULE_TYPE_MASTER = "0";
const MODULE_TYPE_AMBIENTE = "1";
const MODULE_TYPE_AGUA = "2";

const VIDEO_ITEM = "video";
const REMINDER_ITEM = "reminder";

const TOGGLE_POS_AUTO = "2";

const {
  IN_HUMEDAD,
  IN_TEMPERATURA,
  IN_SUSTRATO,
  IN_SWITCH,
  OUT_AC,
  IN_RELOJ,
  OUT_PUMB_DC,
  IN_PH,
  IN_CE,
  IN_TEMP_AGUA,
  OUT_PWM,
  IN_PAR,
  IN_DPV,
  IN_DLI,
  IN_CO2,
} = ALL_KINDS;

const videoData = [
  {
    uid: VIDEO_ITEM,
    kind: VIDEO_ITEM,
  },
];

const reminderData = [
  {
    uid: REMINDER_ITEM,
    kind: REMINDER_ITEM,
  },
];

const getDataComponents = (
  dataConfig,
  dataFromModule,
  toModuleData,
  dataMacCans
) => {
  //getIncialDataEnviroment();

  /*
  C_bool: (4) ["0", "0", "0", "0"]  Switch
  C_float: (4) ["23.7", "26.6", "35.3", "21.8"] //Humedad analogSensor
  D_float: (2) ["38.9", "27.0"] //HyT
  O_bool: (8) ["0", "0", "0", "0", "0", "0", "0", "0"]//Out states
  O_byte: (8) ["0", "0", "0", "0", "0", "0", "0", "0"]//Mode States
  O_float: (8) ["0.2", "0.0", "0.1", "0.0", "0.1", "0.0", "0.1", "0.0"]//Corriente States
  T_byte: (3) ["1", "1", "0"]//A[0]=Tipo de Modulo 1=>Ambiente, A[1]=isOnlineShow 1 manda a Master ESP desde modulo externo,  A[2]= no se usa aún
  Dt:     (2) ["1","1"]
  act: "RT"//Action que manda
  can: 0//Modulo del ms
  user: "gapy1"
  mac:"BC8CE68E0D84"
  */

  let toggles = [];
  let switchs = [];
  let analogSensor = [];
  let date = [];

  dataMacCans.forEach((macItem) => {
    macItem.canType.forEach((canItem) => {
      //console.log(canItem.type)

      const fromModuleActual = dataFromModule.find(
        (i) => i.mac === macItem.mac && i.canid === canItem.can
      );

      toggles = [
        ...toggles,
        ...getDataForToggles(
          dataConfig,
          toModuleData,
          macItem,
          canItem,
          fromModuleActual
        ),
      ];

      switchs = [
        ...switchs,
        ...getDataForSwitch(
          dataConfig,
          toModuleData,
          macItem,
          canItem,
          fromModuleActual
        ),
      ];

      date = [
        ...date,
        ...getDataForDate(
          dataConfig,
          toModuleData,
          macItem,
          canItem,
          fromModuleActual
        ),
      ];

      analogSensor = [
        ...analogSensor,
        ...getDataForAnalogSensor(
          dataConfig,
          toModuleData,
          macItem,
          canItem,
          fromModuleActual
        ),
      ];

      //console.log(analogSensor)
    });
  });

  const allComponents = [
    ...toggles,
    ...switchs,

    ...date,
    ...analogSensor,
    ...videoData,
    ...reminderData,
  ];
  return { toggles, switchs, date, analogSensor, allComponents };
};

const getDataForToggles = (
  dataConfig,
  toModuleData,
  macItem,
  canItem,
  fromModuleActual
) => {
  const toggles = [];
  const MaxNumberOfToggles = 8;
  for (let i = 0; i < MaxNumberOfToggles; i++) {
    let { uid, currentValue, state } = get_Uid_State_CurrenteValue_ForToggle(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    const toggleConfig = dataConfig.find((item) => item.id === uid);

    const break_For_IfToggleNotExists = !!toggleConfig;

    if (break_For_IfToggleNotExists) {
      const getTogglePosition = () => {
        const toggleFound = toModuleData.find((item) => item.id === uid);
        let positionIfNotFoundSetDefault = toggleFound
          ? toggleFound.posicion
          : TOGGLE_POS_AUTO;
        return positionIfNotFoundSetDefault;
      };

      toggles.push({
        name: toggleConfig.item.name,
        outid: toggleConfig.item.outid,
        kind: toggleConfig.item.kind,
        id: toggleConfig.item.id,
        canid: toggleConfig.item.canid,
        mac: toggleConfig.item.mac,

        uid,
        posicion: getTogglePosition(),
        currentValue,
        state,
      });
    }
  }
  return toggles;
};

const getDataForSwitch = (
  dataConfig,
  toModuleData,
  macItem,
  canItem,
  fromModuleActual
) => {
  const switchs = [];
  const maxNumberOfSwitches = 4;

  for (let i = 0; i < maxNumberOfSwitches; i++) {
    let { uid, state } = get_Uid_Sate_ForSwitch(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    const switchConfig = dataConfig.find((i) => i.id === uid);

    const break_For_IfSwitchExists = !!switchConfig;
    if (break_For_IfSwitchExists) {
      switchs.push({
        id: switchConfig.item.id,
        mac: switchConfig.item.mac,
        name: switchConfig.item.name,
        type: switchConfig.item.type,
        kind: switchConfig.item.kind, //Toogle
        canid: switchConfig.item.canid,
        outid: switchConfig.item.outid,

        uid,
        state,
      });
    }
  }

  return switchs;
};

const getDataForDate = (
  dataConfig,
  toModuleData,
  macItem,
  canItem,
  fromModuleActual
) => {
  const date = [];
  let { uid, label1, label2 } = get_Uid_Label_ForDate(
    macItem,
    canItem,
    fromModuleActual
  );
  const dateConfig = dataConfig.find((i) => i.id === uid);

  const break_For_IfDateExists = !!dateConfig;
  if (break_For_IfDateExists) {
    date.push({
      uid,
      name: dateConfig.item.name,
      mac: macItem.mac,
      canid: canItem.can,
      label1,
      label2,
    });
  }

  return date;
};

const getDataForAnalogSensor = (
  dataConfig,
  toModuleData,
  macItem,
  canItem,
  fromModuleActual
) => {
  const analogSensor = [];
  const maxNumberOfAnalogSensorsOfModules = 16;
  //console.log("Analog data config dataConfig", dataConfig);
  for (let i = 0; i < maxNumberOfAnalogSensorsOfModules; i++) {
    let { uid, value } = get_Uid_Value_ForAnalog(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    const sensorNoConfig = dataConfig.find((i) => i.id === uid);
    //console.log("get UID Analog", uid, value, sensorNoConfig);
    const break_For_IfAnalogExists = !!sensorNoConfig;
    if (break_For_IfAnalogExists) {
      analogSensor.push({
        id: sensorNoConfig.item.id,
        mac: sensorNoConfig.item.mac,
        name: sensorNoConfig.item.name,
        canid: sensorNoConfig.item.canid,
        outid: sensorNoConfig.item.outid,
        kind: sensorNoConfig.item.kind,
        uid,
        value,
      });
    }
  }
  return analogSensor;
};

const get_Uid_State_CurrenteValue_ForToggle = (
  macItem,
  canItem,
  fromModuleActual,
  i
) => {
  let uid = null;
  let currentValue = null;
  let state = null;
  const typeOfModule = String(canItem.type);

  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    uid = macItem.mac + "@" + canItem.can + "@" + OUT_AC + "@" + i; //Out AC Fan,
    currentValue = fromModuleActual.O_float[i];
    state = fromModuleActual.O_bool[i];
  } else if (typeOfModule === MODULE_TYPE_AGUA) {
    uid = macItem.mac + "@" + canItem.can + "@" + OUT_PUMB_DC + "@" + i; //Out DC Pumb,
    currentValue = fromModuleActual.O_float[i];
    state = fromModuleActual.O_bool[i];
  } else if (typeOfModule === MODULE_TYPE_MASTER) {
    uid = macItem.mac + "@" + canItem.can + "@" + OUT_PWM + "@" + i; //Out DC Pumb,
    currentValue = 0;
    state = fromModuleActual.D_float[i];
  }

  return { uid, currentValue, state };
};

const get_Uid_Sate_ForSwitch = (macItem, canItem, fromModuleActual, i) => {
  let uid = null;
  let state = null;
  const typeOfModule = String(canItem.type);

  const isAModuleWithSwitches =
    typeOfModule === MODULE_TYPE_AMBIENTE ||
    typeOfModule === MODULE_TYPE_AGUA ||
    typeOfModule === MODULE_TYPE_MASTER;

  if (isAModuleWithSwitches) {
    uid = macItem.mac + "@" + canItem.can + "@" + IN_SWITCH + "@" + i;
    state = fromModuleActual.C_bool[i];
  }
  return { uid, state };
};

const get_Uid_Label_ForDate = (macItem, canItem, fromModuleActual) => {
  let uid = null;
  let label1 = null;
  let label2 = null;
  const typeOfModule = String(canItem.type);

  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    //Si es modulo de Agua lo rompe en 2

    uid = macItem.mac + "@" + canItem.can + "@" + IN_RELOJ + "@0"; //
    label1 = fromModuleActual.Dt[0];
    label2 = fromModuleActual.Dt[1];
  }

  return { uid, label1, label2 };
};

const get_Uid_Value_ForAnalogAmbiente = (
  macItem,
  canItem,
  fromModuleActual,
  i
) => {
  let uid = null;
  let value = null;

  if (i < 4) {
    uid = macItem.mac + "@" + canItem.can + "@" + IN_SUSTRATO + "@" + i;
    value = fromModuleActual.C_float[i];
  }
  if (i === 4) {
    uid = macItem.mac + "@" + canItem.can + "@" + IN_HUMEDAD + "@0";
    value = fromModuleActual.D_float[i];
  }
  if (i === 5) {
    uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMPERATURA + "@1";
    value = fromModuleActual.D_float[i];
  }

  return { uid, value };
};

const get_Uid_Value_ForAnalogAgua = (macItem, canItem, fromModuleActual, i) => {
  let uid = null;
  let value = null;

  switch (i) {
    case 0:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_PH + "@0";
      value = fromModuleActual.C_float[i];
      break;

    case 1:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_CE + "@0";
      value = fromModuleActual.C_float[i];
      break;

    case 2:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMP_AGUA + "@0";
      value = fromModuleActual.C_float[i];
      break;

    default:
      break;
  }

  return { uid, value };
};

const get_Uid_Value_ForAnalogMaster = (
  macItem,
  canItem,
  fromModuleActual,
  i
) => {
  function getDPV(temperature, humidity) {
    const A = -10440.397;
    const B = -11.29465;
    const C = -0.027022355;
    const D = 0.00001289036;
    const E = -0.0000000024780681;
    const F = 6.5459673;
    let Caire = Number(temperature);
    let Cmata = Caire - 2;
    let Taire = (Caire + 273.15) * 1.8;
    let Tmata = (Cmata + 273.15) * 1.8;
    let pvsa = Math.exp(
      A / Taire +
        B +
        C * Taire +
        D * Math.pow(Taire, 2) +
        E * Math.pow(Taire, 3) +
        F * Math.log(Taire)
    );
    let pvsm = Math.exp(
      A / Tmata +
        B +
        C * Tmata +
        D * Math.pow(Tmata, 2) +
        E * Math.pow(Tmata, 3) +
        F * Math.log(Tmata)
    );
    let pva = pvsa * (humidity / 100);
    let dpv1 = pvsm - pva;
    let dpv = dpv1 * 6.894757293168361;
    //console.log({temperature,humidity,Caire,Taire,Tmata,Cmata,pvsa,pvsm,pva,dpv1,dpv1,});
    return Number(dpv).toFixed(3);
  }
  function calculateDli(parValue) {
    let vPar = parValue / 1000000.0;
    let dayLightHours = 12.0 * 3600.0;
    let dli = dayLightHours * vPar;
    return Number(dli).toFixed(3);
  }
  let uid = null;
  let value = null;
  switch (i) {
    case 0:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_HUMEDAD + "@0";
      value = fromModuleActual.O_float[i];
      break;

    case 1:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMPERATURA + "@1";
      value = fromModuleActual.O_float[i];
      break;

    /**
     * case 2: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMPERATURA + "@0";
      value = fromModuleActual.O_float[2];
      break;

    case 3: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMPERATURA + "@0";
      value = fromModuleActual.O_float[3];
      break;

    case 4: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMPERATURA + "@0";
      value = fromModuleActual.O_float[4];
      break;
     */

    case 5:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_PH + "@0";
      value = fromModuleActual.O_float[i];
      break;

    case 6:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_CE + "@0";
      value = fromModuleActual.O_float[i];
      break;

    case 7:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_TEMP_AGUA + "@0";
      value = fromModuleActual.O_float[i];
      break;

    case 8:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_SUSTRATO + "@0";
      value = fromModuleActual.C_float[i];
      break;

    case 9:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_SUSTRATO + "@1";
      value = fromModuleActual.C_float[i];
      break;

    case 10:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_SUSTRATO + "@2";
      value = fromModuleActual.C_float[i];
      break;

    case 11:
      uid = macItem.mac + "@" + canItem.can + "@" + IN_SUSTRATO + "@3";
      value = fromModuleActual.C_float[i];
      break;

    case 12: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_PAR + "@0";
      value = fromModuleActual.O_float[2];
      break;

    case 13: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_DPV + "@0";
      value = getDPV(fromModuleActual.O_float[1], fromModuleActual.O_float[0]);
      break;

    case 14: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_DLI + "@0";
      value = calculateDli(fromModuleActual.O_float[2]);
      break;
    case 15: //Se repite por que aun no se sabe que escoger kind
      uid = macItem.mac + "@" + canItem.can + "@" + IN_CO2 + "@0";
      value = fromModuleActual.O_float[4];
      break;

    default:
      break;
  }

  return { uid, value };
};

const get_Uid_Value_ForAnalog = (macItem, canItem, fromModuleActual, i) => {
  let uid = null;
  let value = null;

  const typeOfModule = String(canItem.type);

  if (typeOfModule === MODULE_TYPE_AMBIENTE) {
    const maxNumberOfAnalogSensorsAmbiente = 6;
    if (i >= maxNumberOfAnalogSensorsAmbiente) {
      return { uid, value };
    }

    const res = get_Uid_Value_ForAnalogAmbiente(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    uid = res.uid;
    value = res.value;
  } else if (typeOfModule === MODULE_TYPE_AGUA) {
    const maxNumberOfAnalogSensorsAgua = 3;
    if (i >= maxNumberOfAnalogSensorsAgua) {
      return { uid, value };
    }

    const res = get_Uid_Value_ForAnalogAgua(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    uid = res.uid;
    value = res.value;
  } else if (typeOfModule === MODULE_TYPE_MASTER) {
    const res = get_Uid_Value_ForAnalogMaster(
      macItem,
      canItem,
      fromModuleActual,
      i
    );
    uid = res.uid;
    value = res.value;
  }

  return { uid, value };
};

const setRender = (dataComponents, render) => {
  const keys = Object.keys(render);
  // ordenamos con respecto a ids y convertimos json a matriz
  const keysOrdered = keys.map((item, i) => render[`col_${i}`]); //ordenamos ids

  //let cnt=0;
  let dataRender = keysOrdered.map((col) => {
    const fils = col.map((fil) => {
      let found;
      //const found=dataComponents.find(item=> item.uid===fil)// ineficiente, que hace loop muchas veces
      for (let i = 0; i < dataComponents.length; i++) {
        //cnt++;
        if (dataComponents[i].uid === fil) {
          //para los que encuentra le mete toda la info
          found = dataComponents[i];
          dataComponents.splice(i, 1); //va eliminando los que ya encontro
          break;
        }
      }

      return found;
    });
    return fils;
  });

  //dataComponents son los elementos que no se encontraron, que significaria que se acaban de agregar
  // de ser asi se mandan a la columna 0
  if (dataRender.length === 0) {
    //en caso de que no exista render no se agrega
    dataRender = [[...dataComponents]];
  } else {
    dataRender[0] = [...dataRender[0], ...dataComponents];
  }

  const dtRender = dataRender.map((col) => col.filter(Boolean)); //Elimana elementos no existentes null,0, false, undefined

  //console.log(getMacsArray(1,"MFCE7E68E0D84","3"))
  return dtRender;
};

const updateData = (state, snap, typeModule) => {
  const firstUid = snap.mac + "@" + snap.canid;

  //Corre por el vector mas largo que es de 8, y va buscando los ids de las salidas o entradas,
  //cuando encuentra todos los ids, corta el for para no buscar en columnas ni filas
  // cuando i es mayor al arreglo por ejemplo C_bool[4] e i=5 entonces las bandera flagCbool=true
  // se pone en true para que no siga buscando ya que al ser un indice que no existe, nuca lo va a encontrar

  let uidToggle;
  let uidSwitch;
  let uidSensorAnalog1;
  let uidSensorAnalog2; //Se agrega por por ejemplo si hay 4 Soil y PH,CE o Wt, todos son analogicos y corren paralelos
  //es decir que se van encontrando al mismo tiempo, por el uid, entonces por eso se tiene que hacer uno extra
  let uidDate;
  let uidSensorHyT;

  let flagSwitch = true; //En falso si quiero componente y en verdadero si no,
  let flagSensorAnalog1 = true;
  let flagSensorAnalog2 = true;
  let flagHyT = true;
  let flagToggle = true;
  let flagDate = true;

  let valueSwitch; //En falso si quiero componente y en verdadero si no,
  let valueSensorAnalog1;
  let valueSensorAnalog2;
  let valueHyT;
  let valueToggleCurrent;
  let valueToggleState;
  let valueDate0;
  let valueDate1;

  for (let i = 0; i < 8; i++) {
    //Switch,analogSensor,
    //console.log(String(typeModule),MODULE_TYPE_AMBIENTE)
    if (String(typeModule) === MODULE_TYPE_AMBIENTE) {
      flagToggle = false; //Si quiero componente
      uidToggle = firstUid + "@" + OUT_AC + "@" + i;
      valueToggleCurrent = snap.O_float[i];
      valueToggleState = snap.O_bool[i];
      if (i >= 8) {
        flagToggle = true;
      }

      uidSwitch = firstUid + "@" + IN_SWITCH + "@" + i;
      flagSwitch = false; //Si quiero componente
      valueSwitch = snap.C_bool[i]; //En falso si quiero componente y en verdadero si no,
      if (i >= 4) {
        flagSwitch = true;
      }

      uidSensorAnalog1 = firstUid + "@" + IN_SUSTRATO + "@" + i;
      flagSensorAnalog1 = false; //Si quiero componente
      valueSensorAnalog1 = snap.C_float[i];
      if (i >= 4) {
        flagSensorAnalog1 = true;
      }

      uidDate = firstUid + "@" + IN_RELOJ + "@0";
      flagDate = false; //Si quiero componente
      valueDate0 = snap.Dt[0];
      valueDate1 = snap.Dt[1];

      if (i < 2) {
        uidSensorHyT = firstUid + "@" + i + "@" + i;
      }
      flagHyT = false; //Si quiero componente
      if (i < 2) {
        valueHyT = snap.D_float[i];
      }
      if (i >= 2) {
        flagHyT = true;
      } //Numero de elementos por los que va a estar buscando
    } else if (String(typeModule) === MODULE_TYPE_AGUA) {
      if (i < 4) {
        uidToggle = firstUid + "@" + OUT_PUMB_DC + "@" + i;
      } //Bombas DC
      if (i < 3) {
        //PH,CE y WTemp
        const I = 7 + i;
        uidSensorAnalog1 = firstUid + "@" + I + "@0"; //   7//8/9 ==>PH/CE/WTemp
      }

      flagSensorAnalog1 = false; //Si quiero componente
      flagToggle = false; //Si quiero componente

      //valueSwitch=snap.C_bool[i];//En falso si quiero componente y en verdadero si no,
      valueSensorAnalog1 = snap.C_float[i];
      //valueHyT=snap.D_float[i];
      //valueToggleCurrent=snap.O_float[i];
      valueToggleState = snap.O_bool[i];
      //valueDate0=snap.Dt[0];
      //valueDate1=snap.Dt[1];

      if (i >= 4) {
        flagToggle = true;
      }
      if (i >= 3) {
        flagSensorAnalog1 = true;
      } //Numero de elementos por los que va a estar buscando
    } else if (String(typeModule) === MODULE_TYPE_MASTER) {
      uidToggle = firstUid + "@" + OUT_PWM + "@" + i;
      flagToggle = false; //Si quiero componente
      valueToggleState = snap.O_byte[i];
      if (i >= 2) {
        flagToggle = true;
      } //Numero de elementos por los que va a estar buscando

      uidSwitch = firstUid + "@" + IN_SWITCH + "@" + i;
      flagSwitch = false; //Si quiero componente
      valueSwitch = snap.C_bool[i]; //En falso si quiero componente y en verdadero si no,
      if (i >= 4) {
        flagSwitch = true;
      }

      uidSensorAnalog1 = firstUid + "@" + IN_SUSTRATO + "@" + i;
      flagSensorAnalog1 = false; //Si quiero componente
      valueSensorAnalog1 = snap.C_float[i];
      if (i >= 4) {
        flagSensorAnalog1 = true;
      }

      if (i === 0 || i === 1) {
        //Sensor IC2 uno  H y T
        uidSensorAnalog2 = firstUid + "@" + i + "@" + i;
      } else if (i === 2 || i === 3 || i === 4) {
        //Sensor IC2 Dos como aun no
        //Como aun no sabemos no se pone
        uidSensorAnalog2 = "NotYet";
      } else if (i === 5) {
        //CE
        uidSensorAnalog2 = firstUid + "@" + IN_PH + "@0"; //   7//8/9 ==>PH/CE/WTemp
      } else if (i === 6) {
        //CE
        uidSensorAnalog2 = firstUid + "@" + IN_CE + "@0"; //   7//8/9 ==>PH/CE/WTemp
      } else if (i === 7) {
        //CE
        uidSensorAnalog2 = firstUid + "@" + IN_TEMP_AGUA + "@0"; //   7//8/9 ==>PH/CE/WTemp
      }
      valueSensorAnalog2 = snap.O_float[i];
      flagSensorAnalog2 = false; //Si quiero componente
      if (i >= 8) {
        flagSensorAnalog2 = true;
      }
    }

    //console.log("Buscando: ",uid)

    for (let col = 0; col < state.length; col++) {
      //col

      if (
        flagSwitch === true &&
        flagSensorAnalog1 === true &&
        flagSensorAnalog2 === true &&
        flagHyT === true &&
        flagToggle === true &&
        flagDate === true
      ) {
        break;
      } //rompe el for ante de avanzar
      //console.log(flagToggle,i)

      for (let fil = 0; fil < state[col].length; fil++) {
        //filas

        if (state[col][fil].uid === uidSwitch) {
          //console.log(`Encontrado en Col: ${col} Fil: ${fil} id: ${uidSwitch}`)

          state[col][fil].state = valueSwitch;
          flagSwitch = true;
        }

        if (state[col][fil].uid === uidSensorAnalog1) {
          //console.log(`Encontrado en Col: ${col} Fil: ${fil} id: ${uidSensorAnalog1}`)

          state[col][fil].value = valueSensorAnalog1;
          flagSensorAnalog1 = true;
        }

        if (state[col][fil].uid === uidSensorAnalog2) {
          //console.log(`Encontrado en Col: ${col} Fil: ${fil} id: ${uidSensorAnalog1}`)

          state[col][fil].value = valueSensorAnalog2;
          flagSensorAnalog2 = true;
        }

        if (state[col][fil].uid === uidSensorHyT) {
          state[col][fil].value = valueHyT;
          flagHyT = true;
        }

        if (state[col][fil].uid === uidToggle) {
          //console.log(`Encontrado en Col: ${col} Fil: ${fil} id: ${uidToggle}`)
          state[col][fil].currentValue = valueToggleCurrent;
          state[col][fil].state = valueToggleState;
          flagToggle = true;
        }
        if (state[col][fil].uid === uidDate) {
          state[col][fil].label1 = valueDate0;
          state[col][fil].label2 = valueDate1;
          flagDate = true;
        }

        if (
          flagSwitch === true &&
          flagSensorAnalog1 === true &&
          flagSensorAnalog2 === true &&
          flagHyT === true &&
          flagToggle === true &&
          flagDate === true
        ) {
          //Cuando ya encontro todos, ya no continua buscando en columnas ni filas
          //console.log(`..Encontrado en Col: ${col}, salid ${i}`)
          break;
        }

        //console.log("Sigue Corrinedo ",col)
      }
    }
  }

  //console.log("state Finish",state)

  return state;
};

const getarrGraphData = async (allComponenentsOrdered, username) => {
  const getDataFromDB = async (uid) => {
    try {
      const user = username;
      const values = await getDataSetValues({ user: user, uid: uid });

      let data = values.data.result[0];
      let valArray = [];
      let arr = [];
      for (let i in data) {
        let newEntry = [Date.parse(data[i].timestamp.value), data[i].val];
        valArray.push(newEntry);
        arr.push(data[i].val);
      }
      let minVal = Math.min(...arr);
      let maxVal = Math.max(...arr);
      return { valArray, minVal, maxVal };
    } catch (error) {
      let valArray = [];
      let minVal = -123;
      let maxVal = 123;
      return { valArray, minVal, maxVal };
    }
  };
  let newAllComponenentsOrdered = [];
  const auxData = allComponenentsOrdered.map(async (eachElement, indx) => {
    const auxallComponents = eachElement.map(async (item, indx) => {
      if (continuosSensors.includes(item.kind)) {
        //console.log("getarrGraphData: ", item);
        const { valArray, minVal, maxVal } = await getDataFromDB(item.uid);
        item["arrayGraph"] = valArray;
        item["minValGrap"] = minVal;
        item["maxValGrap"] = maxVal;
      }
      return item;
    });
    const allComponentsWGraph = await Promise.all(auxallComponents);
    newAllComponenentsOrdered[indx] = allComponentsWGraph;
    return allComponentsWGraph;
  });
  const esperaSalida = await Promise.all(auxData);
  return newAllComponenentsOrdered;
};

export {
  getDataComponents,
  updateData,
  setRender,
  getarrGraphData,
  VIDEO_ITEM,
  REMINDER_ITEM,
};
