import React from 'react';
import "./SwitchT.css"

const SwitchT = ({ isOn, id }) => {
  //console.log(id)
  const state=isOn==="1"?true:false
  return (
    <>
      <input
        checked={state}
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        disabled
      />
      <label
        style={{ background: state && '#06D6A0' }}
        className="react-switch-label"
        htmlFor={id}
      >
        <span 
        className={`react-switch-button`} 
        />
      </label>
    </>
  );
};

export default React.memo(SwitchT);
