import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

//import IconButtonTimelapse from "./IconButtonTimelapse";
import IconButtonCamera from "./IconButtonCamera";
import IconButtonInfoPhotoDay from "./IconButtonInfoPhotoDay";

const useStyles = makeStyles((theme) => ({
  grid: { maxWidth: "100%", margin: 0 },
  icon: {
    color: "#fff",
    strokeWidth: "0.5",
    stroke: "grey",
  },
}));

const BarTileIconsGridContent = ({ tile }) => {
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={1} className={styles.grid}>
        <IconButtonInfoPhotoDay classNameIcon={styles.icon} />
      </Grid>

    
      <Grid item xs={1} className={styles.grid}>
        <IconButtonCamera
          classNameIcon={styles.icon}
          nameCrop={tile.id}
          photoURL={tile.photoURL}
        />
      </Grid>
    </Grid>
  );
};

const BarTileIconsGridTimelapse = ({ tile }) => {
  return <BarTileIconsGridContent tile={tile} />;
};

export default BarTileIconsGridTimelapse;
