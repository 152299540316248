import React from 'react'
import { withRouter } from 'react-router-dom';

import logo from "../../assets/icons/02_logo_41x128.ico";
import { ALL_PATHS } from '../../constants/routerConst';

const Logo = ({className,...props}) => {

    const onClickImg=()=>{
        
        const {PATH_INDEX}=ALL_PATHS
        props.history.push(PATH_INDEX);
      }

    return (
        <div className={className} >
            
            <img 
            src={logo} alt="GAPY icon" width="128px" height="41px"
            style={{
                cursor:"pointer"
            }}
            onClick={onClickImg}
            />
        </div>
    )
}

export default withRouter(Logo)
