import React from 'react'
import Tree from 'react-d3-tree';
import {db} from '../../../config/firebase'
import {withRouter} from 'react-router-dom'
import svgMasterImg from '../../../assets/master1.png'
import svgNodeImg from '../../../assets/node.png'


import svgInImg from '../../../assets/in.png'
import NodeConfig from '../../../assets/NodeConfig.png'

import HumedadImg from '../../../assets/humedad.png'
import TempImg from '../../../assets/temp.png'
import SoilImg from '../../../assets/soil.png'
import SwitchImg from '../../../assets/switch.png'
import svgOutImg from '../../../assets/out.png'
import ClockImg from '../../../assets/clock.png'


import PhSensorImg from '../../../assets/phSensor.png'
import CeSensorImg from '../../../assets/ceSensor.png'
import PumbImg from '../../../assets/pumb.png'
import WTempImg from '../../../assets/WTemp.png'

import AmbienteImg from '../../../assets/Ambiente.png'
import AguaImg from '../../../assets/Agua.png'

import {UserContext} from '../../../context/UserProvider'

import {ALL_PATHS} from '../../../constants/routerConst'

const containerStyles = {
  width: '100%',
  height: '80vh',
  background:"azure"
}




const svgKindIO=(kind)=>{
  let svg={ 
    shape: 'image', 
    shapeProps: { href:svgInImg,
                  width: 60,
                  height: 60, 
                  x: -10,
                  y: -30,
                }
    } ;

   

  if(kind==="0"){svg.shapeProps.href=HumedadImg}
  else if(kind==="1"){svg.shapeProps.href=TempImg}
  else if(kind==="2"){svg.shapeProps.href=SoilImg}
  else if(kind==="3"){svg.shapeProps.href=SwitchImg}
  else if(kind==="5"){svg.shapeProps.href=svgOutImg}
  else if(kind==="D"){svg.shapeProps.href=ClockImg}

  else if(kind==="6"){svg.shapeProps.href=PumbImg}
  else if(kind==="7"){svg.shapeProps.href=PhSensorImg}
  else if(kind==="8"){svg.shapeProps.href=CeSensorImg}
  else if(kind==="9"){svg.shapeProps.href=WTempImg}
  

  return svg
}

const svgTypeModule=(type)=>{
  console.log(type==="1")
  let svg = { 
    shape: 'image', 
    shapeProps: { href:svgNodeImg,
                  width: 60,
                  height: 60, 
                  x: -10,
                  y: -30,
                }
    } 
  
  
  if(type==="1"){svg.shapeProps.href=AmbienteImg}
  else if(type==="2"){svg.shapeProps.href=AguaImg}

  return svg
}

const svgMaster = { 
  shape: 'image', 
  shapeProps: { href:svgMasterImg,
                width: 60,
                height: 60, 
                x: -10,
                y: -30,
              }
  } 

  const svgNodeConfig = { 
    shape: 'image', 
    shapeProps: { href:NodeConfig,
                  width: 60,
                  height: 60, 
                  x: -10,
                  y: -30,
                }
    } 

const TreeChart = (props) => {
    
    //const [username,setUsername]=React.useState("")
//    const [macCan,setMacCan]=React.useState([])
    const [data,setData]=React.useState([])
    const {usuario,dataMacCan}=React.useContext(UserContext)
    //const [iodata,setIodata]=React.useState([])
   
    


    React.useEffect(()=>{
      const obtenerDatos=async()=>{
       try{

        /*
         const getSubCollections = functions.httpsCallable('getSubCollections');
         const listColl=await getSubCollections({ docPath:  `${usuario.username}/infoDevices` })
         const vmac=listColl.data.collections;
         */


         
         if(dataMacCan.length>0){
 
           let debugData1=[]
           await Promise.all(
           dataMacCan.map(async(itemMac)=>{

              let modulos=[]
                 await Promise.all(

                  itemMac.cans.map( async(itemCan)=>{


                    const addr=usuario.username+'/infoDevices/'+itemMac.mac+'/'+itemCan.id+'/configModule'
                    
                    const data=await db.collection(addr).get();
                    const dataConfig=data.docs.map(doc=>( { id: doc.id,...doc.data()} ))
                    

                    //const _IO=dataConfig.map(item=>({ key: item.id,name:item.item.name,nodeSvgShape: item.item.kind==="5"?svgOut:svgIn, }))
                    const _IO=dataConfig.map(item=>({ key: item.id,name:item.item.name,nodeSvgShape:svgKindIO(item.item.kind), }))
                    
                    let IO=_IO.filter(item=>item.key!=="sendConfig")
                    
                    
                    const keyConfig=itemCan.name? itemCan.name:`Node_${itemCan.id}`

                    IO=[{key: `${itemMac.mac}@${itemCan.id}@N`,name:keyConfig,nodeSvgShape:svgNodeConfig, },...IO]//Se agrega objeto Configuración
                    modulos=[
                      ...modulos,
                      {
                        key:keyConfig,
                        name:keyConfig,
                        nodeSvgShape: svgTypeModule(itemCan.type),// svgNode,
                        children:IO
                      }
                     ];

                     
                   })

                 )
             
           
             debugData1=[
               ...debugData1,
               {
                 key:itemMac.mac,
                 name:itemMac.mac,
                 nodeSvgShape: svgMaster,
                 children:modulos
               },
             ];
             

            })
            )
        
        
        //setIodata(dataConfiguracion)
        setData(debugData1)
        console.log("Tree init1",dataMacCan)
        
         
       }
       }catch(error){
         console.log(error)
       }
     }
     obtenerDatos()
       
       
     },[usuario.username,dataMacCan]);

    
    
    const stateRef=React.useRef(null);
    const [translate,setTranslate]=React.useState()
    
    
    React.useEffect(()=>{
      if(stateRef!==null){
        const dimensions=stateRef.current.getBoundingClientRect();
        setTranslate({
          x: dimensions.width / 7,
          y: dimensions.height / 2
        })
      }
      
      
    },[])

    

    const pushLink=(e)=>{
      //console.log(e.key)
      //ALL_PATHS
      const route=`${ALL_PATHS.PATH_CONFIG}/Tree@${e.key}`
      if(e._children===undefined){props.history.push(route)}
    }

    
    /*
    const onMouse=(e)=>{
      if(e._children!==undefined){return}
      console.log(e)
    }*/
    

    return (
        <div className="container mt-3">

          

          <div style={containerStyles} ref={stateRef} className="row">
            
                
                
            {data.length!==0&&(
             
              data.map((item,i)=>(
                <div className="col-md border border-dark mx-2" key={i}>

                    
                    <Tree   
                      data={item}
                      translate={translate}
                      orientation={"horizontal"}
                      initialDepth={0}
                      nodeSize={{x: 180, y: 90}}
                      onClick={e=>pushLink(e)}
                      textLayout={{
                        x:10,
                        y:40
                      }}
                      //onMouseOver={e=>onMouse(e)}
                    />
                </div>
                    
              ))
              
            )
            
            }
            
          </div>
            

            
        </div>
    )
}

export default withRouter(TreeChart)
export {TreeChart}
