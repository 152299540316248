import React from 'react'
import DragChart from '../ConfigComponents/DragChart'
import InputNumber from '../ConfigComponents/InputNumber';

const ConfigHumni = (props) => {

    return (
        <div>
            
                
            <div className="row">
                <div className="col-md-6">
                    
                    <InputNumber
                    id="HI"
                    label="Hora de Inicio"
                    value={props.horaInicio}
                    handleToggle={props.handdleHoraInicio}
                    desactivar={props.desactivar}
                    />
                </div>
                <div className="col-md-6">
                    <InputNumber
                    id="CH"
                    label="Cuantas Horas de Día"
                    value={props.cuantasHoras}
                    handleToggle={props.handdleCuantasHoras}
                    desactivar={props.desactivar}
                    />
                </div>
            </div>

               
            
            <h3>{props.head}</h3>
            <DragChart data={props}/>
        </div>
    )
}

export default ConfigHumni
