import React from "react";

import ImageToEditDialogApp from "../ImageToEditDialogApp/ImageToEditDialogApp";
import { db, storage } from "../../../config/firebase";
import { AvatarApp } from "../AvatarApp";

const EditPhotoDialog = ({
  open,
  setOpen,
  photoURL,

  photosData,
  setPhotosData,
  addr,
  namePhoto,
  localPhotoAddr,
  docFirestore,
  dialogTitle,
  dialogContentText,
  deleteLabel,
  editLabel,
  deleteAllItem,
}) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const editPhotoStorage = async (image) => {
    const imagenRef = await storage.ref().child(addr).child(namePhoto);
    await imagenRef.put(image);

    const imagenURL = await imagenRef.getDownloadURL();
    return imagenURL;
  };

  const editPhotoFireStore = async (imagenURL) => {
    await db.collection(addr).doc(docFirestore).update({ photoURL: imagenURL });
  };

  const editPhotoPage = (imagenURL) => {
    const clone = photosData.map((item) =>
      item.id === namePhoto ? { ...item, photoURL: imagenURL } : item
    );
    setPhotosData(clone);
    localStorage.setItem(localPhotoAddr, JSON.stringify(clone));
  };

  const editPhoto = async (image) => {
    try {
      setLoading(true);
      const imagenURL = await editPhotoStorage(image);
      await editPhotoFireStore(imagenURL);
      editPhotoPage(imagenURL);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message_);
      setLoading(false);
    }
  };

  const selectFile = async (image) => {
    const imageClient = image.target.files[0];

    if (imageClient === undefined) {
      setError("No se selecciono archivo");
      console.log("No se selecciono");
      return;
    }
    if (imageClient.type === "image/png" || imageClient.type === "image/jpeg") {
      await editPhoto(imageClient);
      setError(false);
    } else {
      setError("Archivo no compatible");
    }
  };

  const deletePhotoStorage = async () => {
    try {
      const imagenRef = await storage.ref().child(addr).child(namePhoto);
      await imagenRef.delete();
    } catch (error) {
      console.log(error.message_);
    }
  };

  const deleteJustFireStore = async () => {
    if (deleteAllItem) {
      await db.collection(addr).doc(docFirestore).delete();
      return 
    } 
      await db.collection(addr).doc(docFirestore).update({ photoURL: null });
    
  };

  const deletePhotoPage = () => {

    if (deleteAllItem) {
      const clone = photosData.filter((item) => item.id !== namePhoto);
      return clone;
    }

    const clone = photosData.map((item) =>
      item.id === namePhoto ? { ...item, photoURL: null } : item
    );
    return clone;
  };

  const setLocalPhotoData = (clone) => {
    setPhotosData(clone);
    localStorage.setItem(localPhotoAddr, JSON.stringify(clone));
  };

  const deletePhoto = async () => {
    try {
      setLoading(true);

      await deletePhotoStorage();
      await deleteJustFireStore();
      const clone = deletePhotoPage();
      setLocalPhotoData(clone);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message_);
      setLoading(false);
    }
  };

  return (
    <ImageToEditDialogApp
      open={open}
      setOpen={setOpen}
      ImgComponent={
        <AvatarApp
          large
          title={namePhoto}
          src={photoURL}
          label={namePhoto.charAt(0).toUpperCase()}
        />
      }
      dialogTitle={dialogTitle}
      dialogContentText={dialogContentText}
      deletePhoto={deletePhoto}
      selectFile={selectFile}
      error={error}
      loading={loading}
      deleteLabel={deleteLabel}
      editLabel={editLabel}
    />
  );
};

export default EditPhotoDialog;
