import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
  },
}));

const BarTileInfoGridContent = ({tile}) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        container
      >
        <Grid item xs={12} className={classes.item}>
         {tile.date}
        </Grid>

        <Grid item xs={6} className={classes.item}>
          Día: {tile.day}
        </Grid>
        <Grid item xs={6} className={classes.item}>
          Semana: {tile.week}
        </Grid>
      </Grid>

    </>
  );
};

const BarTileInfoGridTimelapse = ({tile}) => {
  return (
    <>
      <BarTileInfoGridContent tile={tile}/>
    </>
  );
};

export default BarTileInfoGridTimelapse;
