import React from "react";

import { withRouter } from "react-router-dom";

import { AppBar, makeStyles, Toolbar, Hidden } from "@material-ui/core";
import Logo from "../Logo";
import { drawerWidth } from "../../../constants/NavBarConst";
import { UserContext } from "../../../context/UserProvider";
import NavPrivateLinks from "./NavPrivateLinks";

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: "#fff",
  },

  appBarNoUser: {
    background: "#fff",
  },
}));

const LogoNavbar = ({ isUserLogIn }) => {
  return isUserLogIn ? (
    <Hidden lgUp>
      <Logo />
    </Hidden>
  ) : (<Logo />);
};

const Nabvar= ({ onClickDynamicBox }) => {
  const classes = useStyles();
  const { usuario } = React.useContext(UserContext);

  return (
    <AppBar className={usuario.estado ? classes.appBar : classes.appBarNoUser}>
      <Toolbar>
        <LogoNavbar isUserLogIn={usuario.estado} />

        {usuario.estado !== false && (
          <NavPrivateLinks onClickDynamicBox={onClickDynamicBox} />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Nabvar);
