import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import interactionPlugin from "@fullcalendar/interaction";
import Resizer from "react-image-file-resizer";
//Firebase
import { db, storage } from "../../../config/firebase";
import { DialogCalendar } from "../../Common";
import { UserContext } from "../../../context/UserProvider";
import {
  COLORES,
  getIconComponent,
} from "../../Common/CalendarComponents/svgIconEvents";

function getResizeImage(file, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
}

const Calendar = () => {
  const { usuario } = React.useContext(UserContext);

  const [estado, setEstado] = useState({
    weekendsVisible: true,
    infoEvent: null,
    calendarApi: null,
  });
  const [values, setValues] = useState({
    color: "#000000",
    title: "",
    start: "",
    end: "",
    currentSelect: null,
    id: null,
    photos: [],
    deletePhoto: [],
    extendedProps: {
      typeEvent: "Other",
      description: "",
      storagePaths: [],
    },
  });
  const [eventos, setEventos] = useState(null);
  const [error, setError] = useState({
    titleError: "",
    dateError: "",
    rangeError: "",
  });
  const [modalData, setModalData] = useState({
    showModal: false,
    showModalDelete: false,
    showModalSave: false,
    titleModal: "Agregar evento",
  });
  const [showDiv, setShowDiv] = useState(true);
  //Funciones
  //Obtener eventos desde firebase
  async function readData() {
    try {
      const add = usuario.username + "/Keeps/CalendarEvents";
      const data = await db.collection(add).get();
      const arrayData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // let cloneEventos = {
      //   fireEvents: arrayData,
      //   currentEvents: arrayData,
      //   initialEvents: arrayData,
      // };
      //Ajustar fecha del fin de evento
      if (arrayData !== null) {
        for (let e of arrayData) {
          let enddate = new Date(e.end);
          enddate.setDate(enddate.getDate());
          e.end = enddate.toISOString().split("T")[0];
        }
      }
      console.log({ msg: "Get Firebase Data", arrayData });
      setEventos(arrayData);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function addPhotosToStorage(photosArr) {
    if (photosArr.length > 0) {
      const storagePaths = photosArr.map(async (file) => {
        if (file != null) {
          const storageRef = storage.ref();
          const timestamp = Date.now().toString(36);
          const fileName = `${usuario.username}/Keeps/CalendarEvents/${timestamp}_${file.name}`;
          const photoRef = storageRef.child(fileName);
          const resizedImage = await getResizeImage(file, 1280, 720);
          console.log({ resizedImage, photoRef });
          await photoRef.put(resizedImage);
          const uniqueStoragePath = await photoRef.getDownloadURL();
          return uniqueStoragePath;
        } else {
          return null;
        }
      });
      const awaitPaths = await Promise.all(storagePaths);
      const result = awaitPaths.filter((element) => {
        return element !== null && element !== undefined;
      });
      return result;
    } else {
      return [];
    }
  }
  async function deletePhotoOnStorage({
    deleteStoragePath,
    actualStoragePaths,
  }) {
    // Delete image function
    if (deleteStoragePath != undefined && deleteStoragePath.length > 0) {
      let auxArrayPaths = [...actualStoragePaths];
      deleteStoragePath.forEach(async (storagePath) => {
        try {
          auxArrayPaths = auxArrayPaths.filter((item) => {
            return item.localeCompare(storagePath) != 0;
          });
          // Get a reference to the image in Firebase Storage
          const storageRef = storage.refFromURL(storagePath);
          // Delete the image
          await storageRef.delete();
          // Image successfully deleted
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            // Image not found in Firebase Storage
            console.log("Image not found in Firebase Storage.");
          } else {
            // Other error occurred while deleting the image
            console.error("Error deleting image:", error);
          }
        }
      });
      return auxArrayPaths;
    } else {
      return [...actualStoragePaths];
    }
  }
  //updatingDB firebase
  async function updatingDB(id) {
    try {
      const storageNewPaths = await addPhotosToStorage(values.photos);
      const editedStoragePaths = await deletePhotoOnStorage({
        deleteStoragePath: values.deletePhoto,
        actualStoragePaths:
          values.extendedProps.storagePaths == undefined
            ? []
            : values.extendedProps.storagePaths,
      });
      const add = usuario.username + "/Keeps/CalendarEvents";
      const objToUpdate = {
        title: values.title,
        color: values.color,
        end: values.end,
        start: values.start,
        extendedProps: {
          ...values.extendedProps,
          storagePaths: [...editedStoragePaths, ...storageNewPaths],
        },
        id,
      };
      await db.collection(add).doc(id).update(objToUpdate);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateDBonDrag(eventInfo) {
    try {
      const coleccion = usuario.username + "/Keeps/CalendarEvents";
      await db.collection(coleccion).doc(eventInfo.id).update(eventInfo);
    } catch (error) {
      console.log(error);
    }
  }

  async function addToDB() {
    try {
      const storagePaths = await addPhotosToStorage(values.photos);
      const add = usuario.username + "/Keeps/CalendarEvents";
      const res = await db.collection(add).add({
        title: values.title,
        color: values.color,
        end: values.end,
        start: values.start,
        extendedProps: { ...values.extendedProps, storagePaths: storagePaths },
      });
      return res.id;
    } catch (error) {
      console.log(error);
    }
  }

  //Eliminar eventos en firebase
  async function remove(id) {
    try {
      const add = usuario.username + "/Keeps/CalendarEvents";
      await db.collection(add).doc(id).delete();
    } catch (error) {
      console.log(error);
    }
  }

  //Cuando cambia el dropdown, text,date del Modal
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "event") {
      const cloneValue = {
        ...values,
        ["color"]: COLORES[value],
        extendedProps: {
          ...values.extendedProps,
          typeEvent: value,
        },
      };
      setValues(cloneValue);
    } else if (name == "description") {
      const cloneValue = {
        ...values,
        extendedProps: {
          ...values.extendedProps,
          description: value,
        },
      };
      setValues(cloneValue);
    } else if (name == "deletePhoto") {
      const cloneValue = {
        ...values,
        [name]: [...values[name], ...value],
      };
      setValues(cloneValue);
    } else if (name != "event") {
      const cloneValue = {
        ...values,
        [name]: value,
      };
      setValues(cloneValue);
    }
  };

  //Validación
  const validate = () => {
    let titleError = "";
    let dateError = "";
    let rangeError = "";

    if (values.start >= values.end) {
      rangeError = "Seleccione una fecha mayor a la fecha seleccionada";
    }

    if (!values.title) {
      titleError = "Ingrese el titulo del evento";
    }

    if (!values.end) {
      dateError = "Seleccione una fecha final";
    }
    if (!values.start) {
      rangeError = "Seleccione una fecha inicial";
    }

    if (titleError || dateError || rangeError) {
      setError({
        titleError,
        dateError,
        rangeError,
      });
      return false;
    } else return true;
  };

  const sumDayToDate = (date, day) => {
    let startDate = new Date(date);
    startDate.setDate(startDate.getDate() + day);
    return startDate.toISOString().split("T")[0];
  };

  const getAllDayIfEditOrNew = () => {
    if (modalData.titleModal === "Editar evento") {
      return estado.infoEvent.event.allDay;
    }
    return values.currentSelect.allDay;
  };

  const updatingCalendar = (id) => {
    estado.calendarApi.addEvent({
      //id: createEventId(),
      id,
      title: values.title,
      color: values.color,
      start: values.start,
      end: values.end,
      extendedProps: values.extendedProps,
      allDay: getAllDayIfEditOrNew(),
    });
  };

  const setCalendarApiWhenNullOnEdit = () => {
    if (estado.calendarApi == null) {
      estado.calendarApi = estado.infoEvent.view.calendar;
    }
  };

  const setCalendarApiWhenNullOnAdd = () => {
    if (estado.calendarApi == null) {
      estado.calendarApi = values.currentSelect.view.calendar;
    }
  };

  const handleSubmit = async () => {
    /**Validación de los campos */
    setModalData({
      ...modalData,
      showSubmitButton: true,
    });
    const isValid = validate();

    if (isValid) {
      if (modalData.titleModal === "Editar evento") {
        setCalendarApiWhenNullOnEdit();
        await updatingDB(estado.infoEvent.event.id);
        updatingCalendar(estado.infoEvent.event.id);
        estado.infoEvent.event.remove();
      } else {
        setCalendarApiWhenNullOnAdd();
        const id = await addToDB();
        updatingCalendar(id);
      }

      await readData();
      setModalData({
        ...modalData,
        showModal: false,
        showSubmitButton: false,
      });
      setValues({
        ...values,
        color: "#000000",
        title: "",
        start: "",
        end: "",
        photos: [],
        deletePhoto: [],
        extendedProps: {
          typeEvent: "Other",
          description: "",
          storagePaths: [],
        },
      });
    }
  };

  const handleClosed = () => {
    if (modalData.titleModal === "Editar evento") {
      setShowDiv(true);
    }

    setError({
      titleError: "",
      dateError: "",
      rangeError: "",
    });
    setModalData({
      ...modalData,
      titleModal: "Agregar evento",
      showModal: false,
      showSubmitButton: false,
    });
    setValues({
      ...values,
      color: "#000000",
      title: "",
      start: "",
      end: "",
      photos: [],
      deletePhoto: [],
      extendedProps: {
        typeEvent: "Other",
        description: "",
        storagePaths: [],
      },
    });
  };

  const handleDelete = async () => {
    await remove(estado.infoEvent.event.id);
    estado.infoEvent.event.remove();
    handleClosed();
  };

  const handleDateSelectDefaultValue = (selectInfo) => {
    setValues({
      ...values,
      title: "",
      currentSelect: selectInfo,
      start: selectInfo.startStr,
      end: sumDayToDate(selectInfo.startStr, 1),
      extendedProps: {
        typeEvent: "Other",
        description: "",
        storagePaths: [],
      },
    });
    setModalData({
      ...modalData,
      showModal: true,
      showSubmitButton: false,
    });

    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
  };

  const handleEventClickOnEdit = (clickInfo) => {
    setModalData({
      ...modalData,
      titleModal: "Editar evento",
      showModal: true,
    });
    setEstado({
      ...estado,
      infoEvent: clickInfo,
    });
    setValues({
      ...values,
      title: clickInfo.event.title,
      color: clickInfo.event.backgroundColor,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      extendedProps: clickInfo.event.extendedProps,
    });
    setShowDiv(false);
  };

  const handleEvents = (events) => {
    console.log({ events });
    setEventos(events);
  };

  const handleDrag = async (info) => {
    let data = {
      title: info.event.title,
      color: info.event.backgroundColor,
      end: info.event.endStr,
      start: info.event.startStr,
      id: info.event.id,
    };
    await updateDBonDrag(data);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <span>
          {getIconComponent(eventInfo.event.extendedProps.typeEvent, false)}
        </span>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  useEffect(() => {
    readData();
  }, [usuario.username]);

  return (
    eventos && (
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          themeSystem={"standard"}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          locale={esLocale} //cambiar idioma del calendario
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={estado.weekendsVisible}
          initialEvents={eventos}
          select={handleDateSelectDefaultValue}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClickOnEdit}
          eventsSet={handleEvents}
          eventDrop={handleDrag}
          eventResize={handleDrag}
          height={"700px"}
        />

        <DialogCalendar
          modalData={modalData}
          handleClosed={handleClosed}
          values={values}
          handleChange={handleChange}
          error={error}
          handleDelete={handleDelete}
          showDiv={showDiv}
          handleSubmit={handleSubmit}
          flgSubmit={modalData.showSubmitButton}
        />
      </div>
    )
  );
};

export default Calendar;
export { Calendar };
