import React from 'react'
import DosSelect from '../ConfigComponents/DosSelect'

const ConfigAlways = (props) => {
    
    //const value=props.data.data1[0];
    //console.log("props",props.data.data.data1)
    
    const data1=props.data.data1;
    const valueF=(data1)=>{
        if(data1 ===undefined){return "0"}
        else {
            return data1[0]
        }
        
    };
    const value=valueF(data1)
    
    //const value=props.data.data.data1[0];
    //const value=0
    //console.log("data1",value)
    return (
        <div>
            <div >
                    <div className="row">
                        <div className="col-md-12">
                        <h5>Always ON/OFF</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DosSelect
                            id="0"
                            label="Always"
                            label0="OFF"
                            label1="ON"
                            value={value}
                            handleToggle={props.handleToggle}
                            />

                        </div>
                        
                    </div>
                    
                </div>
        </div>
    )
}

export default ConfigAlways
