import React from "react";

import {
  TextField,
  DialogContent,
  Divider,
  Grid,
  DialogContentText,
  Checkbox,
} from "@material-ui/core";

import {
  ResponsiveDateTimePickers,
  RadioButtons,
  RowInput,
  MultiSelectMui,
} from "../../../../Common";

import { MeasureContext } from "../../../../../context/MeasureProvider";

const RADIO_VAL_EXIST = "0";
const RADIO_VAL_NEW = "1";
const RADIO_VAL_DELETE = "2";

const BodyDialog = ({
  handdleDataForm,
  handleDateChange,
  dataRadios,
  handleChangeRadio,
  selectedRadio,
  handleFromDateChange,
  fromDateDelete,
  checkedEraseAll,
  handleCheckedEraseAll,
}) => {
  const { sensorsName, isDialogActivatedByRowConfig, formData } =
    React.useContext(MeasureContext);

  const RowInputNameTxt = (
    <RowInput
      tittle="Nombre"
      Component={{
        item: (
          <TextField
            id="unit-basic"
            label="Nombre"
            onChange={(e) => handdleDataForm(e, "name")}
            variant="outlined"
            size="small"
            color="primary"
            value={formData.name}
            key="nameTxt"
          />
        ),
      }}
    />
  );

  const RowInputNameSelect = (
    <RowInput
      tittle="Nombre"
      Component={{
        item: (
          <MultiSelectMui
            labelSelect="Nombre"
            valueSelect={formData.name ? formData.name : ""}
            onChange={(e) => handdleDataForm(e, "name")}
            selectOptions={sensorsName}
            placeHolder="Nombre"
            key="nameSelect"
          />
        ),
      }}
    />
  );

  const RowInputValue = (
    <RowInput
      tittle="Valor"
      Component={{
        item: (
          <TextField
            id="outlined-number"
            label="Valor"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            color="primary"
            onChange={(e) => handdleDataForm(e, "value")}
            value={formData.value}
            key="valor"
          />
        ),
      }}
    />
  );

  const RowInputUnit = (
    <RowInput
      tittle="Unidad"
      Component={{
        item: (
          <TextField
            id="unit-basic2"
            label="Unidad"
            variant="outlined"
            size="small"
            color="primary"
            onChange={(e) => handdleDataForm(e, "unit")}
            value={formData.unit}
            key="unidad"
          />
        ),
      }}
    />
  );

  const RowInputComment = (
    <RowInput
      tittle="Comentario"
      Component={{
        item: (
          <TextField
            id="unit-basic3"
            label="Comentario"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            color="primary"
            onChange={(e) => handdleDataForm(e, "comment")}
            value={formData.comment}
            key="comment"
          />
        ),
      }}
    />
  );

  const RowInputDate = (
    <ResponsiveDateTimePickers
      id="time-picker"
      selectedDate={formData.date}
      handleDateChange={handleDateChange}
    />
  );

  const RowInputFromDate = (
    <ResponsiveDateTimePickers
      id="time-picker2"
      selectedDate={fromDateDelete}
      handleDateChange={handleFromDateChange}
    />
  );

  const RenderOnNew = (
    <>
      <DialogContentText id="alert-dialog-description">
        Se dará de alta el sensor con estos valores iniciales.
      </DialogContentText>
      {RowInputNameTxt}
      {RowInputValue}
      {RowInputUnit}
      {RowInputComment}
      {RowInputDate}
    </>
  );

  const RenderOnExist = (
    <>
      <DialogContentText id="alert-dialog-description">
        Se agregarán los valores al sensor seleccionado.
      </DialogContentText>
      {RowInputNameSelect}
      {RowInputValue}
      {RowInputComment}
      {RowInputDate}
    </>
  );

  const RenderOnDelete = (
    <>
      <DialogContentText id="alert-dialog-description">
        ¡¡Cuidado!! Se borrarán los datos del sensor
        <br />
        seleccionado de manera permanente.
      </DialogContentText>
      {RowInputNameSelect}

      <RowInput
        tittle="Borrar todo"
        Component={{
          item: (
            <Checkbox
              checked={checkedEraseAll}
              onChange={handleCheckedEraseAll}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          ),
        }}
      />

      {checkedEraseAll === false && (
        <>
          <RowInput
            tittle="Desde:"
            Component={{
              item: RowInputFromDate,
            }}
          />

          <RowInput
            tittle="Hasta:"
            Component={{
              item: RowInputDate,
            }}
          />
        </>
      )}
    </>
  );

  const RenderOnRadioSelected = () => {
    switch (selectedRadio) {
      case RADIO_VAL_EXIST:
        return RenderOnExist;
      case RADIO_VAL_NEW:
        return RenderOnNew;
      case RADIO_VAL_DELETE:
        return RenderOnDelete;
      default:
        return <div>Error</div>;
    }
  };

  const buttonConfig = (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <RadioButtons
            dataRadios={dataRadios}
            handleChangeRadio={handleChangeRadio}
            selectedRadio={selectedRadio}
          />
          <Divider variant="middle" />
        </Grid>
      </Grid>
      {RenderOnRadioSelected()}
    </>
  );

  const rowConfig = (
    <>
      <DialogContentText id="alert-dialog-description">
        Sensor a editar
      </DialogContentText>
      {RowInputValue}
      {RowInputComment}
      {RowInputDate}
    </>
  );

  const RenderBodyDialog = () => {
    if (isDialogActivatedByRowConfig) {
      return rowConfig;
    }
    return buttonConfig;
  };

  return <DialogContent>{RenderBodyDialog()}</DialogContent>;
};

export default BodyDialog;
