import React from "react";

import { IconButton } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

import EditPhotoDialog from "./EditPhotoDialog";

const IconButtonEditPhoto = ({
  classNameIcon,
  namePhoto,
  photoURL,

  photosData,
  setPhotosData,
  addr,
  localPhotoAddr,
  docFirestore,
  dialogTitle,
  dialogContentText,
  deleteLabel,
  editLabel,
  deleteAllItem
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <IconButton
        aria-label="PhotoCameraIcon"
        size="medium"
        component="label"
        onClick={handleClickOpen}
      >
        <PhotoCameraIcon className={classNameIcon} fontSize="large" />
      </IconButton>
      {open && (
        <EditPhotoDialog
          open={open}
          setOpen={setOpen}
          photoURL={photoURL}
          photosData={photosData}
          setPhotosData={setPhotosData}
          addr={addr}
          namePhoto={namePhoto}
          localPhotoAddr={localPhotoAddr}
          docFirestore={docFirestore}
          dialogTitle={dialogTitle}
          dialogContentText={dialogContentText}
          deleteLabel={deleteLabel}
          editLabel={editLabel}
          deleteAllItem={deleteAllItem}
        />
      )}
    </>
  );
};

export default IconButtonEditPhoto;
