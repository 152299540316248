import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Login } from "../components";
import { RUTA_PRIVATE_DATA } from "../constants/routerConst";
import PrivateRoute from "./PrivateRoute";

const PublicNPrivateRoute = () => {

  return (
    <div className="">
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        {RUTA_PRIVATE_DATA.map((item, i) => (
          <PrivateRoute
            path={item.path}
            exact
            component={item.ComponentReact}
            key={`Router_${i}`}
          />
        ))}
      </Switch>
    </div>
  );
};

export default PublicNPrivateRoute;
export { PublicNPrivateRoute };
