import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { UserContext } from "./UserProvider";
import { getAllUidConfigData } from "./functions/DashboardFunctions/getAllUidConfigData";
import { getAllTiles } from "./functions/DashboardFunctions/getAllTiles";
import { getAllNodesData } from "./functions/DashboardFunctions/sharedFunctions";

export const DashboardContext = React.createContext();

const DashboardProvider = ({ children }) => {
  const { usuario, dataMacCan } = useContext(UserContext);
  const { username } = usuario;
  const [allTiles, setAllTiles] = useState([]);
  const [allUidConfigData, setAllUidConfigData] = useState([]);
  const [loraTiles, setLoraTiles] = useState(null);
  const [isDragButton, setIsDragButton] = useState(true);
  const isMountedRef = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      if (dataMacCan.length > 0) {
        const configData = await getAllUidConfigData({ username, dataMacCan });
        if (configData) {
          setAllUidConfigData(configData.dataConfiguracion);
          const tiles = await getAllTiles({ allUidConfigData: configData.dataConfiguracion, username });
          setAllTiles(tiles);
        }
      }
    };

    const fetchLoraNodes = async () => {
      const configDataNodes = await getAllNodesData(username);
      if (isMountedRef.current) {
        setLoraTiles(configDataNodes === "No Lora Devices" ? null : configDataNodes);
      }
    };

    fetchData();
    fetchLoraNodes();

    return () => {
      isMountedRef.current = false;
    };
  }, [username, dataMacCan]);

  const moveTileToMain = useCallback((tileId) => {
    setAllTiles(prevTiles => {
        const newTiles = [...prevTiles]; // Crea una copia del estado actual
        let tileFound = null;
        let sourceIndex = null;

        // Buscar el tile en cada sub-arreglo
        for (let i = 0; i < newTiles.length; i++) {
            tileFound = newTiles[i].find(tile => tile.uid === tileId);
            if (tileFound) {
                sourceIndex = i; // Guardar el índice del sub-arreglo donde se encontró
                break; // Salir del ciclo una vez encontrado el tile
            }
        }

        if (tileFound && sourceIndex !== null) {
            // Eliminar el tile del sub-arreglo original
            newTiles[sourceIndex] = newTiles[sourceIndex].filter(tile => tile.uid !== tileId);
            // Añadir el tile al sub-arreglo en la posición 0
            newTiles[0].push(tileFound);
        }

        return newTiles;
    });
  }, [setAllTiles]);


  return (
    <DashboardContext.Provider value={{ allTiles, setAllTiles, loraTiles, isDragButton, setIsDragButton, moveTileToMain }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
 