import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import ImageToEditDialogApp from "../../../Common/ImageToEditDialogApp/ImageToEditDialogApp";
import timelapse from "../../../../assets/images/timelapse.jpg";
import { AvatarApp } from "../../../Common";
import { db, storage } from "../../../../config/firebase";
import { UserContext } from "../../../../context/UserProvider";
import { TimelapseContext } from "../../../../context/TimelapseProvider";

const useStyles = makeStyles((theme) => ({
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    background: "#fff",
  },
}));
const nameCrop = "Nueva foto timelapse";
const IconAddPhotoTimelapse = () => {
  const classes = useStyles();
  const { usuario } = React.useContext(UserContext);
  const { timelapseData, setTimelapseData } =
    React.useContext(TimelapseContext);
  const { crop } = useParams();
  const addr = `${usuario.username}/crop/${crop}/timelapse/plant`;

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addPhotoStorage = async (image, namePhoto) => {
    const imagenRef = await storage.ref().child(addr).child(namePhoto);
    await imagenRef.put(image);

    const imagenURL = await imagenRef.getDownloadURL();
    return imagenURL;
  };

  const addPhotoFireStore = async (imagenURL, ref) => {
    let date = new Date();
    const imgData = {
      photoURL: imagenURL,
      date,
      offsetDate: date.getTimezoneOffset(),
    };

    await ref.set(imgData);
    return {...imgData,id:ref.id}
  };

  const addPhotoPage = (imgData) => {
    const localTimelapseAddr = `${crop}/timelapse/plant`;
    const clone = [...timelapseData, imgData];
    setTimelapseData(clone);
    localStorage.setItem(localTimelapseAddr, JSON.stringify(clone));
  };

  const addPhoto = async (image) => {
    try {
      setLoading(true);
      
      const ref = await db.collection(addr).doc();
      const imagenURL = await addPhotoStorage(image, ref.id);
      const imgData=await addPhotoFireStore(imagenURL, ref);
      addPhotoPage(imgData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message_);
      setLoading(false);
    }
  };

  const selectFile = async (image) => {
    const imageClient = image.target.files[0];

    if (imageClient === undefined) {
      setError("No se selecciono archivo");
      console.log("No se selecciono");
      return;
    }
    if (imageClient.type === "image/png" || imageClient.type === "image/jpeg") {
      await addPhoto(imageClient);
      setError(false);
      setOpen(false);
    } else {
      setError("Archivo no compatible");
    }
  };

  return (
    <>
      <Fab
        aria-label="add"
        className={classes.fabButton}
        onClick={handleClickOpen}
      >
        <AddAPhotoIcon color="primary" />
      </Fab>

      <ImageToEditDialogApp
        open={open}
        setOpen={setOpen}
        ImgComponent={
          <AvatarApp
            large
            title={nameCrop}
            src={timelapse}
            label={nameCrop.charAt(0).toUpperCase()}
          />
        }
        dialogTitle="Planta"
        dialogContentText="Una foto te permitirá guardar el crecimiento de tu planta y 
        poder realizar un timelapse posteriormente."
        selectFile={selectFile}
        error={error}
        loading={loading}
        editLabel="Nueva"
      />
    </>
  );
};

export default IconAddPhotoTimelapse;
