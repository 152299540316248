import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import AddAlertIcon from "@material-ui/icons/AddAlert";

const COLORES = {
  RED: "#FF0000",
  GREEN: "#008000",
  AQUA: "#00FFFF",
  BLUE: "#0000FF",
  ORANGE: "#FFC300",
  YELLOW: "#FFF333",
  LIME: "#BBFF77",
};

const MenuItemIconColor = (color, disabled = false) => (
  <MenuItem value={color} disabled={disabled}>
    <AddAlertIcon style={{ color }} />
  </MenuItem>
);

const SelectColor = ({ color, handleChange }) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={8} style={{ marginLeft: 8 }}>
        <InputLabel id="demo-mutiple-chip-label">
         Indentificador
        </InputLabel>

        <Select
          labelId="demo-mutiple-chip-label"
          fullWidth={true}
          name="color"
          value={color}
          onChange={handleChange}
          variant="outlined"
        >
          {MenuItemIconColor(color, true)}
          {MenuItemIconColor(COLORES.AQUA)}
          {MenuItemIconColor(COLORES.LIME)}

          {MenuItemIconColor(COLORES.ORANGE)}
          {MenuItemIconColor(COLORES.RED)}
        </Select>
      </Grid>

      <Grid item  xs={3}>
        <input
          name="color"
          type="color"
          onChange={handleChange}
          value={color}
          style={{width: "-webkit-fill-available"}}
        ></input>
      </Grid>
    </Grid>
  );
};

export default SelectColor;
