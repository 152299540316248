import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid} from "@material-ui/core";


import IconButtonTimelapse from "./IconButtonTimelapse";
import IconButtonCamera from "./IconButtonCamera";
import IconButtonDelete from "./IconButtonDelete";
import IconButtonSettings from "./IconButtonSettings";

const useStyles = makeStyles((theme) => ({
  cropName: {
    color: "#fff",
    fontWeight: "bold",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    textAlign: "center",
    marginTop: 8,
  },
  grid: { maxWidth: "100%" },
  icon: {
    color: "#fff",
    strokeWidth: "0.5",
    stroke: "grey",
  },
}));

const BarTileIconsGridContent = ({nameCrop,photoURL}) => {
  const styles = useStyles();

  return (
    <Grid direction="row" justifyContent="space-evenly" alignItems="center" container>
      <Grid item xs={12} md className={styles.grid}>
        <h5 className={styles.cropName}>
          {nameCrop} {/* El tamaño debe de ser max 20 */}
        </h5>
      </Grid>

      <Grid item xs={1} className={styles.grid}>
        <IconButtonTimelapse classNameIcon={styles.icon} nameCrop={nameCrop}/>
      </Grid>

      <Grid item xs={1} className={styles.grid}>
        <IconButtonDelete classNameIcon={styles.icon} nameCrop={nameCrop}/>
      </Grid>

      <Grid item xs={1} className={styles.grid}>
        <IconButtonCamera classNameIcon={styles.icon} nameCrop={nameCrop} photoURL={photoURL}/>
      </Grid>

      <Grid item xs={1} className={styles.grid}>
        <IconButtonSettings classNameIcon={styles.icon} nameCrop={nameCrop} photoURL={photoURL}/>
      </Grid>
    </Grid>
  );
};

const BarTileIconsGrid = ({nameCrop,photoURL}) => {
  
  return (
      <BarTileIconsGridContent nameCrop={nameCrop} photoURL={photoURL}/>
  );
};

export default BarTileIconsGrid;
