export const getUnixTimeDays = (date) => {
  const time = date ? new Date(date) : new Date();

  let minutes = 1000 * 60;
  let hours = minutes * 60;
  let days = hours * 24;
  const dayUnixTime = time.getTime() / days;

  return dayUnixTime;
};

export const getWeeksDaysFromDay = (date) => {
  const today = getUnixTimeDays();
  const initDay = getUnixTimeDays(date);
  const diffDays = Math.round(today - initDay);

  let week = diffDays / 7;
  week = week - (week % 1);
  const day = diffDays % 7;
  return { week, day };
};

 