import React from 'react'
import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, makeStyles, withStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 260,
    },
  },
};

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
      '&$disabled': {
        color: grey[500],
      },
    },
    checked: {},
    disabled: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 100,
      maxWidth: 100,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 600,
    },
  }));

export const SelectorForm = ({ valueSelected, handleChangeSelect, arrayOfOptions, labelName, id }) => {
    const classes = useStyles();

    
  return (
    <FormControl key={`Form_${id}`} className={classes.formControl}>
        <InputLabel key={`InLabe_${id}`} id={`SelectPumps_${id}`}>{labelName}</InputLabel>
        <Select
        key={`Sel_${id}`}
        //labelId="SelectPumps"
        id={`select_${id}`}
        multiple
        value={valueSelected}
        onChange={handleChangeSelect}
        input={<Input id="imputPump" key={`InputFF_${id}`}/>}
        renderValue={(selected) => selected.join(',')}
        MenuProps={MenuProps}
        
        > 
            {arrayOfOptions.map((name) => (
                <MenuItem key={`${name}_${id}`} value={name} >
                    <GreenCheckbox 
                    checked={valueSelected.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                </MenuItem>
            ))}
        </Select>
    </FormControl>
  )
}
