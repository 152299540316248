import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
  },
}));

const BarTileInfoGridContent = ({tile}) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        container
      >
        <Grid item xs={3} className={classes.item}>
          Semana
        </Grid>
        <Grid item xs={3} className={classes.item}>
          Día
        </Grid>
        <Grid item xs={3} className={classes.item}>
          Estado
        </Grid>
        <Grid item xs={3} className={classes.item}>
          Ciclo
        </Grid>
      </Grid>

      <Grid
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        container
      >
        <Grid item xs={3} className={classes.item}>
          {tile.week}
        </Grid>
        <Grid item xs={3} className={classes.item}>
          {tile.day}
        </Grid>
        <Grid item xs={3} className={classes.item}>
          <h5
            style={{
              color: true ? "green" : "red",
              fontWeight: "bold",
              textShadow:
                "-0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 0.5px white",
            }}
          >
            OK
          </h5>
        </Grid>
        <Grid item xs={3} className={classes.item}>
          Plantúla
        </Grid>
      </Grid>
    </>
  );
};

const BarTileInfoGrid = ({tile}) => {
  return (
    <>
      <BarTileInfoGridContent tile={tile}/>
    </>
  );
};

export default BarTileInfoGrid;
