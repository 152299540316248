import React, { useContext, useState } from 'react'
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Papa from 'papaparse';
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UserContext } from '../../../context/UserProvider';

const useStyles = makeStyles((theme) => ({
  sideCard: {
	  width: '100%',
    height: '100%',
	  //padding: theme.spacing(1),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 1px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
}));

if (typeof Highcharts === 'object') {
  Highcharts.setOptions({
    lang: {
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      rangeSelectorFrom: "Desde",
      rangeSelectorTo: "Hasta",
      loading: "Cargando..."
    }
  });
}

export const HistoricalWeatherData = ({ coordinates,polygonName }) => {
  const classes = useStyles();
  const { userTimezone, usuario } = useContext(UserContext)
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [loadingWeatherAnalysis, setLoadingWeatherAnalysis] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    title: {
      text: 'Datos Meteorológicos'
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Fecha y Hora'
      },
      crosshair: true
    },
    yAxis: {
      title: {
        text: 'Valores del Sensor'
      },
      crosshair: true
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      dateTimeLabelFormats: {
        day: '%A, %e de %B de %Y'
      }
    },
    credits: {
      enabled: false
    },
    series: []
  });

  const fetchCSVData = (url) => {
    fetch(url)
      .then(response => response.text())
      .then(csvText => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            setData(result.data);
            filterData(result.data,startDate, endDate);
          },
          error: (error) => {
            console.error('Error al leer el archivo CSV:', error);
          }
        });
      })
      .catch(error => console.error('Error al descargar el archivo CSV:', error));
  };

  const handleGetHistoricalWeatherData = async() => {
    setLoadingWeatherAnalysis(true)
    const bodyRequest = {
      geojson: coordinates,
      username: usuario.username,
      polygonName: polygonName,
      timezone: userTimezone
    }
    try {
      const dataToSend = JSON.stringify(bodyRequest)
      const cloudFunction_url = process.env.REACT_APP_HISTORICAL_WEATHER_DATA
      const response = await fetch(cloudFunction_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonData = await response.json();
      const fileUrl = jsonData.file_url;
      fetchCSVData(fileUrl);
      console.log("Esto es jsonData:",jsonData)
      setLoadingWeatherAnalysis(false);
    } catch (error) {
      console.error("Error en la peticion de datos:",error)
    }
  };

  const filterData = (data, startDate, endDate) => {
    const filteredData = data.filter(row => {
      const date = new Date(row[Object.keys(row)[0]]);
      return date >= startDate && date <= endDate;
    });

    processCSVData(filteredData);
  };

  const processCSVData = (filteredData) => {
    const seriesData = [];

    filteredData.forEach(row => {
      const date = new Date(row[Object.keys(row)[0]]).getTime();
      Object.keys(row).slice(1).forEach((key, index) => {
        if (!seriesData[index]) {
          seriesData[index] = {
            name: key,
            data: []
          };
        }
        seriesData[index].data.push([date, parseFloat(row[key])]);
      });
    });

    setChartOptions(prevOptions => ({
      ...prevOptions,
      title: {
        text: `Datos Historicos de ${polygonName}`
      },
      series: seriesData
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if(date < endDate) {
      if(data.length !== 0) {
        filterData(data, date, endDate);
      }
    }
  };

  const handleEndDateChange = (date) => {
    const now = new Date();
    if (date <= now && date > startDate) {
      setEndDate(date);
      if(data.length !== 0) {
        filterData(data, startDate, date);
      }
      
    }
  };

  return (
	<div> 
    <Grid container direction="row" justifyContent='center' spacing={1}>
      <Grid item xs={12} sm={9}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card className={classes.sideCard}>
          <CardContent>
            {/* <div style={{ marginTop: "15px", marginBottom: "15px"}}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="mi-selector-label">Elige un campo</InputLabel>
                <Select
                  labelId="mi-selector-label"
                  id="mi-selector"
                  value={selectorValue}
                  onChange={handleSelectorChange}
                  //disabled={namesOfPolygons.length === 0}
                >
                  {namesOfPolygons?.map((name,index) => (
                    <MenuItem key={index} value={name}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="start-date-picker"
                label="Fecha de Inicio"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            <div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM//yyyy"
                margin="normal"
                id="end-date-picker"
                label="Fecha de Fin"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            </MuiPickersUtilsProvider>
            {polygonName !== null && 
              <div style={{ textAlign: 'center' }}>
                {loadingWeatherAnalysis ? 
                <CircularProgress />
                :
                <Button variant="contained" size='small' color="primary" onClick={handleGetHistoricalWeatherData}>
                  Obtener datos
                </Button>
                }
              </div>
            }
          </CardContent>
        </Card>
      </Grid>
      
      
    </Grid> 
  </div>
  )
}
