import React, { useEffect, useState } from 'react'
import { backGroundList } from '../../../../../constants/globalConst'
import { Button, Grid, Icon, MenuItem, TextField, makeStyles } from '@material-ui/core'
import { Send } from '@material-ui/icons';
import { db } from '../../../../../config/firebase';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

export const CurretOffsets = ({ ide, username, canId, mac, setOpenAlert }) => {
    const classes = useStyles();
    const [sign, setSign] = useState('0');
    const [offsetValue, setOffsetValue] = useState('');
    const [integerValue, setIntegerValue] = useState(0);
    const [decimalValue, setDecimalValue] = useState(0);
    const [integerDivision, setIntegerDivision] = useState(0);

    const handleChangeSign = (e) => {
        const {value} = e.target;
        setSign(value);
    }

    const handleChangeValue = (e) => {
        const {value} = e.target;
        setOffsetValue(value);
        if (e.target.value === '') {
            //setOffsetValue(0.0);
            const entera = 0;
            const decimal = 0;
            setIntegerValue(entera);
            setDecimalValue(decimal);
            //setOffsetFlag(true);  
            //console.log(value);
          } else{      
            const partes = value.split('.'); // Separar por el punto decimal
            const entera = parseInt(partes[0], 10);
            const decimal = partes.length > 1 ? parseInt(partes[1], 10) : 0;
            setIntegerValue(entera);
            setDecimalValue(decimal);
            //setOffsetFlag(true);  
          }
    }

    const calculateIntegerDivision = () => {
        const result = Math.floor(integerValue / 256);
        setIntegerDivision(result);
    };

    const sendingOperationCalibration = async (e, actionMs) => {
        //console.log(e)
        
        const item = {
          msMqtt: e,
          mac: mac,
          action: actionMs,
          fecha: Date.now(),
          uid: ide,
        };
    
        try {
          const addr = `${username}/infoDevices/${mac}/${canId}/sendConfigModule`;
          await db.collection(addr).doc("sendConfig").set({ item });
          return new Promise(resolve => setTimeout(resolve, 500));
        } catch (error) {
          console.log(error);
        }
    };

    const handleButton = () => {
        setOpenAlert({
            open:true,
            type:"info",
            tittle:"Info",
            txt:"Espere a que se configure correctamente el modulo",
        })
        const arrayDeCadenas = ide.split("@");
        const canid = arrayDeCadenas[1];
        const outid = arrayDeCadenas[3];
        let mqtt = "";
        const len = 6;
        const action = 247;
        calculateIntegerDivision();
        mqtt =
            len +
            "," +
            action +
            "," +
            canid +
            "," +
            outid+
            "," +
            sign +
            "," +
            integerValue +
            "," +
            decimalValue + 
            "," +
            integerDivision;
            const messageAction = "Offset Corriente";
        sendingOperationCalibration(mqtt,messageAction);
        const updateDashboard = "2,6,"+canid+",1";
        const message = "Offset Corriente Update"
        sendingOperationCalibration(updateDashboard,message);
            //setOffsetFlag(false);
        //console.log("delay de 0.5 Seg.")
        //console.log(mqtt);
        // Aquí implementamos el delay de 0.5 segundos antes de permitir que la función reciba nuevos datos.
        
        
        // console.log(isOffset);
    }

    // Función para guardar constantes en una ruta específica
    const saveConstants = (path, item) => {
        db.doc(path).set({item})
        .then(() => {
            console.log("Constantes guardadas exitosamente");
        })
        .catch(error => {
            console.error("Error al guardar las constantes:", error);
        });
    }

    useEffect(() => {
        const addr1 = username+'/infoDevices/'+mac+'/'+canId+'/fromModule/configOK';
        const addr2 = `${username}/infoDevices/${mac}/${canId}/sendConfigModule/sendConfig`;
        const addr3 = `${username}/infoDevices/${mac}/${canId}/configModule/${ide}`
        const unsubscribe = db.doc(addr1).onSnapshot(snapshot => {
          const data = snapshot.data();
          if (data.accion === 247 && data.act === 'recOK') {
              
            // Comprobar un valor en un array de otra ruta
            db.doc(addr2).get().then(doc => {
              const otherData = doc.data();
              const arrayValue = otherData.item; // Asume que someArray es tu array
              
              // Comprobar si el valor constante existe en el array
              if (arrayValue.uid === ide) {
                //console.log("El valor constante está en el array");
                db.doc(addr3).get().then(savedoc => {
                    const itemData = savedoc.data();
                    //console.log("Esto es itemData:", itemData)
                    const newValue = {...itemData.item , offsetValue: offsetValue, offsetSign: sign}
                    const item = {...newValue}
                    //console.log("Esto es item:",item)
                    saveConstants(addr3,item);
                    
                })
                
                
                //db.doc(addr3).set({item: {...newValue}})
                setOpenAlert({
                    open:true,
                    type:"success",
                    tittle:"Configuración Exitosa",
                    txt:"El Offset se configuro correctamente",
                })
                return new Promise(resolve => setTimeout(resolve, 2000));
                
              }
              data.act = 'rec'; 
            }).catch(error => {
              console.error("Error al obtener el documento:", error);
            });
            
          }
          setOpenAlert({
            open:null,
            type:null,
            tittle:null,
            txt:null,
        })
        });
    
        // Limpiar el listener al desmontar el componente
        return () => unsubscribe();
    }, [offsetValue,sign,canId,username,mac,ide]);

    useEffect(() => {
        const defaultOpenAlert={open:null,
            type:null,
            tittle:null,
            txt:null}
        setOpenAlert(defaultOpenAlert);
        const add = `${username}/infoDevices/${mac}/${canId}/configModule/${ide}`
        const unsubscribe = db.doc(add).onSnapshot(snapshot => {
          const data = snapshot.data();
          //console.log("Esto es data:", data)
          if (data.item.offsetSign && data.item.offsetValue) {
            setOffsetValue(data.item.offsetValue);
            setSign(data.item.offsetSign);
          } else {
            setOffsetValue('0.0');
            setSign('0');
          }
        });
    
        // Limpiar el listener al desmontar el componente
        return () => unsubscribe();
    }, [canId,ide,mac,username]);


  return (
    <>
    {/* <div className="col-lg-4 col-md-12 mt-3"> */}
    
    <div >
        <ul className="list-group">
            <li className="list-group-item" style={{background:backGroundList}}>
                <h3 style={{ color: "white" }}>Offset Corriente</h3>
            </li>

            <li className="list-group-item" >

            <div className="row" style={{marginBottom: '40px'}}>
                {/* <div className="col-4">Offset value:</div> */}
                
                <Grid container justifyContent="center" alignItems="center" spacing={4}>
                <Grid item xs={0} sm={0} ></Grid>
                <Grid item xs={6} sm={3} >
                    <TextField 
                    id="selectSign" 
                    label="+ / -" 
                    value={sign} 
                    select 
                    onChange={handleChangeSign} 
                    size="small"
                    variant="outlined">
                    <MenuItem value={"0"}>+</MenuItem>
                    <MenuItem value={"1"}>-</MenuItem>
                    </TextField>
                
                </Grid>

                <Grid item xs={6} sm={6}>
                    
                <TextField
                    id="outlined-number"
                    label="Valor"
                    type="number"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    value={offsetValue}
                    onChange={handleChangeValue}
                    size="small."
                    variant="outlined"
                    inputProps={{
                    step: 0.1, // Permite incrementar en 0.1
                    min: "0", // Valor mínimo que puedes definir según tus necesidades
                    // max: "10", // Valor máximo, opcional según tus necesidades
                    }}
                />
                
                </Grid>

                

                </Grid>
                                
                
            </div>
            <div style={{marginBottom: '40px'}}>
                <Grid container justifyContent="center" alignItems="center">
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<Send />}
                    onClick={handleButton}
                >
                    Enviar
                </Button>
                </Grid>
                </div>
            </li>
        </ul>
       
    </div>
    </>
    
  )
}
