import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useWindowDimensions from "./ComponentsDashboard/WindowDimensions";
import { UserContext } from "../../../context/UserProvider";
import { DashboardContext } from "../../../context/DashboardProvider";
import DashBoardHead from "./ComponentsDashboard/DashBoardHead";

import {
  getNewPositionsWhenTileMoved,
  requestDataFirstNodes,
  saveTilesPositions,
} from "./Functions";
import { BG_COLOR_TILES_WHEN_DRAG, BG_COLUMN_COLOR_DRAG_OFF, BG_COLUMN_COLOR_DRAG_ON, GRID } from "./constants";
import { db } from "../../../config/firebase";
import AppropriateTile from "./ComponentsDashboard/AppropriateTile";
import { MulticropHeader } from "./ComponentsDashboard/MulticropHeader";
import { MultiCropContext } from "../../../context/MultiCropContext/MultiCropContext";
import { getNewPositionFromHiddenCards, getNewPositionsWhenTileMovedTest } from "./Functions/tiles";

const useStyles = makeStyles(theme => ({
  paperWidget: {
    width: 250,
    margin: theme.spacing(1),
    position: 'relative', // Necesario para posicionar el botón de eliminar
  },
  gridItem: {
    minWidth: 250,
    maxWidth: "100%",
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(1), // Ajustar el valor para controlar la distancia desde el borde superior
    right: theme.spacing(1), // Ajustar el valor para controlar la distancia desde el borde derecho
  },
}));

const DashBoard = () => {
  const classes = useStyles();
  const { usuario, dataMacCan, numberOfCans, currentMac, userTimezone } = useContext(UserContext);
  const { allTiles, setAllTiles, isDragButton, setIsDragButton } = useContext(DashboardContext);
  const { tabValue, allTilesTest, setAllTilesTest, saveNewTilesPosition, hiddenCards, setHiddenCards } = useContext(MultiCropContext);
  const { username } = usuario;
  const [tilesMoved, setTilesMoved] = useState(false);
  const [isHorizontalMenuVisible, setIsHorizontalMenuVisible] = useState(false);
  const { width } = useWindowDimensions();

  const minutos = 60000 * 2; //60000 ms = 1 minuto

  const toggleDragMode = async () => {
    if(isHorizontalMenuVisible || tilesMoved){
      setTilesMoved(false);
      await saveNewTilesPosition();
      //await saveTilesPositions(allTiles, username);
    }
    setIsDragButton(!isDragButton);
    setIsHorizontalMenuVisible(!isHorizontalMenuVisible);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // if (!destination) return;
    // Si no hay destination o si el droppableId de destination es inválido, salir
    if (!destination || typeof destination.droppableId === "undefined") return;
    try{
      if(source.droppableId === "0" || destination.droppableId === "0") {
        const updatedHiddenTiles = getNewPositionFromHiddenCards(source,destination, allTilesTest[tabValue], hiddenCards)
        const newTabTiles = [...allTilesTest]
        const indexOfColumn = source.droppableId === "0" ? Number(destination.droppableId) : Number(source.droppableId)
        newTabTiles[tabValue] = [...updatedHiddenTiles[indexOfColumn]]
        setHiddenCards([...updatedHiddenTiles[0]]) 
        setAllTilesTest([...newTabTiles])
      } else {
        const updatedTabTiles = getNewPositionsWhenTileMoved(source,destination, allTilesTest[tabValue])
        const newTabTiles = [...allTilesTest]
        newTabTiles[tabValue] = [...updatedTabTiles]
        setAllTilesTest([...newTabTiles])
      }    
      setTilesMoved(true);
    }catch(error) {
      console.error("Error durante el drag and drop:", error)
    }
  };

  useEffect(() => {
    
    const sendMessageToFirebase = async (routeId, message) => {
      try {
        if (currentMac) {
          const addr = `${username}/infoDevices/${currentMac}/${routeId}/sendConfigModule`;
          await db.collection(addr).doc("sendConfig").set({ item: message });
        }
      } catch (error) {
        console.error('Error sending message to Firebase:', error);
      }
    };

    const sendMessages = () => {
      numberOfCans.forEach((id, index) => {
        setTimeout(() => {
          const item = {
            action: "askingData",
            fecha: Math.floor(Date.now() / 1000),
            mac: currentMac,
            msMqtt: `2,6,${id},1`,
            uid: `${currentMac}@${id}`
          };
          //console.log("Esto es item:", item);
          //console.log("Esto es currentMac:",currentMac)
          sendMessageToFirebase(id, item);
        }, 5000 * index); // Desfase de 5 segundos entre cada mensaje
      });
    };

    if (numberOfCans.length > 0) {
      sendMessages(); // Enviar los mensajes inmediatamente
      const intervalId = setInterval(() => {
        sendMessages();
      }, minutos); // Repetir cada 'minutos' milisegundos (2 minutos en tu caso)

      return () => clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonte
    }
    
  }, [numberOfCans, currentMac, username, minutos]);



  return (
    <div className="container mt-5">
      <DashBoardHead isDragButton={isDragButton} onClickSaveDragged={toggleDragMode} username={username} mac={currentMac} timezone={userTimezone} />
      <MulticropHeader />
      <div >
        <DragDropContext onDragEnd={onDragEnd}>
          {isHorizontalMenuVisible && (
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{ padding: `${GRID * 2}px 0` }}>
              <Droppable droppableId={`${0}`} direction="horizontal">
                {(provided) => (
                  <Grid 
                  item 
                  ref={provided.innerRef} 
                  style={{background: BG_COLUMN_COLOR_DRAG_OFF,
                  padding: GRID,
                  overflow: "auto",
                  alignItems: "center",
                  display: "flex",
                  width: width < 650 ? "300px" : "",
                  border: width < 650 ? "2px dotted" : "",
                  minWidth:"300px",
                  minHeight:"50px"}}
                  {...provided.droppableProps} 
                  >
                    {/* {allTiles[0]?.map((item, index) => ( */}
                    {hiddenCards?.map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={isDragButton}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              borderRadius: '15px',
                            }}
                            className={classes.paperWidget}
                            elevation={3} // Puedes ajustar el nivel de sombra, entre 0 y 24
                          >
                            <AppropriateTile item={item} index={index} col={0} />
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          )}

          <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
            {/* {console.log("Esto es allTiles:",allTiles)} */}
            {Array.from({ length: 3 }, (_, i) => i + 1).map(i => (
              <Droppable key={i} droppableId={`${i}`}>
                {(provided) => (
                  <Grid item
                  ref={provided.innerRef} 
                  style={{
                    background: BG_COLUMN_COLOR_DRAG_OFF,
                    padding: GRID,
                    border: width < 650 ? "2px dotted" : "",  //"2px dotted"
                    minWidth:"300px",
                    minHeight:"50px",
                    borderRadius: '10px',}}
                  {...provided.droppableProps} 
                  
                  >
                    {/* {console.log("allTilesTest:",allTilesTest)} */}
                    {allTilesTest[tabValue][i]?.length ?
                      allTilesTest[tabValue][i].map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={!isHorizontalMenuVisible}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              borderRadius: '15px',
                              
                            }}
                            className={classes.paperWidget}
                            elevation={3} // Puedes ajustar el nivel de sombra, entre 0 y 24
                          >
                            
                            <AppropriateTile item={item} index={index} col={i} />
                          </Paper>
                        )}
                      </Draggable>
                    ))
                    :
                    <div style={{ textAlign: 'center', width: '100%' }}>Cargando...</div>
                    }
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            ))}
          </Grid>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DashBoard;

