const TogglesDataDefault = {
  fan: {
    data1: [
      7, 10, 13, 14, 14, 14, 14, 15, 16, 16, 16, 19, 19, 19, 20, 21, 22, 23, 23,
      23,
    ],
    data2: [
      24, 23, 23, 23, 22, 21, 20, 20, 20, 20, 20, 17, 16, 15, 14, 13, 13, 12,
      11, 10,
    ],
    data3: [45, 45],
  },
  extractor: {
    data1: [1, 45, 41, 1, 42, 52, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data3: [0, 0],
  },
  humnidificador: {
    data1: [
      72, 72, 68, 65, 60, 55, 52, 50, 49, 47, 47, 45, 42, 40, 39, 40, 39, 36,
      35, 34,
    ],
    data2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data3: [0, 0],
  },
  leds: {
    data1: [
      18, 18, 18, 18, 18, 18, 18, 18, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
      10, 10,
    ],
    data2: [22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data3: [0, 0],
  },
  bomba: {
    data1: [0, 50, 51, 1, 10, 8, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data3: [0, 0],
  },
  always: {
    data1: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    data3: [0, 1],
  },
};

const AmbienteDataDefault = {
  pumbPH: [
    5.5, 6.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
  pumbNT: [
    20, 1, 25, 12, 15, 0.8, 1.2, 0, 0, 0, 0, 7.5, 7.5, 7.5, 2.5, 2.5, 2.5, 2.5,
    2.5, 2.5,
  ],
  always: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
};

export { TogglesDataDefault, AmbienteDataDefault };
