import React from "react";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import { ALL_PATHS } from "../../../../constants/routerConst";
import { UserContext } from "../../../../context/UserProvider";
import LogoLogIn from "../../../../assets/images/logo_LogIn.PNG";
import FooterForm from "./FooterForm";
import SocialAuhtButtons from "./SocialAuhtButtons";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const SignInForm = ({ setKindFormUser, ...props }) => {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  //const [error, setError] = React.useState(null);
  // const [error, setError] = React.useState('');
  const [errorProccess, setErrorProccess] = React.useState(null)
  const { signInWithEmailAndPassword, error } = React.useContext(UserContext);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setErrorProccess("Ingrese email");
      return;
    }
    if (!pass.trim()) {
      setErrorProccess("Ingrese Password");
      return;
    }

    setErrorProccess(null);
    signInNPush();
  };

  const signInNPush = async () => {
    try {
      await signInWithEmailAndPassword(email, pass);
      setEmail("");
      setPass("");
      setErrorProccess(null);
      props.history.push(ALL_PATHS.PATH_DASHBOARD);
      
      
    } catch (error) {
      // const responseError = handleAuthError(error)
      console.log("Esto es responseError:",error)
      //console.log(error);
      // if (error.code === "auth/invalid-email") {
      //   setError("Email no valido");
      // }
      // if (error.code === "auth/user-not-found") {
      //   setError("Email no registrado");
      // }
      // if (error.code === "auth/wrong-password") {
      //   setError("Password no valido");
      // }
    }
  };

  return (
    <>
      <Avatar className={classes.avatar} src={LogoLogIn}></Avatar>
      <form noValidate onSubmit={procesarDatos}>
        {error && <div className="alert alert-danger">{error}</div>}
        {errorProccess && <div className="alert alert-danger">{errorProccess}</div>}

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPass(e.target.value)}
          value={pass}
        />

        <SocialAuhtButtons
          labelGoogle="Ingresa con Google"
          labelFacebook="Ingresa con Facebook"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Acceder
        </Button>
      </form>

      <FooterForm
        setKindFormUser={setKindFormUser}
        Link1={{ text: "¿No tienes cuenta?", to: "LogInForm" }}
        Link2={{ text: "Recuperar contraseña", to: "ResetForm" }}
      />
    </>
  );
};

export default withRouter(SignInForm);
