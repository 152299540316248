import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../../context/UserProvider';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import HeadTile from './HeadTile';
import { db } from '../../../../config/firebase';

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const allParameters = [
    "conductivity",
    "temperature",
    "ph",
    "humidity",
    "pressure",
    "co2",
    "nitrogen",
    "phosphorus",
    "potassium",
    "photosynthetic",
    "windSpeed",
    "rainGauge"
]

const namesToShow = [
	"EC",
    "Temperatura",
    "ph",
    "Humedad",
    "Presión",
    "CO2",
    "Nitrógeno",
    "Fósforo",
    "Potasio",
    "PAR",
    "Velocidad del viento",
    "Precipitación"
]
const soilTypes = [1,2,3];
const envTypes = [4,5,9,10]

export const NodeLoraWanTile = ({ data, style, col }) => {
	const { usuario, dataMacCan, getTimezoneOffsetInSeconds } = useContext(UserContext);
	const classes = useStyles();
	const uid = data.uid;
	const arrayDeCadenas = data.uid.split("@"); //0@1@2@3@4
	const [nodeName, setNodeName] = useState("Nodo");
	const [cardData, setCardData] = useState([])
	const [parameterTitles, setParameterTitles] = useState([])

	const useDocumentListener = (docPath) => {
		useEffect(() => {
		  // Obtiene la referencia al documento
		  const docRef = db.doc(docPath);
	  
		  // Se suscribe a los cambios del documento
		  const unsubscribe = docRef.onSnapshot((docSnapshot) => {
			if (docSnapshot.exists) {
			  data = docSnapshot.data();
			//   console.log("Esto es data:",data)
			  const allData = data.data
			  const obtainedData = [];
			  const namesOfParameters = []
			  for (let index = 0; index < allData.length; index++) {
				if(Number(arrayDeCadenas[3]) === allData[index].id){
					for (const key in allData[index]) {
						if (allParameters.includes(key)) {
							obtainedData.push(allData[index][key])
							if(soilTypes.includes(allData[index].type)) { //Tipo soil
								const index = allParameters.indexOf(key);
								const nameFound = namesToShow[index]
								const nameOfParameter = `${nameFound} del sustrato`
								namesOfParameters.push(nameOfParameter)
							} else if(envTypes.includes(allData[index].type)) { //Tipo Ambientales
								const index = allParameters.indexOf(key);
								const nameFound = namesToShow[index]
								const nameOfParameter = `${nameFound} del ambiente`
								namesOfParameters.push(nameOfParameter)
							} else {
								const index = allParameters.indexOf(key);
								const nameFound = namesToShow[index]
								namesOfParameters.push(nameFound)
							}
						}
					}
					// console.log("Esto es obtainedData:",obtainedData)
					// console.log("Esto es namesOfParameters:",namesOfParameters)
					setCardData([...obtainedData])
					setParameterTitles([...namesOfParameters])
					
				}
				
			  }
			  
			  // console.log("Esto es data:", data);
			} else {
			  console.log("¡No se encontró el documento!");
			}
		  }, (err) => {
			console.error("Error al escuchar el documento: ", err);
		  });
	  
		  // Función de limpieza que se ejecuta cuando el componente se desmonta
		  return () => unsubscribe();
	  
		}, [docPath]); // El efecto se vuelve a ejecutar si docPath cambia
	};
  
	const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}/sensors/dataSensors`;
  
	useDocumentListener(docPath);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Indicar que el componente está montado
		const obtenerDatos = async () => {
			try {
			  const arrayDeCadenas = uid.split("@");
			  const nodeid = arrayDeCadenas[2];
			  const addr2 = `${usuario.username}/loraDevices/nodes/${nodeid}/sensors`;

			  const leerConfigNode = async () => {
				try {
				  const docRef = db.collection(addr2).doc("registeredSensors");
				  const docSnap = await docRef.get();
			  
				  if (docSnap.exists) {
					const allDataSensors = docSnap.data().sensors;
					const idSensor = uid.split("@")[3];
					let assignName = ''
					for (let index = 0; index < allDataSensors.length; index++) {
						if(Number(idSensor) === allDataSensors[index].id ) {
							if(soilTypes.includes(allDataSensors[index].type)) {
								assignName = `${allDataSensors[index].name}-Soil`
							} else if(envTypes.includes(allDataSensors[index].type)){
								assignName = `${allDataSensors[index].name}-Amb.`
							} else {
								assignName = `${allDataSensors[index].name}`
							}
							
							setNodeName(assignName)
							
						}
					}
					// Solo actualizar si el componente aún está montado
					if (isMountedRef.current) {
						
					}
					
				  } else {
					// El documento no existe.
					if (isMountedRef.current) {
					  console.log("No se encontró nombre del Nodo Lora");
					}
					
				  }
	
				} catch (error) {
				  console.error("Error al obtener el documento:", error);
				}
			  };
			  
			  //leerDatosDB();
			  leerConfigNode();
			} catch (error) {
			  console.log("obtenerDatos: error ", error);
			}
		  };
		  obtenerDatos();
		  return () => {
			isMountedRef.current = false; // Indicar que el componente se ha desmontado
		  };

	}, [uid, usuario.username])
    
  return (
	<Grid
	container
	direction="row"
	justifyContent="center"
	alignItems="center"  // Cambiado a "center" para centrar verticalmente.
	style={style}
	>
  	<HeadTile name={nodeName} uid={uid} col={col} />

  {cardData.length !== 0 &&
    cardData.map((value, index) => (
      <React.Fragment key={index}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="center"  // Alineación centrada de los elementos hijos.
          justifyContent="center"
          className="parameter-grid"  // Clase CSS personalizada para aplicar estilos.
        >
          <Typography variant="subtitle2" gutterBottom>
            {parameterTitles[index]}
          </Typography>
          <h5>
            <span className="badge badge-dark value-badge">{value}</span>
          </h5>
        </Grid>
        {(index === 1 || index === 3 || index === 5) && (
          <Grid item xs={11}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </React.Fragment>
    ))}
</Grid>

  )
}
