export const INDEX_FIXED_GHOST_HIDE_COL = "0_0";
export const INDEX_FIXED_GHOST_FIRST_COL = "1_0";
export const INDEX_FIXED_GHOST_SECOND_COL = "2_0";
export const INDEX_FIXED_GHOST_THIRD_COL = "3_0";

const GHOST_KIND = "ghost";
export const INITIAL_FIXED_GHOST_COLUMNS = [
  { uid: INDEX_FIXED_GHOST_HIDE_COL, kind: GHOST_KIND },
  { uid: INDEX_FIXED_GHOST_FIRST_COL, kind: GHOST_KIND },
  { uid: INDEX_FIXED_GHOST_SECOND_COL, kind: GHOST_KIND },
  { uid: INDEX_FIXED_GHOST_THIRD_COL, kind: GHOST_KIND },
];

export const BG_COLOR_TILES_WHEN_DRAG = "MintCream";
export const BG_COLUMN_COLOR_DRAG_ON = "LightBlue";
export const BG_COLUMN_COLOR_DRAG_OFF = "LightCyan";

const MILLI_SECONDS = 1000;
export const TIME_TO_ASK_ESP32 = 60 * MILLI_SECONDS;

export const GRID = 8;

export const defaultOpenAlert = {
  open: null,
  type: null,
  tittle: null,
  txt: null,
};
