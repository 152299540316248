
// Lista explícita de zonas horarias de México
// const mexicoTimeZones = [
//     'America/Mexico_City',
//     'America/Cancun',
//     'America/Merida',
//     'America/Monterrey',
//     'America/Matamoros',
//     'America/Mazatlan',
//     'America/Chihuahua',
//     'America/Ojinaga',
//     'America/Hermosillo',
//     'America/Tijuana',
//     'America/Bahia_Banderas'
// ];

const mexicoTimeZones = [
    { label: "UTC-05:00 Quintana Roo/Cancun", value: "America/Cancun" },
    { label: "UTC-06:00 Ciudad de México,Guadalajara,Monterrey", value: "America/Mexico_City" },//
    { label: "UTC-07:00 La Paz, Mazatlan, Sonora", value: "America/Mazatlan" },//zona horaria de Sonora, Sinaloa y la parte sur de Chihuahua es UTC-7, parte norte de Chihuahua es UTC-6 en verano
    { label: "UTC-08:00 Baja California", value: "America/Tijuana" },//zona horaria de baja california UTC-8, UTC-7 h.verano
];

export {
	mexicoTimeZones
}