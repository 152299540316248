import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import CrossButton from "./CrossButton";
import { DashboardContext } from "../../../../context/DashboardProvider";

const HeadForAsyncTile = ({
    col,
    name,
    onClickCrossButton,
    style
}) => {
  const {  isDragButton } = useContext(DashboardContext);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
        
      <Grid item xs={8}>
        {col === 0 && <h5>{name}</h5>}
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={1}>
        <CrossButton onClick={onClickCrossButton} hidden={isDragButton} />
        
      </Grid>
    </Grid>
  );
};

export default HeadForAsyncTile;
