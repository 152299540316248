import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module

require("highcharts/modules/draggable-points")(Highcharts);

const DragChart = (props) => {
    
    const [data1now,setData1now]=React.useState();
    const [data2now,setData2now]=React.useState();
    const [warningTxt,setWarningTxt]=React.useState(null)

    React.useEffect(()=>{
        const dt1=[...props.data.data.data1]
        const dt2=[...props.data.data.data2]
        setData1now(dt1)
        setData2now(dt2)
    },[props.data.data])

    
    const getData2=(props)=>{
        //console.log(props.data.color2)
        if(props.data.color2!==undefined){//existe
            const data2s=data2now?[...data2now]:[...props.data.data.data2];//para quitar dependencia y 

            const data={
                groupId:1,
                name: props.data.seriesName2,
                color: props.data.color2,
                data: data2s
            };
            return data;
        }else{
            const data={};
            return data;
        }
        
    };
    
    
    const optionsF=(props)=>{
        const dataUno=data1now?[...data1now]:props.data.data.data1;
        if(dataUno ===undefined){
            const opt = {
                title: {
                  text: 'Cargando Datos'
                },
                series: [{
                  data: [1, 1, 1]
                }]
              }
            return opt}
        else {
            //console.log("Propiedade de chart")
            //const dataInt=dataUno.map((i)=>{return parseInt(i)})
            const dataDosJs=getData2(props);
            
            const opt = {
                
                title: {
                  text: ''
                },
                series: [
                    {
                    groupId:0,
                    name: props.data.seriesName,
                    color: props.data.color,
                    data: dataUno},
                    dataDosJs
                ],
                yAxis:{
                    //softMin: Math.min.apply(null, data1)-3,
                    softMax: Math.max.apply(null, dataUno)+2,
                    title: {
                       text: props.data.ytext,
                       style: {fontSize: '10px'}
                    }
                   },
                xAxis: {
                    categories: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
                    title: {
                            text: props.data.xtext,
                            style: {fontSize: '10px'}
                           }
                },
                
                plotOptions: {
                    series: {
                        animation: false,
                        stickyTracking: false,
                        dragDrop: {
                            draggableY: true,
                            
                            dragPrecisionY: props.data.dragPrecisionY?props.data.dragPrecisionY:1,
                            dragMaxY:props.data.dragMaxY?props.data.dragMaxY:100,
                            dragMinY: 0
                        },
                        point: {
                            events: {
                                
                                //drop: (e)=>{ props.data.drop(e.target.series.yData)},
                                drop: (e)=>{ 
                                    
                                    const groupId=e.target.series.userOptions.groupId;
                                    
                                    
                                    if(groupId===0){
                                        setData1now(e.target.series.yData)
                                    }
                                    else if(groupId===1){
                                        setData2now(e.target.series.yData)
                                    }
                                    setWarningTxt("¿Esta seguro de modificar la gráfica?")
                                    
                                },
                                dragStart:(e)=>{
                                    props.data.click()
                                    //console.log("object")
                                }
                                /*
                                mouseOver:(e)=>{
                                  }
                                  */
                            }
                        }
                    
                    },
                    column: {
                        stacking: 'normal',
                        minPointLength: 2
                    },
                    line: {
                        cursor: 'ns-resize'
                    }
                },
                //tooltip: {valueDecimals: 2},
                exporting: {enabled: false},
                credits: {enabled: false}, 
                

              }
        return opt
        }
    }

    const options=optionsF(props)

    

    

    

    

    

    const sendingBack=()=>{
        const Data={
                    data1:data1now,
                    data2:data2now
        };
        setWarningTxt(null)
        props.data.updateDrop(Data)
    }

    
    
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />

            {
                warningTxt&&(
                    <div className="col-md-12 mt-3">
                        <div className="alert alert-danger alert-block">
                            {warningTxt}
                            <button
                            className="btn btn-danger btn-sm float-right"
                            onClick={sendingBack}
                            >
                            Editar     
                            </button>
                        
                        </div>
                        
                    </div>
                )
            }

        </div>
    )
}

export default DragChart
