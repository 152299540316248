import { Grid } from '@material-ui/core'
import React from 'react'

const RowInput = ({tittle,Component,...props}) => {
    return (
        <Grid container spacing={3} 
            direction="row"
            justifyContent="center"
            alignItems="center"
        >

            <Grid item xs={6}>
                {tittle}
            </Grid>


            <Grid item xs={6}>
                {Component.item}
            </Grid>
        </Grid>
        
    )
}

export default RowInput
export {RowInput}
