import React from "react";
import { GridListTileBar } from "@material-ui/core";
import BarTileInfoGridTimelapse from "./BarTileInfoGridTimelapse";



const BarTileInfo = ({tile}) => {
  return <GridListTileBar titlePosition="bottom" title={<BarTileInfoGridTimelapse tile={tile}/>}/>;
};

export default BarTileInfo;
