import React from "react";
import MeasureProvider from "../../../context/MeasureProvider";
import ManualMeasure from "./ManualMeasure";

const Manual = () => {
  return (
    <div>
      <MeasureProvider>
        <ManualMeasure />
      </MeasureProvider>
    </div>
  );
};

export default Manual;
export { Manual };
