import React from "react";
import { Route, Redirect } from "react-router-dom";

import { UserContext } from "../context/UserProvider";

const PrivateRoute = ({ component, path, ...rest }) => {
  const { usuario, cerrarSesion } = React.useContext(UserContext);
  //protege la ruta en vez de usar
  // compara datos guardados en cache con el usuario existente

  if (localStorage.getItem("usuario")) {
    const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));

    if (usuarioStorage.uid === usuario.uid) {
      return <Route component={component} path={path} {...rest} />;
    }
    //Cuando los usuario en el cache y el obtenido en la nube no coincide
    console.log(usuarioStorage.uid, usuario.uid);
    cerrarSesion();
    return <Redirect to="/login" {...rest} />;
  }
  //Cuando no hay usuario cache
  cerrarSesion();
  return <Redirect to="/login" {...rest} />;
};

export default PrivateRoute;
