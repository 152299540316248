import React, { useContext, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons';
import { UserContext } from '../../../../context/UserProvider';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
	  minWidth: '50vw', // Asegura un mínimo de ancho para el diálogo
	  maxWidth: '50vw', // Un máximo para evitar que sea demasiado grande
	  [theme.breakpoints.down('xs')]: {
		minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	root: {
		'& .MuiTextField-root': {
		  margin: theme.spacing(1),
		  
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "30ch",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	button: {
		display: 'block',
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2)
	},
}));


export const DeleteCropWindow = ({openPopup, handleClosePopup}) => {
	const classes = useStyles();
	const { usuario } = useContext(UserContext)
	const { namesOfCrops, tabValue,weeksOfCrops,daysOfCrops,idOfCrops,placeOfCrops,crops,
		setWeeksOfCrops,setDaysOfCrops,setNamesOfCrops,sendToCRcrop,setTabValue,setCrops,setPlaceOfCrops
	} = useContext(MultiCropContext);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)

	const handleDeleteCrop = async () => {
		setIsLoadingDelete(true)
		const data = {
			username: usuario.username,
			action: "deleteCrop",
			index: tabValue
		}
		const response = await sendToCRcrop(data)
		if(response === "allOK") {
			const arrayOfNames = [...namesOfCrops];
			arrayOfNames.splice(tabValue,1)
			setNamesOfCrops([...arrayOfNames])
			setTabValue(0)
			handleClosePopup()
			setIsLoadingDelete(false)
		}
	}
  return (
	<div>
		<Dialog
		open={openPopup}
        onClose={handleClosePopup}
        classes={{ paper: classes.dialogPaper }} 
		>
			<DialogTitle id="Delete-crop">{"¿Quieres eliminar este cultivo?"}
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClosePopup}>
				<CloseOutlined />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Typography component="div">
					<Box fontWeight="fontWeightRegular"  m={1}>
						Eliminar este cultivo es una acción permanente. Una vez eliminado, no podrás recuperarlo y 
						se perderán todos los datos asociados, incluido los chats realizados a Japy IA. 
						Te recomendamos que revises bien la información antes de proceder:
					</Box>
					<Box fontWeight="fontWeightBold"  m={1}>
						Nombre: {namesOfCrops[tabValue]}, Tipo de cultivo: {crops[tabValue]}
					</Box>
					<Box fontWeight="fontWeightRegular"  m={1}>
						Si no estás seguro, puedes cancelar ahora y volver a revisar más tarde.
						Si decides continuar con la eliminación, haz clic en el botón "Eliminar".
					</Box>
				</Typography>

				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					{isLoadingDelete ? <CircularProgress color="primary" />
					:
					<>
					<Button
					variant="contained" 
					color="secondary" 
					className={classes.button}	
					onClick={handleClosePopup}	
					// disabled={idOfCrops.length === 1}			
					>
						Cancelar
					</Button>
					
					<Button
					variant="contained" 
					color="primary" 
					className={classes.button}	
					onClick={handleDeleteCrop}	
					// disabled={idOfCrops.length === 1}			
					>
						Eliminar
					</Button>
					</> 
					}
				</Grid>

			</DialogContent>

		</Dialog>
	</div>
  )
}
