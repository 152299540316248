import React from "react";

import { AvatarApp } from "../../../Common";
import ImageToEditDialogApp from "../../../Common/ImageToEditDialogApp/ImageToEditDialogApp";
import { UserContext } from "../../../../context/UserProvider";
import { db, storage } from "../../../../config/firebase";


const ImageAvatarDialog = ({open,setOpen}) => {

  const { usuario, setUsuario } = React.useContext(UserContext);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const addr=usuario.username
  const namePhoto="photo-avatar"
  const editPhoto = async (image) => {
    try {
      setLoading(true);
      const imagenRef = await storage
        .ref()
        .child(addr)
        .child(namePhoto);
      await imagenRef.put(image);
      const imagenURL = await imagenRef.getDownloadURL();
      await db
        .collection("usuarios")
        .doc(addr)
        .update({ photoURL: imagenURL });

      const user = { ...usuario, photoURL: imagenURL };

      localStorage.setItem("usuario", JSON.stringify(user));
      setUsuario(user);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const selectFile = async (image) => {
    const imageClient = image.target.files[0];

    if (imageClient === undefined) {
      setError("No se selecciono archivo");
      console.log("No se selecciono");
      return;
    }
    if (imageClient.type === "image/png" || imageClient.type === "image/jpeg") {
      await editPhoto(imageClient);
      console.log("object");
      setError(false);
    } else {
      setError("Archivo no compatible");
    }
  };

  const deletePhoto = async () => {
    try {
        setLoading(true);
      const imagenRef = await storage
        .ref()
        .child(addr)
        .child(namePhoto);
      await imagenRef.delete();
      const imagenURL = null;
      await db
        .collection("usuarios")
        .doc(addr)
        .update({ photoURL: imagenURL });

      const user = { ...usuario, photoURL: imagenURL };

      localStorage.setItem("usuario", JSON.stringify(user));
      setUsuario(user);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message_);
      setLoading(false);
    }
  };
  
  return (
    <ImageToEditDialogApp
      open={open}
      setOpen={setOpen}
      ImgComponent={<AvatarApp large title={usuario.username} src={usuario.photoURL} label={usuario.username.charAt(0).toUpperCase()}/>}
      dialogTitle="Foto de perfil"
      dialogContentText="Una foto te permitirá saber
        cuando hayas accedido a la cuenta."
      deletePhoto={deletePhoto}
      selectFile={selectFile}
      error={error}
      loading={loading}
      deleteLabel="Borrar"
      editLabel="Cambiar"
    />
  );
};

export default ImageAvatarDialog;
