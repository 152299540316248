import React from "react";

import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ContactMailIcon from "@material-ui/icons/ContactMail";

import { UserContext } from "../../../context/UserProvider";
import { withRouter } from "react-router-dom";
import { ALL_PATHS } from "../../../constants/routerConst";
import {AvatarApp} from "../AvatarApp";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  icon: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  submenu: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}));

const UserMenu = (props) => {
  const classes = useStyles();
  const { cerrarSesion,usuario } = React.useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton 
      onClick={handleClick} 
      >
        <AvatarApp title={usuario.username} src={usuario.photoURL} label={usuario.username.charAt(0).toUpperCase()}/>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem button onClick={cerrarSesion} className={classes.submenu}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>

        <ListItem
          button
          onClick={() => props.history.push(ALL_PATHS.PATH_INFO)}
          className={classes.submenu}
        >
          <ListItemIcon>
            <ContactMailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Info" />
        </ListItem>

        <ListItem button className={classes.submenu}>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </ListItem>
      </StyledMenu>
    </div>
  );
};

export default withRouter(UserMenu);
