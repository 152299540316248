import React, { useEffect, useState } from "react";
import { Button, Paper, IconButton } from "@material-ui/core";
import {
  AddAPhoto as AddAPhotoIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { storage } from "../../../config/firebase";

const PhotoUploader = ({ values, handleChange }) => {
  const [photos, setPhotos] = useState([]);
  const [previews, setPreviews] = useState([]);
  useEffect(() => {
    console.log({
      msg: "OpenDialog",
      paths: values.extendedProps.storagePaths,
    });
    if (values.extendedProps.storagePaths != undefined) {
      if (values.extendedProps.storagePaths.length > 0) {
        setPreviews(values.extendedProps.storagePaths);
        let arrAux = new Array(values.extendedProps.storagePaths.length).fill(
          null
        );
        setPhotos(arrAux);
      } else {
        setPreviews([]);
        setPhotos([]);
      }
    } else {
      setPreviews([]);
      setPhotos([]);
    }
  }, [values.extendedProps.storagePaths]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    addPhotos(selectedFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFiles = Array.from(event.dataTransfer.files);
    addPhotos(selectedFiles);
  };

  const addPhotos = (selectedFiles) => {
    const newPhotos = [...photos];
    const newPreviews = [...previews];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const preview = URL.createObjectURL(file);
      newPhotos.push(file);
      newPreviews.push(preview);
    }
    console.log({ msg: "Add photo", newPreviews, newPhotos });
    handleChange({ target: { name: "photos", value: newPhotos } });
    setPhotos(newPhotos);
    setPreviews(newPreviews);
  };

  // Check if storage path is valid function
  const isStoragePathValid = async (storagePath) => {
    try {
      // Get a reference to the image in Firebase Storage
      const storageRef = storage.refFromURL(storagePath);
      // No error occurred, the storage path is valid
      return true;
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        // Image not found in Firebase Storage
        return false;
      } else {
        // Other error occurred while checking the storage path
        console.error("Error checking storage path:", error);
        return false;
      }
    }
  };

  const handleRemove = async (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
    const updatedPreviews = [...previews];
    const storagePath = updatedPreviews[index];
    const isValid = await isStoragePathValid(storagePath);
    console.log({ storagePath, index, updatedPreviews, isValid });
    if (isValid) {
      handleChange({ target: { name: "deletePhoto", value: [storagePath] } });
    }
    updatedPreviews.splice(index, 1);
    setPreviews(updatedPreviews);
  };

  return (
    <div onDrop={handleDrop} onDragOver={(event) => event.preventDefault()}>
      <input
        type="file"
        style={{ display: "none" }}
        multiple
        onChange={handleFileChange}
        accept="image/*"
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<AddAPhotoIcon />}
        >
          Add Photo
        </Button>
      </label>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {previews.map((preview, index) => (
          <Paper
            key={index}
            elevation={3}
            style={{
              width: 200,
              height: 200,
              margin: 10,
              position: "relative",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <img
              src={preview}
              alt={`Preview ${index + 1}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <IconButton
              style={{ position: "absolute", top: 8, right: 8 }}
              onClick={() => handleRemove(index)}
              aria-label="Remove"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        ))}
      </div>
      {/*<Button variant="contained" color="primary" onClick={handleUpload} disabled={photos.length === 0}>
        Upload
          </Button>*/}
    </div>
  );
};

export default PhotoUploader;
